//Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//gj-strap
import { GetTranslationStrap, TranslationStrap } from './../../../../gj_modules/gj-strap/components/translation';
//stam
import { langages } from './../../../../stam_modules/stam-strap/data'

//TODO maybe to much work

let langageTraductions = {};
for(let langage of langages){
    langageTraductions[langage.value] = require('./../../../../stam_modules/stam-strap/translations/'+langage.value+'.js')[langage.value];
}

let translations = new TranslationStrap(langages, langageTraductions);

export const getTranslationFunction = translations.getTranslationFunction;

class GetTranslation extends Component {
    render() {

        const { quick, short, firstletter } = this.props;

        return (
            <GetTranslationStrap
                quick={quick}
                short={short}
                firstletter={firstletter}
                getTranslation={translations.getTranslation}
            />
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {

        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {

    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GetTranslation);
export const fr = {
    menu:{
        applications:"applications",
        users:"utilisateurs",
        sessions:"sessions"
    },
    translate:{
        translations:"traductions",
        addTranslation:"Ajouter une traduction",
        searchByShort:"Rechercher par short"
    }
}
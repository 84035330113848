import {
    SESSION_ALL,
    SESSION_ID,
    SESSION_EVENTS,
    SESSION_ERROR
} from '../actions/types';

const INITIAL_STATE = {
    error:'',
    sessions:null,
    session:null,
    message:null
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SESSION_ALL:
            let sessions = action.payload.sessions;
            sessions['count'] = action.payload.sessionsCount;
            return {
                ...state,
                sessions: sessions,
                error: '',
                message: action.payload.message
            };
        case SESSION_ID:
            let session = action.payload.session;
            return{
                ...state,
                session:session,
                error:'',
                message:action.payload.message
            }
        case SESSION_EVENTS:
            let events = action.payload.events;
            events['count'] = action.payload.eventsCount;
            return {
                ...state,
                session: {
                    ...state.session,
                    events:events
                },
                error: '',
                message: action.payload.message
            };
        case SESSION_ERROR:
            return { ...state, error: action.payload.message };
        default:
            return state;
    }
}

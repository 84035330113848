import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import './Footer.css';
import { Navbar, Nav, Container } from 'react-bootstrap';

class Footer extends Component {
	renderNavLinks = () => {
		let navLinks = this.props.navLinks;

		if (navLinks && navLinks.length > 0) {
			return navLinks
				.filter(link => link.footer)
				.map((link, i) => {
					return (
						<Link key={`${i}footermenu`} to={link.path} className="mx-3">
							{link.name}
						</Link>
					);
				});
		}
	};

	render() {
		return (
			<Navbar className="navbarFooter">
				<Container className={this.props.align === 'right' ? 'footerRight' : ''}>
					<Nav className="mr-auto">{this.renderNavLinks()}</Nav>
					<Nav className="my-2">{this.props.children}</Nav>
				</Container>
			</Navbar>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}

function mapStateToProps(state) {
	return {};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Footer)
import React, { Component } from 'react';

const googlemapsApiKey = process.env.REACT_APP_GOOGLEMAPS_API_KEY;

class GoogleMaps extends Component {
    constructor(props) {
        super(props);
        this.onScriptLoad = this.onScriptLoad.bind(this);
        this.finalId = "map";
        if(this.props.hasOwnProperty("options")){
            if(this.props.options.hasOwnProperty("id")){
                this.finalId = this.props.options.id;
            }
        }
    }

    onScriptLoad() {

        let options = null;

        if(this.props.options && this.props.options.hasOwnProperty("mapOptions")){
            options = this.props.options.mapOptions;
        }

        const map = new window.google.maps.Map(
            document.getElementById(this.finalId),
            options
        );

        if(this.props.options && this.props.options.hasOwnProperty("onLoad")){
            this.props.options.onLoad(map)
        }
        //this.props.onMapLoad(map)
    }

    componentDidMount() {
        if (!window.google) {
            var s = document.createElement("script");
            s.type = "text/javascript";
            s.src = "https://maps.google.com/maps/api/js?key="+googlemapsApiKey;
            var x = document.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
            s.addEventListener('load', e => {
                this.onScriptLoad()
            })
        } else {
            this.onScriptLoad()
        }
    }

    render() {
        return (
            <div style={{ height: 500 }} id={this.finalId} />
        );
    }
}

export default GoogleMaps
import { createBrowserHistory } from 'history'

const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
});

history.listen((location, action) => {

});

export default history
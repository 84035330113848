import React, { Component } from 'react';

import { Row } from 'react-bootstrap';
import { Panel, Form, PanelButton } from '../form';

class MultiPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            initialValues: {},
        };
    }

    componentDidMount() {
        this.getInitialValues(this.setInitialValues);
    }

    componentDidUpdate(prevProps) {
        const { actualStep } = this.props;
        if (prevProps.actualStep !== actualStep) { //Reload initial values only on the actualStep update
            this.getInitialValues(this.setInitialValues);
        }
    }

    getInitialValues(setInitialValuesFunction) {
        const { steps, actualStep, getValuesFunction } = this.props;

        if (steps && steps.length > 0 && actualStep < steps.length && getValuesFunction) {
            getValuesFunction(steps[actualStep].name).then(function (response) {
                if (response) {
                    setInitialValuesFunction(response);
                }
            });
        }
    }

    setInitialValues = (values) => {
        this.setState({ initialValues: values });
    }

    render() {
        const { steps, progressBar = true, actualStep = 0, reducerPending, reducerError, endFunction } = this.props;

        if (steps && steps.length > 0 && actualStep >= steps.length) {
            if (endFunction) {
                endFunction();
            }
        }

        let progressBarPercent = (steps && steps.length > 0 ? (1 / steps.length) * 100 + "%" : "");

        return (
            <React.Fragment>
                <div className="multipanel">
                    <div className="confirmation-info">

                        {progressBar && steps && steps.length > 0 &&

                            <Row className="progressBar">

                                {steps.map((step, i) => <div
                                    key={"step" + i}
                                    className={"progressBarStep " + (actualStep > i ? "progressBarStepPast" : (actualStep < i ? "progressBarStepFutur" : "progressBarStepPresent"))}
                                    style={{ width: progressBarPercent }}
                                >
                                    Etape {i + 1} - {step.title}
                                </div>
                                )}
                            </Row>
                        }

                        {steps && steps.length > actualStep && steps[actualStep].title &&
                            <h3>{steps[actualStep].title}</h3>
                        }

                        {steps && steps.length > actualStep && steps[actualStep].text &&
                            <h5>{steps[actualStep].text}</h5>
                        }
                    </div>

                    {steps && steps.length > actualStep && steps[actualStep].panel &&
                        <React.Fragment>
                            <div>{steps[actualStep].custom ? steps[actualStep].custom : ""}</div>
                            < Form
                                onSubmit={steps[actualStep].onSubmit}
                                initialValues={{ ...this.state.initialValues, step: actualStep }}
                            >
                                <Panel
                                    width={steps[actualStep].panel.width}
                                    inputs={steps[actualStep].panel.inputs.concat([{ short: "step", type: "hidden" }])}
                                />

                                {reducerError && <span style={{ marginTop: '20px', color: 'red' }}>
                                    <strong>Erreur!</strong> {reducerError}
                                </span>}

                                <center>
                                    {steps[actualStep].returnFunction !== undefined &&
                                        <PanelButton
                                            validText={"Retour"}
                                            className="btn btn-outline"
                                            id="btn-solid"
                                            onClick={steps[actualStep].returnFunction}
                                            type="button"
                                        />
                                    }

                                    {steps[actualStep].button &&
                                        <PanelButton
                                            validText={steps[actualStep].button.text || "Valider"}
                                            updateText={steps[actualStep].button.pendingText || "Patientez..."}
                                            className="btn btn-outline"
                                            id="btn-solid"
                                            pending={reducerPending}
                                        />
                                    }
                                </center>
                            </Form>
                        </React.Fragment>
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default MultiPanel;

//<div className="progressBar">Etape {actualStep + 1}/{steps.length}</div>
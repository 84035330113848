import axios from 'axios';
import errorHandler from './errorHandler';

const API_URL = process.env.REACT_APP_API_URL;

export default function putData(action, pending, errorType, isAuthReq, url, dispatch, data, file) {
    const requestUrl = API_URL + url;
    let headers = {};

    if(pending !== null){
        dispatch( { type:pending } );
    }
    
    if (isAuthReq) {
        headers = {
            headers: {
                Authorization: localStorage['token']
            }
        };
	}
	
	let isFile = false;
	
	Object.keys(data).forEach(function (key) {
		if(data[key] instanceof File){
			isFile = true;
		}
	});

	let finalData = data;

	if (file || isFile) {
		headers.headers['Content-Type'] = 'multipart/form-data';
		
		let formData = new FormData()
		Object.keys(data).forEach((key) => {
			formData.append(key, data[key])
		})

		finalData = formData;

    }
  
    return axios
    .put(requestUrl, finalData, headers)
    .then(response => {
        dispatch({
            type: action,
            payload: response.data,
            requestData: data // put request data in response so reducers know what is it about
        });
        return { response };
    })
    .catch(error => {
        return errorHandler(dispatch, error, errorType, data);
    });
}
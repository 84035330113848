import React from 'react';
import { GetTranslation } from '../stam_modules/stam-strap/components/partials';

export const mbuilding = {
    name: "mbuilding",
	clean:"tour",
    actions: [
        "all", "id", "add", "update", "trash", "reset"
    ],
    model: {
        game:{
            name:"Application",
            type:"select",
            data:{
                isMulti:true
            },
            modelType:"game",
            update:true,
            add:true
        },
        short: {
            name: "Short",
            type: "text",
            placeholder: "Short",
            add: true,
            update: true
        },
        name: {
            name: "Nom",
            placeholder: "Nom de l'objet",
            type: "translationtext",
            //add: true,
            update: true
        },
        description: {
            name: "Description",
            type: "textarea",
            placeholder: "Description",
            update: true
        },
		location:{
            name: "Localisation",
            type: "location",
            update: true
		},
		elevation: {
            name: "Elevation",
            type: "text",
            placeholder: "Elevation",
            update: true
        },
		ffile:{
            name:"Media",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"ffile",
            update:true,
        },
		mstory:{
            name:"Story",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"mstory",
            update:true
        },
		mcard:{
            name:"Fiche",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"mcard",
            update:true
        },
		m3dmodel:{
            name:"3d Model",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"m3dmodel",
            update:true
        }
    },
    filters:{
        default:{
            filter:"searchMbuildingByName",
            quantity:20,
            state:"mbuilding.mbuildings",
            value:"_id",
            label:"short"
        },
		all:[
			{
				type:"search",
				size:25,
				name:"searchMbuildingByShort",
				placeholder:"Recherche par short"
			},
		]
    },
    display:{
        selectDefault:(e) => {
            return {
                label:e.name,
                value:e._id,
                force:true
            }
        }
    },
	properties:[
		{ short: "short", name: "Short", width: "45%", category: "classic"},
		{ short: "name", name: "Nom", width: "45%", category: "classic"},
		{ short: "actions", name: "Actions", width: "10%", category: "actions" }
	],
	renderTd:(e) => {
		return {
			id: e._id,
			key: "mbuilding_"+e._id,
			short: e.short,
			name: <GetTranslation quick={e.name && e.name.translations} />
		}
	}
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
//import filter from 'lodash/filter'
import { bindActionCreators } from 'redux'
//import { getNotificationByUser } from '../../actions/notification'
import { navLinks } from './../../../../data/menu'
import Header from './../../../../gj_modules/gj-strap/components/partials/Header'
import { logoutUser } from '../../actions/auth'
//Langages
import { langages } from '../../data'
import { slangageUpdate } from './../../../../gj_modules/gj-stuva/actions'

class HeaderTop extends Component {
    /*countNotification = () => {
        return filter(this.props.notification, {
            state: 'PENDING'
        }).length;
    };*/

    render() {
        const { currentUser, menu, slangageUpdate, langage } = this.props;

        return (
            <Header
                headerTitle="Stam"
                headerLink="/games"
                navLinks={navLinks[menu]}
                currentUser={currentUser}
                isUser={true}
                canRegister={false}
                logoutUser={this.props.logoutUser}
                authenticatedUser={currentUser}
                lang={true}
                langages={langages}
                updateLangage={slangageUpdate}
                currentLangage={langage.currentLangage}
            />
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logoutUser,
            slangageUpdate
            //getNotificationByUser
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        notification: state.notifications.notification,
        currentUser: state.auth.user,
        langage: state.langage
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderTop);

import { actions } from 'stam_modules/stam-strap/stuva/index.js';

const elementActions = actions["translation"];
const getAll = elementActions.getAll;

const gjstuva = {
    translation:{
        getAll:getAll
    },
    actions:actions
}

export default gjstuva;
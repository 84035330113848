import {
    UPDATE_NOTIFICATION,
    GET_USER,
    GET_USER_SELF,
    EXISTING_USER,
    ADD_USER,
    RESET_USER,
    USER_ERROR,
    SET_PREFERENCE,
    EDIT_USER_SUCCESS,
    EDIT_USER_REQUEST,
    EDIT_USER_ERROR,
    USER_RESET,
    USER_ALL,
    USER_ALL_PENDING
} from '../actions/types';

const INITIAL_STATE = {
    error: '',
    user: null,
    users:null,
    notification: null,
    message: null,
    pending: false
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case USER_RESET:
            return {
                ...state,
                error: '',
                user: null,
                users: null,
                notification: null,
                message: null,
                pending: false
            }
        case USER_ALL_PENDING:
            return {
                ...state,
                pending:true
            }
        case USER_ALL:
            let users = action.payload.users;
            users['count'] = action.payload.usersCount;
            return {
                ...state,
                users: users,
                error: '',
                pending:false,
                message: action.payload.message
            };
        case GET_USER:
            let notification = action.payload.users[0].preference.notification;
            return {
                ...state,
                user: action.payload.user[0],
                notification,
                error: '',
                message: action.payload.message
            };
        case GET_USER_SELF:
            return {
                ...state,
                user: action.payload.user,
                notification,
                error: '',
                message: action.payload.message
            };
        case UPDATE_NOTIFICATION:
            return {
                ...state,
                notification: action.payload.notification,
                error: '',
                message: action.payload.message
            };
        case ADD_USER:
            return { ...state, message: action.payload, error: '' };
        case RESET_USER:
            return { ...state, user: null, message: '', error: '' };
        case EXISTING_USER:
            return { ...state, error: action.payload };
        case USER_ERROR:
            return { ...state, error: action.payload.message };
        case SET_PREFERENCE:
            return {
                ...state,
                user: {
                    ...state.user,
                    preference: {
                        ...state.user.preference,
                        [action.preference]: action.value
                    }
                }
            };
        case EDIT_USER_REQUEST:
            return {
                ...state,
                processEditUser: true,
                editUserError: null
            };
        case EDIT_USER_ERROR:
            return {
                ...state,
                processEditUser: false,
                editUserError: action.payload
            };
        case EDIT_USER_SUCCESS:
            return {
                ...state,
                processEditUser: false,
                editUserError: null,
                user: {
                    ...state.user,
                    profile: {
                        ...state.user.profile,
                        firstName: action.requestData.firstName,
                        lastName: action.requestData.lastName
                    },
                    email: action.requestData.email,
                    gender: action.requestData.gender,
                    numero: action.requestData.numero,
                    address: action.requestData.address
                }
            };
        default:
            return state;
    }
}

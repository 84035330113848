//Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//stam-strap
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
//child-app
import history from '../../../../history';
//gj-strap
import { Full, FullCenter, TileContainer, Tile, TileHeader, TileBody, Cover, modalFunction } from './../../../../gj_modules/gj-strap/components/partials';
import { Form, PanelButton } from './../../../../gj_modules/gj-strap/components/form';
import { processSubmit, checkNull } from './../../../../gj_modules/gj-strap/lib';
//various
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons'
//modules
import FfileChannels from './FfileChannels';
import FfilePreview from './FfilePreview';
//gj-stuva
import { generateInitialValue } from './../../../../gj_modules/gj-stuva/forms'
import { PanelNew } from './../../../../gj_modules/gj-stuva/components'
import { actions } from '../../stuva';
import { GetTranslation } from '../partials';
const elementActions = actions["ffile"];
const reset = elementActions.reset;
const getById = elementActions.getById;
const updateById = elementActions.updateById;
const trashById = elementActions.trashById;

class Ffile extends Component {
	constructor(props) {
        super(props);
        this.state = {
            select:null
        };
	}
	
    componentDidMount() {
        const { match, reset, getById } = this.props;
		const id = match && match.params && match.params.idffile;
        reset();
        if (id) {
            getById(id);
        }
    }

    componentDidUpdate() {
		const { ffile } = this.props;
		const { select } = this.state;

		if(select == null){
			if(ffile && ffile.data && ffile.data.subffiles && ffile.data.subffiles.length > 0){
				let findPreview = null;
				let first = null;
				for(let sf of ffile.data.subffiles){
					if(sf.channel === "preview"){
						if(select !== sf._id){
							findPreview = sf._id
						}
					}else{
						if(first == null){
							first = sf._id;
						}
					}
				}
				if(findPreview !== null){
					this.setState({select:findPreview});
				}else{
					this.setState({select:first});
				}
			}
		}
    }

    submitEdit(values) {
        const { match, updateById, ffile } = this.props;
        let element = ffile;
        const id = match && match.params && match.params.idffile;
        console.log(processSubmit(values));
        if (element && !element.pendingUpdate) {
            updateById(id, processSubmit(values));
        }
    }

    render() {
		const { match, ffile, trashById, menu } = this.props;
		const { select } = this.state;
        const id = match && match.params && match.params.id;
        const idffile = match && match.params && match.params.idffile;

        const element = ffile;

        let portlet = {
            title: "Fichiers",
            links: [
                /*{name:"Sessions", path:"/"+id+"/sessions"},*/
                { name: "Fichier", path: "/" + id + "/ffile/" + idffile }
            ]
		}

        return (
            <React.Fragment>
                <div id="popup"></div>
                <MenuLeft menu={menu} />
                <HeaderTop menu={menu} />

                {element && !element.pending ? (

                    <FullCenter portlet={portlet}>
                        <TileContainer>
                            <Tile size={"full"}>
                                <TileBody>
                                    <Cover
                                        icon={faPencilRuler}
                                        title={<GetTranslation quick={element.name && element.name.translations} />}
                                        subtitle={checkNull(element.short)}
                                    >
                                        Fichier créée le {moment().format("DD/MM/YYYY")}<br />
                                        Dernière modification le {moment().format("DD/MM/YYYY")}
                                    </Cover>
                                </TileBody>
                            </Tile>
                        </TileContainer>

                        <Row>

                            <Col lg="6" className="tile-col">

                                {/* Preview */}
								<FfilePreview
									select={select}
								/>

                            </Col>

                            <Col lg="6" className="tile-col">

                                {/* Channels */}
                                <FfileChannels
									selectFunction={(e) => {
										this.setState({select:e});
									}}
									select={select}
								/>

                                {/* Général */}
                                <TileContainer>
                                    <Tile size={"full"}>
                                        <TileHeader title="Général" border={true} />
                                        <TileBody>

                                            <Form
                                                onSubmit={values => this.submitEdit(values)}
                                                initialValues={generateInitialValue(elementActions.model, element)}
                                            >

                                                <PanelNew
                                                    width={[3, 9]}
                                                    model={elementActions.model}
                                                    filter={"update"}
                                                    element={element}
                                                />

                                                <PanelButton
                                                    pending={element.pendingUpdate}
                                                    message={element.message}
                                                />

                                            </Form>
                                        </TileBody>
                                    </Tile>
                                </TileContainer>

                                {/* Delete */}
                                <TileContainer>
                                    <Tile size={'full'}>
                                        <TileBody>
                                            <Button
                                                onClick={function () {
                                                    modalFunction({
                                                        btnFunction: () => {
                                                            trashById(idffile).then(
                                                                function (response) {
                                                                    history.push("/" + id + "/ffiles")
                                                                }
                                                            );
                                                        },
                                                        btnText: "Confirmer",
                                                        text: "Êtes-vous sûr de supprimer cet objet ?",
                                                        header: "Supprimer un objet",
                                                        state: "ffile.pendingTrash"
                                                    }, "popup")
                                                }}
                                                className="float-sm-right"
                                                variant="danger"
                                            >
                                                Supprimer cet objet
											</Button>
                                        </TileBody>
                                    </Tile>
                                </TileContainer>
                            </Col>
                        </Row>

                    </FullCenter>
                ) : (
                        <Full>
                            Chargement
                        </Full>
                    )}
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            reset,
            getById,
            updateById,
            trashById
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        ffile: state.ffile.ffile,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Ffile);
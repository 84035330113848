import React from 'react';
import { wrapField } from './Form';

const Checkbox = ({ input, name, value, label, onChange, disabled }) => {
	return (
		<React.Fragment>
            <div className="form-group">
                <label className="checkbox" style={{"paddingLeft":(label ? "40px" : "30px")}}>
                    <input
                        type="checkbox"
                        name={name}
                        value={value}
                        onChange={onChange}
                        disabled={disabled}
                        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                        {...input}
                    />
                    {label} <span style={{"top":(label ? 0 : "-4px")}} />
                </label>
            </div>
		</React.Fragment>
	);
};

export default wrapField(Checkbox, 'checkbox');

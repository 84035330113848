import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class LargeButton extends Component {
	render() {
		const { label, to, icon, onClick } = this.props;
		return (
			<React.Fragment>
				<Link className="btn-large" to={to} onClick={onClick}>
					<div className="btn-large-body">
						<div className="btn-large-content">
							<div className="btn-large-title">
								<p>{label}</p>
							</div>
							<div className="btn-large-icon">
								<i className={icon + ' rounded-circle'}></i>
							</div>
						</div>
					</div>
				</Link>
			</React.Fragment>
		);
	}
}

export default LargeButton;

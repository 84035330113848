import React from 'react';
import { wrapField } from './Form';
import './TextArea.scss';

const TextArea = props => {
    const { input, label, placeholder, meta, children, disabled, onChange, height } = props;

	return (
		<React.Fragment>
			<div className="form-group">
				{label ? <label>{label}</label> : null}
				<textarea
					className={`form-control-2 textarea ${meta && meta.error && meta.touched ? 'error' : ''}`}
					placeholder={placeholder}
					value={input.value}
					disabled={disabled}
                    style={{ cursor: disabled ? 'not-allowed' : 'auto', height: height ? height : '70px' }}
                    name={input.name}
					onChange={value => {
						onChange && onChange(value);
						input.onChange(value && (value.value || value));
					}}
				></textarea>
				{meta && meta.error && meta.touched && <p className="form-input-error-message">{meta.error}</p>}
				{children}
			</div>
		</React.Fragment>
	);
};

export default wrapField(TextArea);

import {
    BLOCK_ALL,
    BLOCK_ID,
    BLOCK_RESET,
    BLOCK_ERROR
} from '../actions/types';

const INITIAL_STATE = {
    error:'',
    blocks:null,
    block:null,
    message:null
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case BLOCK_ALL:
            let blocks = action.payload.blocks;
            blocks['count'] = action.payload.blocksCount;
            return {
                ...state,
                blocks: blocks,
                error: '',
                message: action.payload.message
            };
        case BLOCK_ID:
            let block = action.payload.block;
            return{
                ...state,
                block:block,
                error:'',
                message:action.payload.message
            }
        case BLOCK_RESET:
            return{
                ...state,
                error:'',
                blocks:null,
                block:null,
                message:null
            }
        case BLOCK_ERROR:
            return { ...state, error: action.payload.message };
        default:
            return state;
    }
}

import {
	MPOI_UPDATE_OFFSET
} from './types';

export function updateOffset(id, param, value) {
    return dispatch => dispatch({
		type: MPOI_UPDATE_OFFSET,
		payload: {
			id:id,
			param:param,
			value:value
		}
	});
}
import React, { useState } from 'react';
import moment from 'moment';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './DateRange.scss';

import { DateRangePicker, /*CalendarDay*/ } from 'react-dates';
import { wrapField } from './Form';

function handleChange(startDate, endDate, input, onChange) {

    let final = {
        startDate:startDate ? startDate.format('YYYY-MM-DD') : null,
        endDate:endDate ? endDate.format('YYYY-MM-DD') : null
    }

	input.onChange(final);
	if(onChange){
        onChange(final);
    }
}

const DateRange = props => {

    let initDate = {
        startDate:null,
        endDate:null
	}

	let isOutsideRange = () => false;
	//let isOutsideRange = (day) => day.isAfter(moment());

    if(props.input.value){
        if(props.input.value.startDate){
            initDate.startDate = moment(props.input.value.startDate);
        }
        if(props.input.value.endDate){
            initDate.endDate = moment(props.input.value.endDate);
        }
    }

    const [focus, setFocus] = useState(null);
    const [startDate, setStartDate] = useState(initDate.startDate);
    const [endDate, setEndDate] = useState(initDate.endDate);

    return (
        <React.Fragment>
            <div className="date-range">
                <DateRangePicker
                    startDate={startDate}
                    startDateId="your_unique_start_date_id"
                    endDate={endDate}
                    endDateId="your_unique_end_date_id"
                    onDatesChange={({ startDate, endDate }) => {
                        handleChange(startDate, endDate, props.input, props.onChange)
                        setStartDate(startDate);
                        setEndDate(endDate);
                    }}
                    startDatePlaceholderText={"Début"}
                    endDatePlaceholderText={"Fin"}
                    focusedInput={focus}
					onFocusChange={focusedInput => setFocus(focusedInput)}
					isOutsideRange={isOutsideRange}
                />
            </div>
            {props.meta && props.meta.error && props.meta.touched && <p className="form-input-error-message">{props.meta.error}</p>}
        </React.Fragment>
    );
}

export default wrapField(DateRange);

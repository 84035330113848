import React from 'react';
import Radio from './Radio'

const Radios = function ({ input, name, data, value, label, onClick, classOnLabel}) {
	//const { data, noForm } = props;

	return(
		data.map((value, index) => {
			return (
				<Radio
					key={name+"-"+value.value}
					name={name}
					value={value.value}
					label={value.label}
				/>
			)
		})
	)
};

export default Radios
//Users
export const roles = {
    ROLE_ADMIN:{
        value: 'admin',
        label: 'Admin',
        rank: 10
    },
    ROLE_RRH:{
        value: 'rrh',
        label: 'RRH',
        rank: 20
    },
    ROLE_GUEST:{
        value: 'guest',
        label: 'Invité',
        rank: 40
    }
};
import React, { Component } from 'react';
import './Tile.css';

class TileBody extends Component{
    render(){
        return(
            <div className={"tile-body "+(this.props.className ? this.props.className : "")+" "+(this.props.border ? "tile-header-border" : "")+""}>
                {this.props.children}
            </div>
        )
    }
}

export default TileBody;
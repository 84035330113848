import React from 'react';
import { wrapField } from './Form';
import './Radio.scss';

const Radio = function ({ input, name, value, label, onClick, classOnLabel}) {
	classOnLabel = "radio mr-3 " + classOnLabel;
	return (
		<React.Fragment>
			<label className={classOnLabel}>
				{label}
				<input type="radio" name={name} value={value} onClick={onClick} {...input} />
				<span className="checkround" />
			</label>
		</React.Fragment>
	);
};

export default wrapField(Radio, 'radio');

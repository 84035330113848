//Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// npm
import axios from 'axios';
//stam-strap
import HeaderTop from 'stam_modules/stam-strap/components/partials/HeaderTop';
import MenuLeft from 'stam_modules/stam-strap/components/partials/MenuLeft';
//import { required } from '../../lib/validate';
//child-app
//import history from '../../history';
//gj-strap
import { ComplexAll } from 'gj_modules/gj-strap/components/complex'
import { FullCenter, /*modalFunction, TileContainer, Tile, TileHeader, TileBody*/ } from 'gj_modules/gj-strap/components/partials';
/*import DataTable from 'gj_modules/gj-strap/components/form/DataTable';
import { processSubmit } from 'gj_modules/gj-strap/lib';*/
import { GetTranslation } from 'stam_modules/stam-strap/components/partials';
//various
//import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
//gj-stuva
import { /*generatePanelNew, generateInitialValue*/ } from 'gj_modules/gj-stuva/forms'
//import { generatePanelNew } from 'gj_modules/gj-stuva/forms'
import { Button } from 'react-bootstrap'
//import { PanelNew } from 'gj_modules/gj-stuva/components'
import { actions } from 'stam_modules/stam-strap/stuva';
const elementActions = actions["mbuilding"];
const reset = elementActions.reset;
const getAll = elementActions.getAll;

const API_URL = process.env.REACT_APP_API_URL;

class Export extends Component {

    /*constructor(props) {
        super(props);
    }*/

    componentDidMount() {
		
    }

	getExport = (b) => {
		const requestUrl = API_URL + "/export/building/"+b;
		let headers = {
			headers: {
				Authorization: localStorage['token']
			}
		};
		
		return axios
		.get(requestUrl, headers)
		.then(response => {
			console.log(response.data)
			alert(b+" has been published")
			return { response };
		})
		.catch(error => {
			//return errorHandler(dispatch, error, errorType);
		});
	}

    renderTd = (e) => {
        return {
            id: e._id,
            key: "mbuilding_" + e._id,
            short: e.short,
			name: <GetTranslation quick={e.name && e.name.translations} />,
			export: <Button
				onClick={() => this.getExport(e.short)}
			>Exporter</Button>
        }
    }

    render() {
        const { mbuilding /*, match, trashById, getAll*/ } = this.props;

        const element = mbuilding;

		//let array = []

        const properties = [
            { short: "short", name: "Short", width: "45%", category: "classic" },
            { short: "name", name: "Nom", width: "45%", category: "classic" },
            { short: "export", name: "Actions", width: "10%", category: "classic" }
        ];

        const portlet = {
            title: "Export",
            links: [
                { name: "Building", path: "/things" }
            ]
        }

        return (
            <React.Fragment>
                <div id="popup"></div>
                <MenuLeft menu={"timescope"} />
                <HeaderTop menu={"timescope"} />
                <FullCenter portlet={portlet}>
				<ComplexAll
                        actions={elementActions}
                        //callbackGetAll={() => { this.updateMarkers(); }}
                        elements={element.mbuildings}
                        properties={properties}
                        filters={[]}
                        renderTd={this.renderTd}
                        pending={element.pending}
                        noDataMessage={"Pas d'objets"}
                    >
                    </ComplexAll>
                </FullCenter>
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
			reset,
            getAll
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        mbuilding: state.mbuilding,
        menuGame: state.menu.game
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Export);

import React from 'react';
import moment from 'moment';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './DatePicker.scss';

import { DayPickerSingleDateController, CalendarDay } from 'react-dates';
import { wrapField } from './Form';

function handleChange(selectedDate, input, onChange) {
	const newDate = selectedDate.format('YYYY-MM-DD');
	if(!Array.isArray(input.value)) { // not a calendar with multiple selectable days
		input.onChange(newDate);
		if(onChange) onChange(newDate);
		return;
	}

	let selectedDates = input.value.slice();
	if(selectedDates.includes(newDate)) // date already selected, we deselect it
		selectedDates = selectedDates.filter(date => date !== newDate);
	else // date not already selected, we select it
		selectedDates.push(newDate);
	input.onChange(selectedDates);
	if(onChange) onChange(newDate);
}

const DatePicker = props => (
	<React.Fragment>
		<div className="date-picker">
			<DayPickerSingleDateController
				date={props.input.value && !Array.isArray(props.input.value) ? moment(props.input.value) : null}
				onDateChange={date => handleChange(date, props.input, props.onChange)}
				isOutsideRange={d => {
                    if(props.anytime){
                        return false;
                    }else{
                        return d.isBefore(Date.now(), 'day')
                    }
                }}
				isDayBlocked={d => {
                    if(props.alldays){
                        return false;
                    }else{
                        const weekDay = d.isoWeekday();
                        return weekDay === 6 || weekDay === 7;
                    }
				}}
				isDayHighlighted={d => false}
				initialVisibleMonth={() => moment(props.input.value ? (Array.isArray(props.input.value) ? props.input.value[0] : props.input.value) : new Date())}
				hideKeyboardShortcutsPanel
				noBorder
				focused
				renderCalendarDay={renderProps => {
					if(Array.isArray(props.input.value) && renderProps.modifiers) {
						const formatedDay = moment(renderProps.day).format('YYYY-MM-DD');
						props.input.value.find(day => day === formatedDay) ? renderProps.modifiers.add('selected') : renderProps.modifiers.delete('selected');
					}

					return <CalendarDay {...renderProps} />;
				}}
			/>
		</div>
		{props.meta && props.meta.error && props.meta.touched && <p className="form-input-error-message">{props.meta.error}</p>}
	</React.Fragment>
);

export default wrapField(DatePicker);

import React from 'react';
import Creatable from 'react-select/lib/Creatable';

import { wrapField } from './Form';

const CreatableSelect = props => {
	const { options, name, placeholder, input, meta, label, onChange } = props;

	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			<Creatable
				className={`select ${meta && meta.error && meta.touched ? 'error' : ''} mb-3`}
				classNamePrefix="select"
				name={name}
				placeholder={placeholder || 'Sélectionner'}
				value={input.value}
				onChange={value => {
					input.onChange(value);
					onChange && onChange(value);
				}}
				options={options}
				{...props}
			/>
			{meta && meta.error && meta.touched && <p className="form-input-error-message">{meta.error}</p>}
		</div>
	);
};

export default wrapField(CreatableSelect);

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './FullCard.css';


export default ({ children, title }) => (
    <Container>
        <Row>
            <Col lg="12" xl="12">
                <h2 id="title1" className="mt-5">
                    <span>{title}</span>
                </h2>
            </Col>
            <Col sm="12" md="5" xl="3" className="Col-full">
                {children}
            </Col>
        </Row>
    </Container>
);
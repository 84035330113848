import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { TileContainer, Tile, TileHeader, TileBody } from './../../../../gj_modules/gj-strap/components/partials';

//import moment from 'moment';

import { Panel } from './../../../../gj_modules/gj-strap/components/form';
import DataTable from './../../../../gj_modules/gj-strap/components/form/DataTable';
import { Button } from 'react-bootstrap';

import { /*returnFunction,*/ processSubmit } from './../../../../gj_modules/gj-strap/lib';
import { modalFunction } from './../../../../gj_modules/gj-strap/components/partials';

import { required } from './../../../../gj_modules/gj-strap/lib/validate';
//import { PanelDisplay } from 'gj_modules/gj-strap/components/display';

//import { Tooltip, OverlayTrigger } from 'react-bootstrap'

//gj-stuva
//import { generateInitialValue } from 'gj_modules/gj-stuva/forms'
import { actions } from '../../stuva';
const elementActions = actions["ffile"];
const add = elementActions.subffile.add;
/*const reset = elementActions.reset;
const getById = elementActions.getById;*/
const updateById = elementActions.subffile.updateById;
const trashById = elementActions.subffile.trashById;

class FfileChannels extends Component {
    componentDidMount() {
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    dataTableChannelsRender = () => {
        const { ffile, updateById, trashById, selectFunction, select } = this.props;

		const properties = [ // DataTable Headers
            { short: "channel", width: "30%" },
            { short: "type", width: "30%" },
            { short: "size", width: "20%" },
            { short: "actions", width: "20%", category: "actions" }
        ];

        const array = []

        if(ffile.ffile && ffile.ffile.data && ffile.ffile.data.hasOwnProperty("subffiles") && ffile.ffile.data.subffiles.length > 0){
            for(let f of ffile.ffile.data.subffiles){
				if(f.status === "active"){
					let temp = {
						id:f._id,
						key:f._id,
						channel:f.channel,
						type:f.mimetype,
						size:(f.size/1048576).toFixed(3)+" Mo"
					}

					if(f._id === select){
						temp.style = {backgroundColor:"#dfdfdf"}
					}

					array.push(temp)
				}
            }
        }

        return (
            <DataTable small
                //noEditGlobal
                properties={properties}
                collection={array}
                noDataMessage={"Pas de channel"}
                clickAction={(e) => {
					selectFunction(e.id);
				}}
                editAction={(e) => {
					let sf = null;
					for(let subffile of ffile.ffile.data.subffiles){
						if(subffile._id === e){
							sf = subffile;
						}
					}
					if (!ffile.pendingsubffileUpdate) {
						modalFunction({
							submit: (values) => {
								if (!ffile.pendingsubffileUpdate) {
									updateById(ffile.ffile._id, e, processSubmit(values))
								}
							},
							initialValues: {
								channel:sf.channel,
								streaming:sf.streaming
							},
							text: (
								<Panel
									width={[3, 9]}
									inputs={[
										{ short: "channel", name: "Channel", type: "text", placeholder: ""},
										{ short: "streaming", name: "Streaming", type: "checkbox"},
										{ short: "file", name: "File", type: "file", placeholder: ""},
									]}
								/>
							),
							header: "Modifier un channel",
							state: "ffile.pendingsubffileUpdate"
						}, "popup")
					}
                }}
                deleteAction={(e) => {
                    modalFunction({
                        btnFunction: () => {
							//console.log("function delete "+e+" - "+ffile.ffile._id)
							trashById(ffile.ffile._id, e)
                        },
                        btnText: "Confirmer",
                        text: "Êtes-vous sûr ?",
                        header: "Supprimer un channel",
                        state: "ffile.pendingsubffileTrash"
                    }, "popup")
                }}
            />
        )
    }

    render() {
        const { ffile, add } = this.props;

        return (
            <React.Fragment>
                {/* Actions */}
                <TileContainer>
                    <Tile size={"full"}>
                        <TileHeader
                            title="Channels"
                            border={true}
                            toolbar={<Button onClick={
                                () => {
                                    if (!ffile.pendingsubffileAdd) {
                                        modalFunction({
                                            submit: (values) => {
                                                if (!ffile.pendingsubffileAdd) {
													values["id"] = ffile.ffile._id;
                                                    add(processSubmit(values))
                                                }
                                            },
                                            text: (
                                                <Panel
                                                    width={[3, 9]}
                                                    inputs={[
														{ short: "channel", name: "Channel", type: "text", placeholder: ""},
														{ short: "file", name: "File", type: "file", placeholder: "", validate:required() },
                                                    ]}
                                                />
                                            ),
                                            header: "Ajouter un channel",
                                            state: "ffile.pendingsubffileAdd"
                                        }, "popup")
                                    }
                                }
                            }>
                                {ffile.pendingsubffileAdd ? "O" : "+"}
                            </Button>}
                        />
                        <TileBody>
                            {this.dataTableChannelsRender()}
                        </TileBody>
                    </Tile>
                </TileContainer>
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
			add,
			updateById,
			trashById
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        ffile: state.ffile,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FfileChannels);
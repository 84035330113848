//React
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';

import { PanelButton } from '../form';

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faUser, faTimes, faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
//Functions
import { TranslationSelect } from '../translation';
//Css
import './Header.scss';

export const REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

class Menu extends Component {
    handleClickOutside = () => {
        if (this.props.opened) {
            this.props.toggleMenu();
        }
    };

    renderNavLinks = () => {
        let navLinks = this.props.navLinks;

        if (navLinks && navLinks.length > 0) {
            return navLinks
                .filter(link => link.mobile)
                .map((link, i) => {
                    let finalTo = link.path;
                    if (this.props.urlPrefix && !link.noprefix) {
                        finalTo = this.props.urlPrefix + '' + link.path;
                    }
                    return (
                        <li key={`${i}headertooglemenu`}>
                            <Link onClick={this.props.toggleMenu} to={finalTo}>
                                {link.name}
                            </Link>
                        </li>
                    );
                });
        }
    };

    render() {
        return (
            <nav id="aside-nav" style={{ zIndex: 10, left: this.props.opened ? 0 : '-100%' }}>
                <span onClick={this.props.toggleMenu} aria-label="close-nav">
                    <FontAwesomeIcon icon={faTimes} />
                </span>

                <ul>{this.renderNavLinks()}</ul>
            </nav>
        );
    }
}

const ClosableMenu = onClickOutside(Menu);

class Header extends Component {
    state = {
        opened: false
    };

    logout = () => {
        this.props.logoutUser();
        if (this.state.opened) this.toggleMenu();
    };

    toggleMenu = () => {
        this.setState(state => ({
            opened: !state.opened
        }));
    };

    renderNavLinks = () => {
        let navLinks = this.props.navLinks;

        if (navLinks) {
            return navLinks
                .filter(link => link.header)
                .map((link, i) => {
                    return (
                        <Link key={`${i}headermenu`} to={link.path} className="mx-3">
                            {link.name}
                        </Link>
                    );
                });
        }
    };

    renderCompanyList() {

        let companyList = this.props.companyList;

        return companyList
            .map((company, i) => {
                return (
                    <option
                        key={i+"asidelist"}
                        value={company.id}
                    >
                        {company.name}
                    </option>
                )
            });
    }

    render() {

        let notificationIcon = true;

        if(this.props.hasOwnProperty("notificationIcon")){
            notificationIcon = this.props.notificationIcon;
        }

        let iconBell = faBell;
        let iconUser = faUser;
        let iconLogout = faSignOutAlt;

        if(this.props.iconBell){
            iconBell = this.props.iconBell;
        }
        if(this.props.iconUser){
            iconUser = this.props.iconUser;
        }
        if(this.props.iconLogout){
            iconLogout = this.props.iconLogout;
        }

        const { companyList, logo } = this.props
        let arrow = companyList && companyList.length > 1 ? '' : 'active'

        return (
            <header className="navbar navbar-expand navbar-dark flex-xl-row bd-navbar">

                {/* MOBILE */}

                {!this.props.hideMobileMenu ? (
                    <React.Fragment>
                        <span id="toggle-nav-btn" onClick={this.toggleMenu} aria-label="open-nav">
                            <FontAwesomeIcon icon={faBars} />
                        </span>
                        <ClosableMenu opened={this.state.opened} toggleMenu={this.toggleMenu} navLinks={this.props.navLinks} urlPrefix={this.props.urlPrefix} />
                    </React.Fragment>
                ) : null}

                {logo ? (
					<Link className="navbar-brand" to={this.props.headerLink || '/home'}>
						<img id="logo" src={"" + (REACT_APP_PUBLIC_URL ? REACT_APP_PUBLIC_URL : "") + "/images/logo.png"} alt="logo" />
						<span className="brand-name">{this.props.headerTitle || 'GJGEARS'}</span>
					</Link>
				) : null }
				
				{/* Custom content */}
				{this.props.children}

                {/* COMPANY LIST */}

                {companyList && companyList.length ?
                    <select
                        className={"header-menu-select " + arrow}
                        disabled={companyList.length < 2 && true}
                        onChange={this.props.onSelect}
                        defaultValue={this.props.companyDefaultValue}
                    >
                        {this.renderCompanyList()}
                    </select> : null
                }

                {/* RIGHT BAR */}

                <div className="navbar-nav-container">

                    {/* LANGAGE */}

                    {this.props.lang ? (
                        <TranslationSelect
                            langages={this.props.langages}
                            updateLangage={this.props.updateLangage}
                            currentLangage={this.props.currentLangage}
                        />
                    ) : null}

                    {/* NAVLINKS */}

                    {!this.props.authenticatedUser && this.props.isUser ? (
                        <div className="navbar-nav-scroll d-none d-lg-inline-flex">
                            {this.props.children}
                            <div className="mr-5">{this.renderNavLinks()}</div>
                            {this.props.canRegister ? (
                                <Link to="/register">
                                    {/*<button id="subsribe-btn" type="button" className="btn btn-sm">
                                        S'inscrire
                            </button>*/}

                                    <PanelButton
											validText="S'inscrire"
											className="btn"
											id="btn-solid"
										/>
                                </Link>
                            ) : null}
                        </div>
                    ) : (
                            <div className="navbar-nav-scroll d-none d-lg-inline-flex">
                                <div className="mr-5">{this.renderNavLinks()}</div>
                            </div>
                        )}

                    {/* ICONS */}

                    {this.props.authenticatedUser && this.props.isUser ? (
                        <React.Fragment>
                            {notificationIcon ?
                                <Link id="notification-icon" className="btn d-lg-inline-block mb-3 mb-md-0 ml-md-3" to="/notifications">
                                    <FontAwesomeIcon icon={iconBell} />
                                    {!!this.props.notifications && <span id="notification-number">{this.props.notifications}</span>}
                                </Link>
                            : null }
                            <Link id="account-icon" className="btn d-lg-inline-block mb-3 mb-md-0 mr-xs-3" to="/profile">
                                <FontAwesomeIcon icon={iconUser} />
                            </Link>
                            <Link id="logout-icon" onClick={() => this.logout()} className="btn d-lg-inline-block mb-3 mb-md-0" to="/">
                                <FontAwesomeIcon icon={iconLogout} />
                            </Link>
                        </React.Fragment>
                    ) : null}
                </div>
            </header>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

function mapStateToProps(state) {
    return {};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Header)
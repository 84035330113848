//import { faAddressBook, faFolderOpen, faBuilding, faUser, faSun, faPaperPlane } from '@fortawesome/free-regular-svg-icons'
import { faUsers,  faCubes, faList/*, faQuestion, faCube, faHistory*/ } from '@fortawesome/free-solid-svg-icons'
import { roles } from './constants'

export const navLinks = {
    timescope:[
        {
            path:"/games",
            name:"Applications",
            mobile:true,
            desktop:true,
            icon:faList,
            noprefix:true
        },
        {
            path:"/users",
            name:"Utilisateurs",
            mobile:true,
            desktop:true,
            icon:faUsers,
            noprefix:true
        },
        {
            desktop:true,
            type:"spacebar"
        },
		{
            path:"/mbuilding",
            name:"Tours",
            mobile:true,
            desktop:true,
            icon:faCubes,
            rank:roles.ROLE_ADMIN.rank
		},
		{
            path:"/msphere",
            name:"Spheres",
            mobile:true,
            desktop:true,
            icon:faCubes,
            rank:roles.ROLE_ADMIN.rank
		},
		{
            path:"/mlayer",
            name:"Layers",
            mobile:true,
            desktop:true,
            icon:faCubes,
            rank:roles.ROLE_ADMIN.rank
		},
		{
            path:"/mpoi",
            name:"POI",
            mobile:true,
            desktop:true,
            icon:faCubes,
            rank:roles.ROLE_ADMIN.rank
		},
		{
            path:"/mcard",
            name:"Fiches",
            mobile:true,
            desktop:true,
            icon:faCubes,
            rank:roles.ROLE_ADMIN.rank
		},
		{
            path:"/mstory",
            name:"Stories",
            mobile:true,
            desktop:true,
            icon:faCubes,
            rank:roles.ROLE_ADMIN.rank
		},
		{
            path:"/m3dmodel",
            name:"3d models",
            mobile:true,
            desktop:true,
            icon:faCubes,
            rank:roles.ROLE_ADMIN.rank
		},
        {
            path:"/maudioguid",
            name:"Audio Guids",
            mobile:true,
            desktop:true,
            icon:faCubes,
            rank:roles.ROLE_ADMIN.rank
		},
        {
            path:"/things",
            name:"Objets",
            mobile:true,
            desktop:true,
            icon:faCubes,
            rank:roles.ROLE_ADMIN.rank
		},
        {
            path:"/mquestion",
            name:"Questions",
            mobile:true,
            desktop:true,
            icon:faCubes,
            rank:roles.ROLE_ADMIN.rank
        },
		{
			path:"/ffiles",
            name:"Fichiers",
            mobile:true,
            desktop:true,
            icon:faCubes,
            rank:roles.ROLE_ADMIN.rank
		},
        {
            path:"/translations",
            name:"Traductions",
            mobile:true,
            desktop:true,
            icon:faCubes,
            rank:roles.ROLE_ADMIN.rank
        },
        {
            path:"/sphereditor",
            name:"Sphere Editor",
            mobile:true,
            desktop:true,
            icon:faCubes,
            rank:roles.ROLE_ADMIN.rank
        },
        {
            path:"/export",
            name:"Export",
            mobile:true,
            desktop:true,
            icon:faCubes,
            rank:roles.ROLE_ADMIN.rank
        },
        {
            path:"/mversion",
            name:"Versions",
            mobile:true,
            desktop:true,
            icon:faCubes,
            rank:roles.ROLE_ADMIN.rank
        },
        {
            path:"/fqrcodes",
            name:"QR Codes",
            mobile:true,
            desktop:true,
            icon:faCubes,
            rank:roles.ROLE_ADMIN.rank
        }
    ]
}
import React, { Component } from 'react';

//import { Input } from 'gj_modules/gj-strap/components/form';
import { Row, Col } from 'react-bootstrap';

class PanelDisplay extends Component {
    renderSwitch(f, index) {
        const { width } = this.props;

        switch (f.type) {
            case 'text':
                return (
                    <Row key={index}>
                        <Col sm={width[0]} className="my-auto text-right">
                            {f.name}
                        </Col>
                        <Col sm={width[1]}>
                            {f.value}
                        </Col>
                    </Row>
                );
            default:
                return(null)
        }
    }

    render() {
        const { inputs } = this.props;

        return (
            <React.Fragment>
                {inputs.map((f, index) =>
                    this.renderSwitch(f, index)
                )}
            </React.Fragment>
		);
	}
}

export default PanelDisplay;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import history from '../../../../history';

//import { resetGame, getGameAll/*, addProject, deleteProject*/ } from '../../actions/game'

import { Panel } from './../../../../gj_modules/gj-strap/components/form';
import FullCenter from './../../../../gj_modules/gj-strap/components/partials/FullCenter';

import { ComplexAll } from './../../../../gj_modules/gj-strap/components/complex'

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import { modalFunction } from './../../../../gj_modules/gj-strap/components/partials';

import { required } from '../../lib/validate';

import { actions } from '../../stuva';
const elementActions = actions["game"];
const getAll = elementActions.getAll;
const reset = elementActions.reset;


class Games extends Component {

    componentDidMount(){
    }

    renderTd = (e) => {
        return {
            id: e._id,
            key: "game_"+e._id,
            name: e.name
        }
    }
    render(){

        const { game, menu /*addProject, deleteProject*/ } = this.props;

        const properties = [
            { short: "id", width: "5%", category: "id"},
            { short: "name", name: "Nom", width: "80%", category: "classic"},
            { short: "actions", name: "Actions", width: "10%", category: "actions" }
        ];

        const filters = [
            { type:"search", size:80, name:"searchGameByName", placeholder:"Recherche par nom" },
            {
                type:"button",
                size:20,
                name:"",
                title:"Ajouter une application",
                icon:faPlusCircle,
                fn:() => {
                    modalFunction({
                        submit:(values) => {
                            /*if(!user.pendingAdd){
                                addProject(values).then(
                                    function(response){
                                        history.push("/sic/project/"+response.response.data.projects)
                                    }
                                );
                            }*/
                        },
                        text:(
                            <Panel
                                width={[3, 9]}
                                inputs={[
                                    { short: "name", name: "Nom", type: "text", placeholder: "Nom d'utilisateur", validate:required() },
                                ]}
                            />
                        ),
                        header:"Ajouter un utilisateur",
                        state:"user.pendingAdd"
                    }, "popup")
                }
            }
        ]

        const portlet = {
            title:"Applications",
            links:[
                {name:"Recherche", path:"/games"}
            ]
        }
            
        return(
            <React.Fragment>
                <div id="popup"></div>
                <MenuLeft menu={menu} />
                <HeaderTop menu={menu} />
                <FullCenter portlet={portlet}>
                    <ComplexAll
                        actions={elementActions}
                        elements={game.games}
                        properties={properties}
                        filters={filters}
                        renderTd={this.renderTd}
                        pending={game.pending}
                        noDataMessage = {"Pas de jeux"}
                        deleteAction = {(e) => {
                            modalFunction({
                                btnFunction:() => {
                                    //deleteProject(e)
                                },
                                btnText:"Confirmer",
                                text:"Êtes-vous sûr ?",
                                header:"Supprimer un jeu",
                                state:"game.pendingDelete"
                            }, "popup")
                        }}
                        editAction = {(id) => ( history.push("/game/"+id) )}
                    />
                </FullCenter>
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            reset,
            getAll
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        game: state.game,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Games);

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SubHeader from './SubHeader';
import "./FullCenter.css";


export default ({ children, portlet }) => (
    <div className={portlet ? "content pb-5" : "content py-5"}>
        <div className="fullcenter">
            {portlet ? <SubHeader portlet={portlet} /> : null}
            <Row>
                <Col lg="12" xl="12">
                    {children}
                </Col>
            </Row>
        </div>
    </div>
);
import { thing, mbuilding, msphere, mlayer, mpoi, mcard, mstory, m3dmodel, mquestion, mversion, maudioguid } from 'stuva';

let stamstrap = {
    models:{
        thing:thing,
		mbuilding:mbuilding,
		msphere:msphere,
		mlayer:mlayer,
		mpoi:mpoi,
		mcard:mcard,
		mstory:mstory,
		m3dmodel:m3dmodel,
		maudioguid: maudioguid,
		mquestion:mquestion,
		mversion:mversion
    }
}

export default stamstrap;
/**
 * transform a number of minutes into a hh:mm string,
 * example: `390` to `"06:30"`
 * @param {number} minutes
 * @return {string} formatted time
 */
export function formatHour(minutes) {
    const m = Math.round(minutes);
    const left = m % 60;
    return `${((m - left) / 60)
        .toString()
        .padStart(2, '0')}:${left.toString().padStart(2, '0')}`;
}

/**
 * transform a formatted string hh:mm into a number of minutes,
 * example: `"06:30"` to `390`
 * @param {string} time
 * @return {number} minutes
 */
export function timeStrToMinutes(time) {
    const t = time.split(':');
    return parseInt(t[0], 10) * 60 + parseInt(t[1], 10);
}

import { getData } from './index';

import {
    MENU_RESET,
    MENU_GAME_UPDATE,
    MENU_GAME_RESET,
    MENU_GAME_ALL,
    MENU_ERROR
} from './types';

import history from '../../../history';

export function resetMenu() {
    return dispatch => dispatch({ type: MENU_RESET });
}

export function setMenuGame(id, game) {
	return (dispatch) => {
		//const { menu } = getState();
		dispatch({ type: MENU_GAME_UPDATE, action:{id:id, game:game }});
		history.push('/');
	};
}

export function resetMenuGame() {
    return dispatch => dispatch({ type: MENU_GAME_RESET });
}

export function getMenuGameAll() {
    const url = '/game/all';
    return dispatch => getData(MENU_GAME_ALL, null, MENU_ERROR, true, url, dispatch);
}
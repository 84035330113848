import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretSquareRight } from '@fortawesome/free-regular-svg-icons'
import { Button } from 'react-bootstrap';
import './SubHeader.css';

class SubHeader extends Component {

    componentDidMount() {

    }

    renderNavPortlet = () => {

        let portlets = this.props.portlet.links;

        return portlets
            .map((p, i) => {
                return (
                    <Link key={""+i+"portlet"} to={p.path} className="mx-3">{p.name}</Link>
                )
            });
    }

    render() {
        const {portlet} = this.props;

        return(
            <Row>
                <Col lg="6" xl="6">
                    <div className="portletNav py-4">
                        { portlet.path ? (
                            <Link to={portlet.path}>
                                <span className="portletTitle">{portlet.title}</span>
                            </Link>
                        ) : (
                            <span className="portletTitle">{portlet.title}</span>
                        )}
                        <FontAwesomeIcon className="portletIcon" icon={faCaretSquareRight} />
                        {this.renderNavPortlet()}
                    </div>
                </Col>
                <Col lg="6" xl="6">
                    <div className="">
                        {portlet.actions ? (
                            portlet.actions.map((action, i) => (
                                <Button key={""+i+"portlet"} className="float-sm-right my-3 ml-3" variant={action.variant} onClick={action.function}>
                                    {action.icon ? (
                                        <React.Fragment>
                                            <FontAwesomeIcon icon={action.icon} />{'\u00A0'}
                                        </React.Fragment>
                                    ) : null}
                                    {action.title}
                                </Button>
                            ))
                        ) : ""
                    }
                        
                    </div>
                </Col>
            </Row>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SubHeader));
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import './PanelButton.scss';

class PanelButton extends Component {

    renderButton() {
        const {
            pending,
            message,
            updateText = "Mise à jour...",
            validText = "Sauvegarder",
            className = "float-sm-right",
            id = "",
            type = "submit",
            onClick = null
        } = this.props;

        return (
            <React.Fragment>
                <Button id={id} className={className} type={type} variant="success" onClick={onClick ? onClick : null}>
                    {pending ? updateText : validText}
                </Button>
                {message &&
                    <div className="pt-2">
                        <span className={pending ? "toaster-base toaster-off" : "toaster-base toaster-on"}>
                            {message}
                        </span>
                    </div>
                }
            </React.Fragment>
        )
    }

    render() {
        const {
            panel = true
        } = this.props;

        return (
            <React.Fragment>
                {panel ? (
                    <div className="mt-5">
                        {this.renderButton()}
                    </div>
                ):(
                    this.renderButton()
                )}                
            </React.Fragment>
        );
    }
}

export default PanelButton;
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faEdit, faTrashAlt, faArrowUp, faArrowDown, faEye } from '@fortawesome/free-solid-svg-icons'
import { Popover, OverlayTrigger, Button } from 'react-bootstrap'
import { modalFunction } from '../partials';
import './DataTable.scss';

class DataTable extends Component {

    getLines(arrayParams, params, showAll = 0, showMore = null, refresh) {
        const { collection: dataCollection, clickAction, moreAction, editAction, deleteAction, viewAction, noEditGlobal, focus} = this.props;

        let idContent = null;

        return dataCollection.slice().map((element, index) => {

            const content = arrayParams.map((param) => {

                let styles = {
                    width: params[param.short].width,
                    marginLeft: params[param.short].marginLeft,
                    marginRight: params[param.short].marginRight,
                }

                if (params[param.short].style) {
                    if (Array.isArray(params[param.short].style)) {
                        for (let s of params[param.short].style) {
                            styles = {
                                ...styles,
                                ...s
                            }
                        }
                    } else {
                        styles = {
                            ...styles,
                            ...params[param.short].style
                        }
                    }
                }

                return (
                    <td
                        className={`datatable-cell ${param.className ? param.className : null}`}
                        style={styles}
                        key={"cell-" + param.short}
                    >
                        <span onClick={(e) => {
                            e.preventDefault()
                            if (!editAction && !clickAction && !showMore && refresh) {
                                window.location.href = element[param.short.toLowerCase()];
                                window.location.reload();
                            }
                        }}>{element[param.short.toLowerCase()]}</span>
                    </td>
                );
            });

            if (params["id"]) {
                idContent = <td
                    className="datatable-cell center"
                    data-field="id"
                    style={{ width: params["id"].width }}
                >
                    <span>{element.index}</span>
                    {/*<Link to={element.link}>{element.id}</Link>*/}
                </td>
            }

            let focusClass = "";

            if (focus) {
                if (focus.id === element.id) {
                    if (focus.hasOwnProperty("class")) {
                        focusClass = " " + focus.class;
                    }
                }
            }

            const trRender = (
                <tr
                    className={((editAction || clickAction || viewAction) && !noEditGlobal && focusClass === "") ? ("datatable-row datatable-row-hover" + focusClass + (index === showAll - 1 ? " datatable-row-showMore " : " ") + element.customClass) : ("datatable-row" + focusClass + (index === showAll - 1 ? " datatable-row-showMore " : " ") + element.customClass)}
                    onClick={() => {
                        if (!noEditGlobal) {
                            if (clickAction) {
                                clickAction(element);
                            } else if (editAction) {
                                editAction(element.id)
                            } else if (viewAction) {
                                viewAction(element.id)
                            }
                        }
                    }}
                    style={element.style}
                >

                    {idContent}
                    {/*<td
                        className="datatable-cell center"
                        data-field="id"
                        style={{ width: params["id"].width }}
                    >
                        {element.id}
                    </td>*/}

                    {content}

                    {params["actions"] ? (
                        <td className="datatable-cell text-right" style={{ width: params["actions"].width }}>
                            <span>
                                <div className="dropdown">
                                    {moreAction ?
                                        <p
                                            title="3 petits points"
                                            className="btn btn-actions"
                                            onClick={e => { e.stopPropagation(); moreAction && moreAction(element.id) }}
                                        >
                                            <FontAwesomeIcon icon={faEllipsisH} />
                                        </p>
                                        : null}
                                    {viewAction ?
                                        <p
                                            title="Aperçu"
                                            className="btn btn-actions"
                                            onClick={e => { e.stopPropagation(); viewAction && viewAction(element.id) }}
                                        >
                                            <FontAwesomeIcon icon={faEye} />
                                        </p>
                                        : null}
                                    {editAction ?
                                        <p
                                            title="Editer"
                                            className="btn btn-actions"
                                            onClick={e => { e.stopPropagation(); editAction && editAction(element.id) }}
                                        >
                                            <FontAwesomeIcon icon={faEdit} />
                                        </p>
                                        : null}
                                    {deleteAction ?
                                        <p
                                            title="Supprimer"
                                            className="btn btn-actions"
                                            onClick={e => { e.stopPropagation(); deleteAction && deleteAction(element.id) }}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </p>
                                        : null}
                                </div>
                            </span>
                        </td>
                    ) : null}
                </tr>
            );

            //let popoverContent = null;

            if (element.hasOwnProperty("popover")) {
                const popover = (
                    <Popover id="popover-basic">
                        {element.popover.title ? (
                            <Popover.Title as="h3">{element.popover.title}</Popover.Title>
                        ) : ""}
                        <Popover.Content>
                            {element.popover.content}
                        </Popover.Content>
                    </Popover>
                );

                return (
                    <OverlayTrigger container={document.body} trigger={element.popover.trigger} placement={element.popover.placement} overlay={popover} key={element.key}>
                        {(!showAll || showAll === 0 || index < showAll) ? trRender : (showAll === index ? showMore : null)}
                    </OverlayTrigger>
                )
            } else {
                return (
                    <React.Fragment key={element.key}>
                        {(!showAll || showAll === 0 || index < showAll) ? trRender : (showAll === index ? showMore : null)}
                    </React.Fragment>
                )
            }
        });
    }


    render() {
        const { clickAction, moreAction, viewAction, editAction, deleteAction, properties, propertiesTop, propertiesOrderBy, propertyOrderBy, collection: dataCollection, noDataMessage, small, dataTableClass, pending, blockClass, showAll = 0, refresh = false } = this.props;

        const params = {};
        const arrayParams = [];
        let idTitle, collection = null;

        let finalPropertiesTop = null;

        if (propertiesTop) {
            finalPropertiesTop = propertiesTop.slice().map((property) => {

                let styles = { width: property.width }

                if (property.style) {
                    if (Array.isArray(property.style)) {
                        for (let s of property.style) {
                            styles = {
                                ...styles,
                                ...s
                            }
                        }
                    } else {
                        styles = {
                            ...styles,
                            ...property.style
                        }
                    }
                }

                return (
                    <th
                        //className="datatable-cell"
                        key={property.name + "" + Math.random()}
                        colSpan={property.colspan}
                        style={styles}
                    >
                        <span>{property.name}</span>
                    </th>
                );
            })
        }

        const titles = properties.slice().map((property) => {

            if (property.category === undefined) {
                property.category = "classic"
            }

            params[property.short] = {
                ...property
            };
            if (property.category === "classic") {

                arrayParams.push(params[property.short]);

                let name = property.short;

                if (property.name !== undefined && property.name !== null) {
                    name = property.name;
                }

                let styles = {
                    width: property.width
                }

                if (property.style) {
                    if (Array.isArray(property.style)) {
                        for (let s of property.style) {
                            styles = {
                                ...styles,
                                ...s
                            }
                        }
                    } else {
                        styles = {
                            ...styles,
                            ...property.style
                        }
                    }
                }

                return (
                    <th
                        className="datatable-cell"
                        key={property.short}
                        style={styles}
                    >
                        {property.orderby ? (
                            <React.Fragment>
                                <span onClick={() => propertiesOrderBy(property.short)} className="click">{name}</span>
                                {propertyOrderBy && propertyOrderBy.name === property.short ? (
                                    <FontAwesomeIcon className="way" icon={propertyOrderBy.way === "desc" ? faArrowUp : faArrowDown} />
                                ) : null}
                            </React.Fragment>

                        ) : (
                                <span>{name}</span>
                            )}
                    </th>
                );
            } else {
                return null;
            }
        });

        if (params["id"]) {
            idTitle = <th className="datatable-cell" style={{ width: params["id"].width }}>
                <span>#<i className="fas fa-arrow-up"></i></span>
            </th>
        }

        // Si la collection est vide
        if (!dataCollection || dataCollection.length === 0) {
            const content = noDataMessage ? noDataMessage : "No data";

            collection = <React.Fragment>
                <tr className="datatable-row">
                    <td
                        className="my-5"
                        style={{ textAlign: "center" }}
                        colSpan={"3"}
                    >
                        <div className="my-5">{content}</div>
                    </td>
                </tr>
            </React.Fragment>

            // Si la collection est inférieure à l'aff. max
        } else if (!showAll || showAll >= dataCollection.length) {
            collection = this.getLines(arrayParams, params, 0, null, refresh);

            // Si on doit afficher la popup         
        } else {
            const showMore = (
                <tr className="datatable-row" style={{ margin: "5px" }}>
                    <td
                        className="my-5"
                        style={{ textAlign: "center" }}
                        colSpan={"3"}
                    >
                        <Button onClick={
                            () => {
                                modalFunction({
                                    text: (
                                        <DataTable small
                                            properties={properties}
                                            collection={dataCollection}
                                            showAll={0}
                                            params={params}
                                            viewAction={viewAction}
                                            clickAction={clickAction}
                                            moreAction={moreAction}
                                            editAction={editAction}
                                            deleteAction={deleteAction}
                                            refresh={refresh}
                                        />
                                    ),
                                    state: "popup",
                                    header: "Liste complète"
                                }, "popup")
                            }
                        }>
                            {"Afficher plus..."}
                        </Button>
                    </td>
                </tr>
            );
            collection = this.getLines(arrayParams, params, showAll, showMore, refresh);

        }

        return (
            <React.Fragment>
                <div
                    className={[
                        "datatable",
                        blockClass
                    ].join(' ')}
                    id="column_rendering"
                >
                    {pending ? (
                        <div className="loadingState">
                            Chargement
                        </div>
                    ) : null}
                    <table className={[
                        small ? "datatable-table datatable-table-small" : "datatable-table",
                        pending ? "datatable-table-pending" : "",
                        dataTableClass
                    ].join(' ')}>
                        {small ? null : (
                            <thead className="datatable-head">

                                {finalPropertiesTop ? (
                                    <tr className="datatable-row">
                                        {finalPropertiesTop}
                                    </tr>
                                ) : null}

                                <tr className="datatable-row">

                                    {idTitle}
                                    {/*<th className="datatable-cell" style={{ width: params["id"].width }}>
                                        <span>#<i className="fas fa-arrow-up"></i></span>
                                    </th>*/}

                                    {titles}
                                    {params["actions"] ? (
                                        <th className="datatable-cell" style={{ width: params["actions"].width }}>
                                            <span>{(params["actions"].name !== undefined) ? params["actions"].name : "Actions"}</span>
                                        </th>
                                    ) : null}
                                </tr>
                            </thead>
                        )}
                        <tbody className="datatable-body">
                            {collection}
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    };
};

export default DataTable;

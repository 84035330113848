import {
    MENU_RESET,
    MENU_GAME_UPDATE,
    MENU_GAME_RESET,
    MENU_GAME_ALL,
    MENU_ERROR
} from '../actions/types';

const INITIAL_STATE = {
    game: null,
    gameId: null,
    error: '',
    games: null,
    message: null
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case MENU_GAME_UPDATE:
            return {
                ...state,
                gameId: action.action.id,
                game:action.action.game
            };
        case MENU_GAME_RESET:
            return {
                ...state,
                games: null
            }
        case MENU_GAME_ALL:
            let games = action.payload.games;
            games['count'] = action.payload.gamesCount;
            return {
                ...state,
                games: games,
                error: '',
                message: action.payload.message
            };
        case MENU_RESET:
            return {
                ...state,
                game: null,
                gameId: null,
                error: '',
                message: null
            };
        case MENU_ERROR:
            return { ...state, error: action.payload.message };
        default:
            return state;
    }
}

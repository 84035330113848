import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import history from '../../../../history';

import { resetUser, getUserAll/*, addProject, deleteProject*/ } from '../../actions/user'

import { Panel } from './../../../../gj_modules/gj-strap/components/form';
import FullCenter from './../../../../gj_modules/gj-strap/components/partials/FullCenter';

import { ComplexAll } from './../../../../gj_modules/gj-strap/components/complex'

import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import { modalFunction } from './../../../../gj_modules/gj-strap/components/partials';

import { required } from '../../lib/validate';

class Users extends Component {
    componentDidMount(){
    }

    renderTd = (e) => {
        return {
            id: e._id,
            key: "users_"+e._id,
            login: e.login,
            rank: e.rank,
            email: e.email,
        }
    }

    render(){

        const { user, menu /*addProject, deleteProject*/ } = this.props;

        const properties = [
            { short: "id", width: "10%", category: "id"},
            { short: "login", name: "Login", width: "30%"},
            { short: "rank", name: "Rang", width: "20%"},
            { short: "email", name: "Email", width: "30%"},
            { short: "actions", name: "Actions", width: "10%", category: "actions" }
        ];

        const filters = [
            { type:"search", size:40, name:"searchUserByLogin", placeholder:"Recherche par login" },
            { type:"search", size:40, name:"searchUserByEmail", placeholder:"Recherche par email" },
            {
                type:"button",
                size:20,
                name:"",
                title:"Ajouter un utilisateur",
                icon:faPlusCircle,
                fn:() => {
                    modalFunction({
                        submit:(values) => {
                            /*if(!user.pendingAdd){
                                addProject(values).then(
                                    function(response){
                                        history.push("/sic/project/"+response.response.data.projects)
                                    }
                                );
                            }*/
                        },
                        text:(
                            <Panel
                                width={[3, 9]}
                                inputs={[
                                    { short: "name", name: "Nom", type: "text", placeholder: "Nom d'utilisateur", validate:required() },
                                ]}
                            />
                        ),
                        header:"Ajouter un utilisateur",
                        state:"user.pendingAdd"
                    }, "popup")
                }
            }
        ]

        const portlet = {
            title:"Utilisateurs",
            links:[
                {name:"Recherche", path:"/users"}
            ]
        }
            
        return(
            <React.Fragment>
                <div id="popup"></div>
                <MenuLeft menu={menu} />
                <HeaderTop menu={menu} />
                <FullCenter portlet={portlet}>
                    <ComplexAll
                        getElementsAll={getUserAll}
                        resetElements={resetUser}
                        elements={user.users}
                        properties={properties}
                        filters={filters}
                        renderTd={this.renderTd}
                        pending={user.pending}
                        noDataMessage = {"Pas d'utilisateurs"}
                        deleteAction = {(e) => {
                            modalFunction({
                                btnFunction:() => {
                                    //deleteProject(e)
                                },
                                btnText:"Confirmer",
                                text:"Êtes-vous sûr ?",
                                header:"Supprimer un utilisateur",
                                state:"user.pendingDelete"
                            }, "popup")
                        }}
                        editAction = {(id) => ( history.push("/user/"+id) )}
                    />
                </FullCenter>
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetUser,
            getUserAll
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Users);

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Modal, Button } from 'react-bootstrap';
import { Form } from '../form';

class ModalSimple extends Component {

    constructor(props) {
        super(props);
        this.state = { first: true };
    }

    componentDidUpdate(){
        const { modal, pending } = this.props;

        if (pending && this.state.first) {
            this.setState({
                first: false
            });
        }

        if (!pending && !this.state.first) {
            modal.onHide()
        }
    }

    outSide = () => {
        if(!this.props.pending){
            this.props.modal.onHide()
        }
    }

    render() {
        const { modal, pending } = this.props;

        let finalPending = pending;

        if (modal.free) {
            finalPending = false;
        }

        return (
            <Modal
                show={true}
                onHide={this.outSide} //modal.onHide
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Form
                    onSubmit={(values) => {
                        modal.submit(values)
                        //modal.onHide()
                    }}
                    initialValues={modal.initialValues}
                >
                    {modal.header ? (
                        <Modal.Header>
                            <b>{modal.header}</b>
                        </Modal.Header>
                    ) : null }
                    <Modal.Body>
                        {finalPending ?
                            "Chargement..."
                            : (
                                modal.text
                            )}
                    </Modal.Body>
                    {finalPending ? null : (
                        <Modal.Footer>

                            {modal.submit ? <Button type="submit">Soumettre</Button> : null}

                            {(modal.btnFunction && !modal.free) ? <Button onClick={() => {
                                modal.btnFunction()
                                //modal.onHide()
                            }}>{modal.btnText}</Button> : null}
                            {modal.close ? <Button onClick={modal.onHide}>{modal.closeText}</Button> : null}
                        </Modal.Footer>
                    )}
                </Form>
            </Modal>
        )
    }
}

function recompose(obj, string, free = false) {
    if (!free) {
        var parts = string.split('.');
        var newObj = obj[parts[0]];
        if (parts[1]) {
            parts.splice(0, 1);
            var newString = parts.join('.');
            return recompose(newObj, newString);
        }
        return newObj;
    } else {
        return obj;
    }
}

function mapStateToProps(state, ownProps) {
    return {
        pending: recompose(state, ownProps.modal.state, ownProps.modal.free)
    }
}

export default connect(mapStateToProps)(ModalSimple)
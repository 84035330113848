import {
    STRANSLATION_UPDATE
} from '../types/stranslation';

const INITIAL_STATE = {
    currentLangage: ""
};

export default function(state = INITIAL_STATE, action) {
    switch (action.type) {
        case STRANSLATION_UPDATE:
            return {
                ...state,
                currentLangage:action.payload
            };
        default:
            return state;
    }
}

import React, { Component } from 'react';

import { Media, Row, Col } from 'react-bootstrap';
import { Avatar } from '../display';

class Cover extends Component {
	render() {
        
        const { children, icon, title, subtitle } = this.props;
        
		return (
            <Row>
                <Col sm="6">
                    <Media>
                        <Avatar icon={icon} />
                        <Media.Body>
                            <h3>{title}</h3>
                            <p className="my-0">{subtitle}</p>
                        </Media.Body>
                    </Media>
                </Col>
                <Col sm="6" className="text-right">
                    {children}
                </Col>
            </Row>
		);
	}
}

export default Cover

//Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//stam-strap
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
//import { required } from '../../lib/validate';
//child-app
import history from '../../../../history';
//gj-strap
import { ComplexAll } from './../../../../gj_modules/gj-strap/components/complex'
import { FullCenter, modalFunction } from './../../../../gj_modules/gj-strap/components/partials';
import { processSubmit } from './../../../../gj_modules/gj-strap/lib';
import { GetTranslation } from '../partials';
//various
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
//gj-stuva
import { /*generatePanelNew,*/ generateInitialValue } from './../../../../gj_modules/gj-stuva/forms'
//import { generatePanelNew } from 'gj_modules/gj-stuva/forms'
import { PanelNew } from './../../../../gj_modules/gj-stuva/components'
import { actions } from '../../stuva';
const elementActions = actions["unit"];
const getAll = elementActions.getAll;
const add = elementActions.add;
const trashById = elementActions.trashById;
const reset = elementActions.reset;

class Units extends Component {

    componentDidMount(){
    }

    renderTd = (e) => {
        return {
            id: e._id,
            key: "unit_"+e._id,
            short: e.short,
            name: <GetTranslation quick={e.name && e.name.translations} />
        }
    }
    render(){

        const { unit, menu, match, add, trashById, getAll, menuGame } = this.props;

        const element = unit;

        const properties = [
            { short: "id", width: "5%", category: "id"},
            { short: "short", name: "Short", width: "30%", category: "classic"},
            { short: "name", name: "Nom", width: "50%", category: "classic"},
            { short: "actions", name: "Actions", width: "10%", category: "actions" }
        ];

        const filters = [
            { type:"search", size:80, name:"searchUnitByName", placeholder:"Recherche par nom" },
            {
                type:"button",
                size:20,
                name:"",
                title:"Ajouter une unité",
                icon:faPlusCircle,
                fn:() => {
                    modalFunction({
                        submit:(values) => {
                            console.log(processSubmit(values));
                            if(!element.pendingAdd){
                                add(processSubmit(values)).then(
                                    function(response){
                                        getAll();
                                        //history.push("/sic/project/"+response.response.data.projects)
                                    }
                                );
                            }
                        },
                        initialValues:generateInitialValue(elementActions.model, {game:[{_id:menuGame._id, name:menuGame.name}]}),
                        text:<PanelNew
                            width={[3, 9]}
                            model={elementActions.model}
                            filter={"add"}
                        />,
                        header:"Ajouter une unité",
                        state:"unit.pendingAdd"
                    }, "popup")
                }
            }
        ]

        const portlet = {
            title:"Unités",
            links:[
                {name:"Recherche", path:"/units"}
            ]
        }
            
        return(
            <React.Fragment>
                <div id="popup"></div>
                <MenuLeft menu={menu} />
                <HeaderTop menu={menu} />
                <FullCenter portlet={portlet}>
                    <ComplexAll
                        actions={elementActions}
                        elements={element.units}
                        properties={properties}
                        filters={filters}
                        renderTd={this.renderTd}
                        pending={element.pending}
                        noDataMessage = {"Pas d'unités"}
                        deleteAction = {(e) => {
                            modalFunction({
                                btnFunction:() => {
                                    trashById(e).then(
                                        function(response){
                                            getAll();
                                        }
                                    );
                                },
                                btnText:"Confirmer",
                                text:"Êtes-vous sûr ?",
                                header:"Supprimer une unité",
                                state:"unit.pendingTrash"
                            }, "popup")
                        }}
                        editAction = {(id) => ( history.push("/"+match.params.id+"/unit/"+id) )}
                    />
                </FullCenter>
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            reset,
            getAll,
            trashById,
            add
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        unit: state.unit,
        menuGame: state.menu.game
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Units);

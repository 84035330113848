export const fvalue = {
    name: "fvalue",
    actions: [
        "all", "id", "add", "update", "trash", "reset"
    ],
    model: {
        game:{
            name:"Application",
            type:"select",
            data:{
                isMulti:true
            },
            modelType:"game",
            update:true,
            add:true
        },
        short: {
            name: "Short",
            type: "text",
            placeholder: "Short",
            add: true,
            update: true
        },
        name: {
            name: "Nom",
            placeholder: "Nom de la valeur",
            type: "translationtext",
            update: true
        },
        type:{
            name:"Type",
            type:"select",
            data:{
                isMulti:false,
                options:[
                    {
                        label:"text",
                        value:"text"
                    },
                    {
                        label:"int",
                        value:"int"
                    },
                    {
                        label:"float",
                        value:"float"
                    }
                ]
            },
            update:true
        },
        unit:{
            name:"Unité",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"unit",
            update:true,
        },
        description: {
            name: "Description",
            type: "textarea",
            placeholder: "Description",
            update: true
        },
        data: {
        }
    }
}
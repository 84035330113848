export const langages = [
    {
        value: "fr",
        label: "Français"
    },
    {
        value: "en",
        label: "English"
    },
    {
        value: "de",
        label: "Deutsche"
    },
    {
        value: "es",
        label: "Español"
    },
    {
        value: "nl",
        label: "Nederlands"
    }
]
import {
	MPOI_UPDATE_OFFSET
} from './../actions/types';

export default function (state, action) {
    switch (action.type) {
        case MPOI_UPDATE_OFFSET:

			if(state.mpoi && state.mpoi.mpois){

				let mpois = state.mpoi.mpois.slice();

				for(let i=0; i<mpois.length; i++){
					if(String(mpois[i]._id) === String(action.payload.id)){
						//console.log(action.payload.offset)
						mpois[i][action.payload.param] = action.payload.value
					}
				}

				let mpoi = Object.assign(state.mpoi.mpoi, {});

				mpoi[action.payload.param] = action.payload.value

				return {
					...state,
					mpoi:{
						...state.mpoi,
						mpois:mpois,
						mpoi:mpoi
					}
				};

			}else{
				return {
					...state
				};
			}

        default:
            return state;
    }
}

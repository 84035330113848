//React
import { combineReducers } from 'redux';
//Stuva reducers
import { reducers } from '../stuva';
import { stranslationReducer } from './../../../gj_modules/gj-stuva/reducers';
//Local reducers
import authReducer from './auth_reducer';
import menuReducer from './menu_reducer';
import userReducer from './user_reducer';
//import gameReducer from './game_reducer';
import sessionReducer from './session_reducer';
import blockReducer from './block_reducer';
import clusterReducer from './cluster_reducer';
import notificationReducer from './notification_reducer';

let reducersTemp = {
    auth: authReducer,
    menu: menuReducer,
    user: userReducer,
    //game: gameReducer,
    session: sessionReducer,
    block: blockReducer,
    cluster: clusterReducer,
    notifications: notificationReducer,
    langage: stranslationReducer
}

const reducersFinal = combineReducers(Object.assign(reducersTemp, reducers));

export default reducersFinal;

//React
import React, { /*Component*/ } from 'react';
import { Route } from 'react-router-dom';
//stam-strap
import {
	Login,
	RequireAuth,
	NotFoundPage,
	Users,
	Game,
	Games,
	Action,
	Actions,
	Block,
	Blocks,
	Cluster,
	Clusters,
	Session,
	Sessions,
	Thing,
	Things,
	Ffile,
	Ffiles,
	Fvalue,
	Fvalues,
	Unit,
	Units,
	Translations,
	Translation,
	Fqrcodes,
	Fqrcode,
	FqrcodeRedirector
} from './components'

const generateComponent = function (C, match, menu) {
	return (
		<C
			match={match}
			menu={menu}
		/>
	)
}

const generateRoute = function (url, c, menu) {
	return <Route key={"key_route_" + url} exact path={url} render={(props) => generateComponent(c, props.match, menu)} />
}

export default function stamApp(params = {
	match: null,
	RequireAuth: null,
	menu: null,
	history: null,
	components: []
}
) {

	let pagesOutput = [];

	for (let c of params.components) {

		let output = null;

		switch (c) {
			case "login":
				output = generateRoute("/", Login, params.menu);
				break;
			case "users":
				output = generateRoute("/users", RequireAuth(Users, params.history), params.menu);
				break;
			case "games":
				output = generateRoute("/games", RequireAuth(Games, params.history), params.menu);
				break;
			case "game":
				output = generateRoute("/game/:id", RequireAuth(Game, params.history), params.menu);
				break;
			case "sessions":
				output = generateRoute("/:id/sessions", RequireAuth(Sessions, params.history), params.menu);
				break;
			case "session":
				output = generateRoute("/:id/session/:idsession", RequireAuth(Session, params.history), params.menu);
				break;
			case "blocks":
				output = generateRoute("/:id/blocks", RequireAuth(Blocks, params.history), params.menu);
				break;
			case "block":
				output = generateRoute("/:id/block/:idblock", RequireAuth(Block, params.history), params.menu);
				break;
			case "clusters":
				output = generateRoute("/:id/clusters", RequireAuth(Clusters, params.history), params.menu);
				break;
			case "cluster":
				output = generateRoute("/:id/cluster/:idcluster", RequireAuth(Cluster, params.history), params.menu);
				break;
			case "actions":
				output = generateRoute("/:id/actions", RequireAuth(Actions, params.history), params.menu);
				break;
			case "action":
				output = generateRoute("/:id/action/:idaction", RequireAuth(Action, params.history), params.menu);
				break;
			case "things":
				output = generateRoute("/:id/things", RequireAuth(Things, params.history), params.menu);
				break;
			case "thing":
				output = generateRoute("/:id/thing/:idthing", RequireAuth(Thing, params.history), params.menu);
				break;
			case "ffiles":
				output = generateRoute("/:id/ffiles", RequireAuth(Ffiles, params.history), params.menu);
				break;
			case "ffile":
				output = generateRoute("/:id/ffile/:idffile", RequireAuth(Ffile, params.history), params.menu);
				break;
			case "fvalues":
				output = generateRoute("/:id/fvalues", RequireAuth(Fvalues, params.history), params.menu);
				break;
			case "fvalue":
				output = generateRoute("/:id/fvalue/:idfvalue", RequireAuth(Fvalue, params.history), params.menu);
				break;
			case "units":
				output = generateRoute("/:id/units", RequireAuth(Units, params.history), params.menu);
				break;
			case "unit":
				output = generateRoute("/:id/unit/:idunit", RequireAuth(Unit, params.history), params.menu);
				break;
			case "translations":
				output = generateRoute("/:id/translations", RequireAuth(Translations, params.history), params.menu);
				break;
			case "translation":
				output = generateRoute("/:id/translation/:idtranslation", RequireAuth(Translation, params.history), params.menu);
				break;
			case "fqrcodes":
				output = generateRoute("/:id/fqrcodes", RequireAuth(Fqrcodes, params.history), params.menu);
				break;
			case "fqrcode":
				output = generateRoute("/:id/fqrcode/:idfqrcode", RequireAuth(Fqrcode, params.history), params.menu);
				break;
			case "p_fqrcode":
				output = generateRoute("/p/qrcode/:idfqrcode", FqrcodeRedirector, params.menu);
				break;
			default:
				break;
		}

		if (output !== null) {
			pagesOutput.push(output);
		}
	}

	if (params.components.indexOf("notfound") !== -1) {
		pagesOutput.push(<Route key={"key_route_notfound"} component={NotFoundPage} />)
	}

	return pagesOutput;
}
export const mquestion = {
    name: "mquestion",
	clean:"question",
    actions: [
        "all", "id", "add", "update", "trash", "reset"
    ],
    model: {
        game:{
            name:"Application",
            type:"select",
            data:{
                isMulti:true
            },
            modelType:"game",
            update:true,
            add:true
        },
        short: {
            name: "Short",
            type: "text",
            placeholder: "Short",
            add: true,
            update: true
        },
		mbuilding:{
            name:"Tour",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"mbuilding",
            update:true,
            add:true
        },
		media:{
            name:"Media",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"ffile",
            update:true
        },
        order: {
            name: "Ordre",
            type: "text",
            placeholder: "100",
            update: true
        },
        question: {
            name: "Question",
            type: "translationtext",
            placeholder: "Quelle est la hauteur de la Tour Eiffel ?",
            update: true
        },
        exemplequestion: {
            name: "Exemple",
            type: "desc",
            data: {
                value: "Quelle est la hauteur de la Tour Eiffel ?"
            },
            update: true
        },
        proposal: {
            name: "Propositions",
            placeholder: "210m;324m;580m",
            type:"translationtext",
            update: true
        },
        exempleproposals: {
            name: "Exemple",
            type: "desc",
            data: {
                value: "210m;324m;580m"
            },
            update: true
        },
        answer: {
            name: "Réponse",
            type: "text",
            placeholder: "2",
            update: true
        },
        comment: {
            name: "Commentaires",
            type: "translationtext",
            placeholder: "Short comment to illustrate the right answer",
            update: true
        }
    },
    filters:{
        default:{
            filter:"searchMquestionByShort",
            quantity:20,
            state:"mquestion.mquestions",
            value:"_id",
            label:"short"
        },
		all:[
			{
				type:"search",
				size:25,
				name:"searchMquestionByShort",
				placeholder:"Recherche par short"
			},
		]
    },
    display:{
        selectDefault:(e) => {
            return {
                label:e.name,
                value:e._id,
                force:true
            }
        }
    },
	properties:[
		{ short: "short", name: "Short", width: "40%", category: "classic"},
		{ short: "mbuilding", name: "Tour", width: "30%", category: "classic"},
		{ short: "order", name: "Ordre", width: "20%", category: "classic"},
		{ short: "actions", name: "Actions", width: "10%", category: "actions" }
	],
	renderTd:(e) => {
		return {
			id: e._id,
			key: "mquestion_"+e._id,
			short: e.short,
			mbuilding: e.mbuilding && e.mbuilding.short,
			order: e.order,
			default: e.default
		}
	}
}
import React, { Component } from 'react';
//Various
import { Button } from 'react-bootstrap';
//gj-strap
import { wrapField } from './Form';
import { Input, Select, TextArea } from '../form';
import DataTable from './DataTable';
//style
import './DataTableEdit.scss';

class DataTableEdit extends Component {
    constructor(props) {
        super(props)

        this.state = {
            index: 0
        }
	}
	
	componentDidMount(){
		const { input, data } = this.props;

		//console.log("didmount")

		let inputCopy = Object.assign({}, input);

		inputCopy.value = [];

		for (let v = 0; v < input.value.length; v++) {
			let temp = {
				index: v,
				value: input.value[v]["value"],
				langage: input.value[v]["langage"],
			};
			
			inputCopy.value.push(temp);
		}

		if (data.formater) {
            input.onChange(data.formater(inputCopy.value));
        } else {
            input.onChange(inputCopy.value)
		}

		if(inputCopy.value.length > 0){
			this.setState({index:inputCopy.value[inputCopy.value.length-1].index+1})
		}
	}

    renderSwitch(f, index) {
        //const { width } = this.props;

		const { input } = this.props;
		
		//console.log(input)

        switch (f.type) {
            case 'text':
            case 'password':
            case 'email':
                return (
                    <Input
                        //noForm
                        name={input.name+"_"+index+"_"+f.short}
                        type={f.type}
                        placeholder={f.placeholder}
                        validate={f.validate}
                        onChange={(e) => this.changeCell({
                            short: f.short,
                            value: e.target.value,
                            index: index
                        })}
                    />
                );
			case 'textarea':
				return (
					 <TextArea
						name={input.name+"_"+index+"_"+f.short}
						placeholder={f.placeholder}
						validate={f.validate}
                        onChange={(e) => this.changeCell({
                            short: f.short,
                            value: e.target.value,
                            index: index
                        })}
						//height={f.data && f.data.height ? f.data.height : null}
					/>
				)
            case 'select':
                let placeholderTemp = f.placeholder;

                if (f.data.address) {
                    if (f.data.address.hasOwnProperty("properties")) {
                        placeholderTemp = f.data.address.properties.label;
                    }
                }

                return (
                    <React.Fragment>
                        {f.data.options ? (
                            <Select
                                //noForm
                                name={input.name+"_"+index+"_"+f.short}
                                isClearable={true}
                                options={f.data.options}
                                validate={f.validate}
                                onChange={(e) => this.changeCell({
                                    short: f.short,
                                    value: e,
                                    index: index
                                })}
                            />
                        ) : (
                                <Select
                                    noForm
                                    inputId={"select-place-id-" + f.short}
                                    isMulti={f.data.isMulti}
                                    isAsync
                                    forceLabel={f.data.forceLabel}
                                    //cacheOptions
                                    name={input.name+"_"+index+"_"+f.short}
                                    validate={f.validate}
                                    noOptionsMessage={input => 'Cherchez'}
                                    loadOptions={f.data.loadOptions}
                                    defaultOptions
                                    placeholder={placeholderTemp}
                                    isClearable={true}
                                    defaultValue={f.data.defaultValue}
                                    cache={f.data.cache}
                                    getOptionValue={f.data.getOptionValue}
                                    getLabelValue={f.data.getOptionLabel}
                                //getOptionValue={(option)=>option.value}
                                //getLabelValue={(option)=>option.label}
                                /*validate={nonEmpty('Choisissez un lieu ou saisissez une adresse')}*/
                                /*getOptionValue={getOptionValue}*/
                                /*getOptionValue={(option)=>{
                                    return option.value
                                }}
                                getOptionLabel={option => {
                                    //console.log(option);
                                    if (option.label){
                                        return option.label;
                                    }else{
                                        console.log("nolabel");
                                        return "nolabel"
                                    }
                                }}*/
                                />
                            )}
                    </React.Fragment>
                )
            default:
                return (null)
        }
    }

    addRow = (forceIndex = null) => {
		const { input, data } = this.props;
		const { index } = this.state;

		let inputCopy = Object.assign({}, input);

		let temp = {
            id: index,
            index: index,
            key: "dataedit_" + index
        };

        if (data && data.rows && data.rows.length > 0) {
            for (let row of data.rows) {
                temp[row.short] = this.renderSwitch(row, index);
            }
		}

		inputCopy.value.push(temp);
		
		if (data.formater) {
            input.onChange(data.formater(inputCopy.value));
        } else {
            input.onChange(inputCopy.value)
		}

		this.setState({index:index+1})
    }

    changeCell = (e) => {
		const { input, data } = this.props;

		let found = null;
		let index = 0;

		for(let i of input.value){
			if(i.index === e.index){
				found = index;
			}
			
			if(found === null){
				index++;
			}
		}

		if(found !== null){

			let inputCopy = Object.assign({}, input);
			
			inputCopy.value[index][e.short] = e.value;

			if (data.formater) {
				input.onChange(data.formater(inputCopy.value));
			} else {
				input.onChange(inputCopy.value)
			}

		}
    }

    deleteRow = (e) => {

		const { input, data } = this.props;

		let found = null;
		let index = 0;

		var inputCopy = Object.assign({}, input);

		for(let i of inputCopy.value){
			if(i.index === e){
				found = index
			}else{
				index++;
			}
		}

		if(found !== null){
			inputCopy.value.splice(found, 1);

			if (data.formater) {
				input.onChange(data.formater(inputCopy.value));
			} else {
				input.onChange(inputCopy.value)
			}
		}

    }

    render() {
		const { input, /*label, meta, children, onChange,*/ data } = this.props;
		
		//console.log("renderDataTableEdit")

		//console.log(input)

        let properties = [];

        if (data && data.rows && data.rows.length > 0) {
            for (let row of data.rows) {
                properties.push({
                    short: row.short,
                    width: row.width
                })
            }
        }

        properties.push({
            short: "actions",
            width: "10%"
		})
		
		let collection = [];
		//console.log(input)
		for (let v = 0; v < input.value.length; v++) {

			let temp = {
				id: input.value[v].index,
				index: input.value[v].index,
				key: "dataedit_" + input.value[v].index
			};
	
			if (data && data.rows && data.rows.length > 0) {
				for (let row of data.rows) {
					temp[row.short] = this.renderSwitch(row, input.value[v].index);
				}
			}

			if(input.value[v].index !== undefined){
				collection.push(temp);
			}
		}

        return (
            <React.Fragment>
                <DataTable small
                    blockClass={"blockDataTableEdit"}
                    dataTableClass={"dataTableEdit"}
                    noEditGlobal
                    properties={properties}
                    collection={collection}
                    noDataMessage={"Pas de traduction"}
                    pending={null}
                    deleteAction={(e) => this.deleteRow(e)}
                    editAction={null}
                />
                <Button block className={"mt-5"} onClick={() => this.addRow()}>Ajouter</Button>
            </React.Fragment>
        )
    }

};

export default wrapField(DataTableEdit);
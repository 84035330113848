// react
import React from 'react';
// store
import { default as store } from './../../../index'
//gj-strap
import { modalFunction } from './../../../gj_modules/gj-strap/components/partials';
import { processSubmit } from './../../../gj_modules/gj-strap/lib';
//gj-stuva
import { generateInitialValue } from './../../../gj_modules/gj-stuva/forms'
import { PanelNew } from './../../../gj_modules/gj-stuva/components'

import { actions } from './../../../stam_modules/stam-strap/stuva/index.js';
const elementActions = actions["translation"];

export default function generateTranslationPopupUpdate(origin) {
	store.dispatch(elementActions.getById(origin.element._id)).then(
		function(response){

			let newElement = response.response.data.translation

			modalFunction({
				submit:(values) => {
					store.dispatch(elementActions.updateById(newElement._id, processSubmit(values))).then(
						function(response){
							store.dispatch(origin.getElement(origin.id))
						}
					);
				},
				initialValues:generateInitialValue(elementActions.model, newElement),
				text:<PanelNew
					width={[3, 9]}
					model={elementActions.model}
					filter={"update"}
					element={origin.element}
				/>,
				header:"test",
				state:"translation.pendingupdate"
			}, "popup")
		}
	)
}
export const thing = {
    add:{
        location:{
            name: "Localisation",
            type: "location",
            add: true,
            update: true
		},
		ffile:{
            name:"Objet",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"ffile",
            update:true,
        },
    }
}
import { getData, postData, deleteData, putData } from '../requests'

import { dataToUrl } from '../utils'

export default function generateActions(objects){
    
    let temp = {};

    for(let o of objects){
        for(let a of o.actions){
            if(a === "reset"){
                o.reset = function(){
                    return dispatch => dispatch({ type: o.name+"_reset" });
                }
            }else if(a === "all"){
                o.getAll = function(data){
                    const url = "/"+o.name+"/all"+dataToUrl(data);
                    return dispatch => getData(o.name+"_all", o.name+"_all_pending", o.name+"_error", true, url, dispatch);
                }
            }else if(a === "id"){
                o.getById = function(id){
                    const url = "/"+o.name+"/id/"+id;
                    return dispatch => getData(o.name+"_id", o.name+"_id_pending", o.name+"_error", true, url, dispatch);
                }
            }else if(a === "add"){
                o.add = function(data){
                    const url = "/"+o.name+"/add";
                    return dispatch => postData(o.name+"_add", o.name+"_add_pending", o.name+"_error", true, url, dispatch, data);
                }
            }else if(a === "update"){
                o.updateById = function(id, data){
                    const url = "/"+o.name+"/update/"+id;
                    return dispatch => putData(o.name+"_update", o.name+"_update_pending", o.name+"_error", true, url, dispatch, data);
                }
            }else if(a === "trash"){
                o.trashById = function(id){
                    const url = "/"+o.name+"/trash/"+id;
                    return dispatch => deleteData(o.name+"_trash", o.name+"_trash_pending", o.name+"_error", true, url, dispatch);
                }
            }
		}
		
		if(o.hasOwnProperty("subactions")){
			for(let property in o.subactions){
				let subname = o.subactions[property].name;
				o[subname] = {};
				for(let sa of o.subactions[property].actions){
					if(sa === "add"){
						o[subname].add = function(data){
							const url = "/"+o.name+"/"+subname+"/add";
							return dispatch => postData(o.name+"_"+subname+"_add", o.name+"_"+subname+"_add_pending", o.name+"_error", true, url, dispatch, data);
						}
					}else if(sa === "update"){
						o[subname].updateById = function(id, subid, data){
							const url = "/"+o.name+"/"+subname+"/update/"+id+"/"+subid;
							return dispatch => putData(o.name+"_"+subname+"_update", o.name+"_"+subname+"_update_pending", o.name+"_error", true, url, dispatch, data);
						}
					}else if(sa === "trash"){
						o[subname].trashById = function(id, subid){
							const url = "/"+o.name+"/"+subname+"/trash/"+id+"/"+subid;
							return dispatch => deleteData(o.name+"_"+subname+"_trash", o.name+"_"+subname+"_trash_pending", o.name+"_error", true, url, dispatch);
						}
					}
				}
			}
		}

        temp[o.name] = o;
    }

    return temp;
}
//import { default as stamstrap } from "../../../settings/stam-strap"

let fqrcode = {
    name: "fqrcode",
    actions: [
        "all", "id", "add", "update", "trash", "reset"
    ],
    model: {
        game:{
            name:"Application",
            type:"select",
            data:{
                isMulti:true
            },
            modelType:"game",
            update:true,
            add:true
        },
        short: {
            name: "Short",
            type: "text",
            placeholder: "Short",
            add: true,
            update: true
        },
		type:{
			name: "Type",
            type: "text",
            placeholder: "url",
			add: true
		},
		url:{
			name: "Url",
			type:"text",
            placeholder: "https://",
			add: true,
			update: true
		},
		urlsuffix:{
			name: "Url suffixe",
			type:"text",
            placeholder: "?a=123",
			add: true
		},
        description: {
            name: "Description",
            type: "textarea",
            placeholder: "Description",
            update: true
        },
        data: {
        }
    }
}

export default fqrcode;
//Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//stam-strap
import HeaderTop from 'stam_modules/stam-strap/components/partials/HeaderTop';
import MenuLeft from 'stam_modules/stam-strap/components/partials/MenuLeft';
//import { required } from '../../lib/validate';
//child-app
import history from '../../history';
//gj-strap
import { ComplexAll } from 'gj_modules/gj-strap/components/complex'
import { Full, modalFunction } from 'gj_modules/gj-strap/components/partials';
import { processSubmit, returnFunction } from 'gj_modules/gj-strap/lib';
import { GetTranslation } from 'stam_modules/stam-strap/components/partials';
//various
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
//Partial
import SphereditorPartial from './SphereditorPartial';
import { SphereViewer } from './SphereViewer';
//gj-stuva
import { /*generatePanelNew,*/ generateInitialValue } from 'gj_modules/gj-stuva/forms'
//import { generatePanelNew } from 'gj_modules/gj-stuva/forms'
import { PanelNew } from 'gj_modules/gj-stuva/components'
// actions
import { updateOffset } from './../../actions/mpoi'
import { actions } from 'stam_modules/stam-strap/stuva';
const elementActions = actions["mpoi"];
const reset = elementActions.reset;
const getAll = elementActions.getAll;
const add = elementActions.add;
const trashById = elementActions.trashById;
const getById = elementActions.getById;

const elementActionsMsphere = actions["msphere"];
const getAllMsphere = elementActionsMsphere.getAll;
const getByIdMsphere = elementActionsMsphere.getById;

class Sphereditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            map: null,
            markers:[],
            markerCurrent:null,
			msphere:null
        };
    }

    componentDidMount() {
		//console.log("didmount")
		const { mpoi } = this.props;
        let thing = new URLSearchParams(window.location.search).get('t');

		if (thing) {
			this.props.getById(thing);
		}

		if(mpoi && mpoi.mpois){
			//console.log(mpoi.mpois)
		}
    }

    renderTd = (e) => {
        return {
            id: e._id,
            key: "thing_" + e._id,
            short: e.short,
            name: <GetTranslation quick={e.name && e.name.translations} />
        }
    }

    customIcon = (opts) => {
        return Object.assign({
            path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
            fillColor: '#34495e',
            fillOpacity: 1,
            strokeColor: '#000',
            strokeWeight: 2,
            scale: 1,
        }, opts);
    }

    saveMarker = (id) => {
        const { markers } = this.state;
        let markersTemp = markers.slice();
        for(let m of markersTemp){
            if(m._id === id){
                m.marker.setIcon(
                    this.customIcon({
                        fillColor: "#00FF00"
                    }),
                );
            }
        }
    }

    updateMarker = (id, location) => {
        const { map, markers } = this.state;
        let markersTemp = markers.slice();
        for(let m of markersTemp){
            if(m._id === id){
                let latlng = new window.google.maps.LatLng(location.coordinates[1], location.coordinates[0]);
                m.marker.setPosition(latlng);
                map.panTo(latlng);
                m.marker.setIcon(
                    this.customIcon({
                        fillColor: "#FFFF00"
                    }),
                );
            }
        }
    }

    updateMarkers = (params) => {
		console.log("updatemarkers")
		console.log(params)
        const { mpoi } = this.props;
        const { map, markers } = this.state;

        const element = mpoi;

        let markersTemp = markers.slice();

		if(params && params.filter && params.filter.searchMpoiByMsphere){
			this.props.getByIdMsphere(params.filter.searchMpoiByMsphere).then((response) => {
				console.log(response)
				if(response && response.response && response.response.data && response.response.data.msphere){
					this.setState({
						...this.state,
						msphere:response.response.data.msphere
					})
				}
			})
		}

        if(map){
            if(element.mpois){

				console.log(element.mpois)

                let thingFromUrl = null;

                let tempUrl = new URLSearchParams(window.location.search).get('t');
		        if (tempUrl) {
                    thingFromUrl = tempUrl;
                }

                for (let i = 0; i < element.mpois.length; i++) {
                    let find = null;
                    for(let m of markersTemp){
                        if(m._id === element.mpois[i]._id){
                            find = element.mpois[i]._id
                        }
                    }
                    if(find == null){

                        let marker = new window.google.maps.Marker({
                            position: new window.google.maps.LatLng(element.mpois[i].location.coordinates[1], element.mpois[i].location.coordinates[0]),
                            map: map,
                            icon: this.customIcon({
                                fillColor: "#0000FF"
                            })
                        })

                        if(thingFromUrl === element.mpois[i]._id){
                            marker.setIcon(
                                this.customIcon({
                                    fillColor: "#00FF00"
                                }),
                            );
                        }

                        marker.addListener("click", () => {
                            const { markerCurrent, markers } = this.state;
                            if(markerCurrent === element.mpois[i]._id){
                            }else{
                                //Erase old marker
                                for(let m of markers){
                                    m.marker.setIcon(
                                        this.customIcon({
                                            fillColor: "#0000FF"
                                        }),
                                    )
                                }
                                this.setState({ markerCurrent: element.mpois[i]._id })
                                history.push('?t=' + element.mpois[i]._id);
                                this.props.getById(element.mpois[i]._id)
                                marker.setIcon(
                                    this.customIcon({
                                        fillColor: "#00FF00"
                                    }),
                                );
                                //marker.setDraggable(true)
                            }
                        });

                        markersTemp.push({
                            _id:element.mpois[i]._id,
                            marker:marker
                        });
                    }
                }

                this.setState({
                    markers:markersTemp,
                    markerCurrent:thingFromUrl
                })

            }
        }
    }

    newPopup = (lat = 0, lon = 0) => {
        const { thing, add, getById, menuGame, getAll } = this.props;
        const element = thing;

        let location = null;

        if (lat !== 0) {
            location = {
                type: "Point",
                coordinates: [
                    lon,
                    lat
                ]
            }
        }

		let _this = this;

        modalFunction({
            submit: (values) => {
                //console.log(processSubmit(values));
                if (!element.pendingAdd) {
                    add(processSubmit(values)).then(
                        function (response) {
							let id = response.response.data.thing._id;
							history.push('?t=' + id);
                            getById(id)
                            getAll().then(
								function(response){
									_this.updateMarkers()
								}
							);
                        }
                    );
                }
            },
            initialValues: generateInitialValue(elementActions.model, {
                game: [{ _id: menuGame._id, name: menuGame.name }],
                location
            }),
            text: <PanelNew
                width={[3, 9]}
                model={elementActions.model}
                filter={"add"}
            />,
            header: "Ajouter un objet",
            state: "thing.pendingAdd"
        }, "popup")
    }

    render() {
        const { mpoi, match, trashById, getAll } = this.props;

        const element = mpoi;

        const properties = [
            { short: "short", name: "Short", width: "45%", category: "classic" },
            { short: "name", name: "Nom", width: "45%", category: "classic" },
            { short: "actions", name: "Actions", width: "10%", category: "actions" }
        ];

		let cacheResultIds = [];

        const filters = [
            { type: "search", size: 25, name: "searchMpoiByShort", placeholder: "Recherche par short" },
			{
                short:"sortMsphereId",
                type: "select", size: 50, name: "searchMpoiByMsphere", placeholder: "Sphere", data: {
                    isMulti: true,
                    isAsync: true,
                    forceLabel: true,
                    cache: cacheResultIds,
                    //defaultValue: { value:null, label:"", force:true },
                    loadOptions: returnFunction(this.props.getAllMsphere, "searchMsphereByShort", 5, "msphere.mspheres", "_id", ["short"])
                }
            },
            {
                type: "button",
                size: 20,
                name: "",
                title: "Ajouter un objet",
                icon: faPlusCircle,
                fn: () => {
                    this.newPopup();
                }
            }
        ]

        const portlet = {
            title: "Pois",
            links: [
                { name: "Recherche", path: "/things" }
            ]
        }

        return (
            <React.Fragment>
                <div id="popup"></div>
                <MenuLeft menu={"timescope"} />
                <HeaderTop menu={"timescope"} />
                <Full portlet={portlet}>
                    <ComplexAll
						autoload={false}
						mode={["custom", "map", "list"]}
						page={{
							skip:0,
							limit:100
						}}
                        actions={elementActions}
                        callbackGetAll={(params) => { this.updateMarkers(params); }}
                        elements={element.mpois}
                        properties={properties}
                        filters={filters}
                        renderTd={this.renderTd}
                        pending={element.pending}
                        noDataMessage={"Pas d'objets"}
                        deleteAction={(e) => {
                            modalFunction({
                                btnFunction: () => {
                                    trashById(e).then(
                                        function (response) {
                                            getAll();
                                        }
                                    );
                                },
                                btnText: "Confirmer",
                                text: "Êtes-vous sûr ?",
                                header: "Supprimer un objet",
                                state: "mpoi.pendingTrash"
                            }, "popup")
                        }}
                        editAction={(id) => (history.push("/" + match.params.id + "/thing/" + id))}
                        split={true}
						modeCustomName={"Sphere 360"}
						custom={<React.Fragment>
							<SphereViewer
								msphere={this.state.msphere}
								mpois={mpoi.mpois}
								selectAction={(id) => {
									history.push('?t=' + id)
									this.props.getById(id)
								}}
							/>
						</React.Fragment>}
                        map={{
                            location: "location",
                            onLoad: map => {

                                this.setState({map:map});

                                //Add right clic comportement
                                window.google.maps.event.addListener(map, "rightclick", (function (event) {
									//console.log(event)
                                    var lat = event.latLng.lat();
                                    var lng = event.latLng.lng();
                                    this.newPopup(lat, lng);
                                }).bind(this))
                                //Display elements
                                this.updateMarkers();
                            },
                            mapOptions: {
                                center: {
                                    lat: 48.9800958,
                                    lng: 2.2807058
                                },
                                zoom: 18,
                                //mapTypeId: google.maps.MapTypeId.ROADMAP,
                                streetViewControl: false,
                                disableDefaultUI: true,
                                styles: [
                                    {
                                        "elementType": "labels",
                                        "stylers": [
                                            {
                                                "visibility": "off"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "administrative.land_parcel",
                                        "stylers": [
                                            {
                                                "visibility": "off"
                                            }
                                        ]
                                    },
                                    {
                                        "featureType": "administrative.neighborhood",
                                        "stylers": [
                                            {
                                                "visibility": "off"
                                            }
                                        ]
                                    }
                                ]
                            }
                        }}
                    >
                        <SphereditorPartial
                            onSave={() => console.log("save")}
                            onChange={(state) => {
                                if(state.hasOwnProperty("modified")){

									let paramsToSave = ["angle", "elevation", "offset", "offsetangle", "offsetelevation"]

									for(let ps of paramsToSave){
										if(state.modified.hasOwnProperty(ps)){
											if(state.modified[ps]){
												//console.log(this.state.markerCurrent)
												let needToUpdate = true;
	
												if(state.submitSucceeded){
													if(!state.dirtySinceLastSubmit){
														needToUpdate = false;
													}
												}
												
												if(needToUpdate){
													let id = new URLSearchParams(window.location.search).get('t');
													this.props.updateOffset(id, ps, state.values[ps])
													
													//this.updateMarker(this.state.markerCurrent, state.values.location);
												}
												
											}
										}
									}
                                }
                            }}
                        />
                    </ComplexAll>
                </Full>
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            reset,
            getAll,
            trashById,
            add,
            getById,
			getAllMsphere,
			getByIdMsphere,
			updateOffset
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        mpoi: state.mpoi,
        menuGame: state.menu.game
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Sphereditor);

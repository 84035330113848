export default function processSubmit(values) {

	/*async function readFileAsDataURL(file) {
		let result_base64 = await new Promise((resolve) => {
			let fileReader = new FileReader();
			fileReader.onload = (e) => resolve(fileReader.result);
			fileReader.readAsDataURL(file);
		});
	
		return result_base64;
	}*/

    let valuesO = Object.assign({}, values);

    Object.keys(valuesO).forEach(async function (key) {
        if(valuesO[key] !== null && valuesO[key].hasOwnProperty("force") && valuesO[key].force){
            valuesO[key] = valuesO[key].value;
        }else if(Array.isArray(valuesO[key])){
            let valuesCopy = valuesO[key].slice();
            for(let i=0; i<valuesCopy.length; i++){
                if(valuesCopy[i].hasOwnProperty("force") && valuesCopy[i].force){
                    valuesCopy[i] = valuesCopy[i].value;
                }
            }
            valuesO[key] = valuesCopy.slice();
		}

		//Format files
		/*if(valuesO[key] instanceof File){
			let temp = {
				name:valuesO[key].name,
				size:valuesO[key].size,
				type:valuesO[key].type,
				//data:await readFileAsDataURL(valuesO[key])
			}

			valuesO[key+"_t"] = temp;
		}*/
    });
    return valuesO;
}
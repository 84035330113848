import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import history from '../../../../history';
//import moment from 'moment';

import { resetCluster, getClusterAll/*, addProject, deleteProject*/ } from '../../actions/cluster'

//import { Panel } from 'gj_modules/gj-strap/components/form';
import FullCenter from './../../../../gj_modules/gj-strap/components/partials/FullCenter';

import { ComplexAll } from './../../../../gj_modules/gj-strap/components/complex'

import { modalFunction } from './../../../../gj_modules/gj-strap/components/partials';

//import { required } from '../../lib/validate';

class Clusters extends Component {

    componentDidMount(){
    }

    renderTd = (e) => {
        return {
            id: e._id,
            key: "clusters_"+e._id,
            app: e.game.name,
            name: e.name,
            short: e.short,
            type: e.type
        }
    }
    render(){

        const { cluster, menu, match /*addProject, deleteProject*/ } = this.props;

        const properties = [
            { short: "id", width: "5%", category: "id"},
            { short: "app", name: "Application", width: "20%", category: "classic"},
            { short: "name", name: "Nom", width: "25%", category: "classic"},
            { short: "short", name: "Short", width: "20%", category: "classic"},
            { short: "type", name: "Type", width: "20%", category: "classic"},
            { short: "actions", name: "Actions", width: "10%", category: "actions" }
        ];

        const filters = [
            { type:"search", size:80, name:"searchClusterByName", placeholder:"Recherche par nom" },
            { type:"hidden", name:"sortGameId", value:""+match.params.id+"" }
        ]

        const portlet = {
            title:"Clusters",
            links:[
                {name:"Recherche", path:"/clusters"}
            ]
        }
            
        return(
            <React.Fragment>
                <div id="popup"></div>
                <MenuLeft menu={menu} />
                <HeaderTop menu={menu} />
                <FullCenter portlet={portlet}>
                    <ComplexAll
                        getElementsAll={getClusterAll}
                        resetElements={resetCluster}
                        elements={cluster.clusters}
                        properties={properties}
                        filters={filters}
                        renderTd={this.renderTd}
                        pending={cluster.pending}
                        noDataMessage = {"Pas de sessions"}
                        deleteAction = {(e) => {
                            modalFunction({
                                btnFunction:() => {
                                    //deleteProject(e)
                                },
                                btnText:"Confirmer",
                                text:"Êtes-vous sûr ?",
                                header:"Supprimer un cluster",
                                state:"cluster.pendingDelete"
                            }, "popup")
                        }}
                        editAction = {(id) => ( history.push("/"+match.params.id+"/cluster/"+id) )}
                    />
                </FullCenter>
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetCluster,
            getClusterAll
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        cluster: state.cluster,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Clusters);
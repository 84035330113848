import React, { /*useState*/ } from 'react';
import { wrapField } from './Form';
import './Input.scss';

const File = props => {
    const { input, type, label, placeholder, meta, children, disabled, onChange, noForm } = props;

	//let [valuee, setValuee] = useState(input.value);

	//valuee = input.value;
	
	return (
		<React.Fragment>
			<div className="form-group">
				{label ? <label>{label}</label> : null}
				<input
					className={`form-control-2 ${meta && meta.error && meta.touched ? 'error' : ''} ${type === 'search' ? 'search' : ''}`}
					type={"file"}
					placeholder={placeholder}
					//value={valuee}
					disabled={disabled}
                    //style={{ cursor: disabled ? 'not-allowed' : 'auto' }}
                    name={input.name}
					onChange={value => {
                        onChange && onChange(value);
                        /*console.log(input.value)*/
                        //setValuee(value.target.files[0]);
                        if(!noForm){
                            input.onChange(value.target.files[0]);
                        }
					}}
				/>

				{meta && meta.error && meta.touched && <p className="form-input-error-message">{meta.error}</p>}
				{children}
			</div>
		</React.Fragment>
	);
};

export default wrapField(File);

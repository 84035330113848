import React from 'react';
import { Col } from 'react-bootstrap';
import './Tile.css';

export default ({ children, size }) => {
    if(size === "classique"){
        return (
            <Col lg="12" xl="8">
                <div className="tile"> {children} </div>
            </Col>
        );
    }else if(size === "full"){
        return (
            <Col lg="12">
                <div className="tile"> {children} </div>
            </Col>
        );
    }else if(size === "half"){
        return (
            <Col lg="6">
                <div className="tile"> {children} </div>
            </Col>
        );
    }else{
        return (
            <Col lg="6" xl="4">
                <div className="tile"> {children} </div>
            </Col>
        );
    }
}

export const nl = {
    menu:{
        applications:"toepassingen",
        users:"gebruikers",
        sessions:"sessies"
    },
    translate:{
        translations:"vertalingen",
        addTranslation:"Voeg een vertaling toe"
    }
}
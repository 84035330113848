import React from 'react';
import ReactDOM from 'react-dom'

import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux'

import { ModalSimple } from './'

import { default as store } from '../../../../index'

const modalFunction = function (data, id) {

    let finalData = {
        show: true,
        onHide: () => ReactDOM.render(null, document.getElementById(id)),
        text: "",
        close: true,
        closeText: "Annuler",
        state: data.state,
        free: false
    }

    if (data.hasOwnProperty("header")) {
        finalData.header = data.header;
    }
    if (data.hasOwnProperty("onHide")) {
        finalData.onHide = data.onHide;
    }
    if (data.hasOwnProperty("text")) {
        finalData.text = data.text;
    }
    if (data.hasOwnProperty("close")) {
        finalData.close = data.close;
    }
    if (data.hasOwnProperty("closeText")) {
        finalData.closeText = data.closeText;
    }
    if (data.hasOwnProperty("btnFunction")) {
        finalData.btnFunction = data.btnFunction;
        finalData.btnText = data.btnText;
    }
    if (data.hasOwnProperty("submit")) {
        finalData.submit = data.submit;
    }
    if (data.hasOwnProperty("initialValues")) {
        finalData.initialValues = data.initialValues;
    }
    if (data.hasOwnProperty("free")) {
        finalData.free = data.free;
    }

    ReactDOM.render(
        <BrowserRouter>
            <Provider store={store}>
                <ModalSimple modal={finalData} />
            </Provider>
        </BrowserRouter>
        ,
        document.getElementById(id)
    );
}

export { modalFunction }
// react
import React, { useRef } from 'react'
// npm
import { extend, useFrame, useThree } from 'react-three-fiber'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

extend({ OrbitControls });

export default function CameraControls(){
  
	const { camera, gl: { domElement } } = useThree();
	
	const controls = useRef();

	camera.far = 10000000
	camera.fov = 50

	useFrame((state) => {
		controls.current.update()
	});

	return (
		<orbitControls
			ref={controls}
			args={[camera, domElement]}
			enableZoom={false}
			rotateSpeed={-0.3}
			/*maxAzimuthAngle={Math.PI / 4}
			maxPolarAngle={Math.PI}
			minAzimuthAngle={-Math.PI / 4}
			minPolarAngle={0}*/
		/>
	);
};

/*const Camera = () => {
	const cameraa = useRef()
	const { camera } = useThree()

	camera.fov = 90;
	camera.aspect = window.innerWidth / window.innerHeight;
	camera.up.set(0, 0, 1)

	const [mouseDown, setMouseDown] = useState(false)

	useFrame(({ mouse }) => {
		if(mouseDown){
			console.log("down")
		}
		console.log(mouse)
	})

	return <perspectiveCamera
		onPointerDown={() => setMouseDown(true)}
	  	ref={cameraa}
	  	position={[0, 0, 0]}
	  	onUpdate={self => self.updateProjectionMatrix()}
	/>
}*/
import React from 'react';
import { wrapField } from './Form';

const SelectButtons = ({ options, input, meta }) => {
  return (
    <React.Fragment>
      <div className="d-table m-auto py-4">
        {options.map((option, index) => {

          let idEl = 'btn-solid-blue';

          if (input.value === option.value) {
            idEl += '-active';
          }

          return (
            <button
              id={idEl}
              key={index}
              type="button"
              className={`btn btn-outline signup-link ${
                input.value && option.value === input.value ? 'active' : ''
              }`}
              onClick={() => input.onChange(option.value)}
            >
              <span>{option.label}</span>
            </button>
          );
        })}
        {meta && meta.error && meta.touched && (
          <p className="form-input-error-message">{meta.error}</p>
        )}
      </div>
    </React.Fragment>
  );
};

export default wrapField(SelectButtons);

//import { logoutUser } from './auth'

export default function errorHandler(dispatch, error, type, requestData) {
    console.error('Error type: ', type);
    console.error(error);
  
    let errorMessage = error && error.response ? error.response.data : error || 'unknown error';

    // NOT AUTHENTICATED ERROR
	if (error && ((error.hasOwnProperty("status") && error.status === 401) || (error.hasOwnProperty("response") && error.response.status === 401))) {
        console.log(errorMessage);
        //TO CLEAN
        //return dispatch(logoutUser(errorMessage));
        return dispatch();
	}
    
    dispatch({
        type,
        payload: errorMessage,
        requestData // put request data in response so reducers know what is it about
    });
  
    return { error };
}
import React, { Component } from 'react';
import { Tab } from 'react-bootstrap';
import './Tile.css';

class TileTab extends Component{
    render(){

        const { children, eventKey, title } = this.props;

        return(
            <Tab eventKey={eventKey} title={title}>
                <div className="tile-body">
                    {children}
                </div>
            </Tab>
        )
    }
}

export default TileTab;
import React, { Component } from 'react'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';
import frLocale from '@fullcalendar/core/locales/fr';

class Calendar extends Component {

    render() {
        const { events, eventRender } = this.props;

        return (
            <FullCalendar
                plugins={[dayGridPlugin, listPlugin]}
                defaultView={'dayGridMonth'}
                allDaySlot={false}
                header={{
                    left: 'title',
                    center: 'prev,today,next',
                    right: null
                }}
                locales={[frLocale]}
                locale="fr"
                firstDay={1}
                events={events}
                eventRender={eventRender}
            />
        );
    }
}

export default Calendar;
import React, { useState } from 'react';
import { wrapField } from './Form';
import './Input.scss';

const Location = props => {
    const { input, label, /*type, placeholder,*/ meta, children, disabled, onChange, noForm } = props;

    const [valuee, setValuee] = useState(input.value);

    const [latitude, setLatitude] = useState(input.value && input.value.coordinates && input.value.coordinates[1]);
    const [longitude, setLongitude] = useState(input.value && input.value.coordinates && input.value.coordinates[0]);

    const subChange = (which, value) => {
        let location = {
            type:"Point",
            coordinates:[longitude, latitude]
        }

        if(which === "lat"){
            setLatitude(value)
            location.coordinates[1] = value;
        }else{
            setLongitude(value)
            location.coordinates[0] = value;
        }

        onChange && onChange(location);
        /*console.log(input.value)*/
        setValuee(location);
        if(!noForm){
            input.onChange(location);
        }
    }

	return (
		<React.Fragment>
			<div className="form-group">
				{label ? <label>{label}</label> : null}
				<input
					className={`form-control-2 ${meta && meta.error && meta.touched ? 'error' : ''} `}
					type={"text"}
					placeholder={"Latitude"}
					value={valuee && valuee.coordinates && valuee.coordinates[1]}
					disabled={disabled}
                    style={{ cursor: disabled ? 'not-allowed' : 'auto' }}
                    name={input.name}
					onChange={value => {
                        subChange("lat", value.target.value);
					}}
				/>
                <input
					className={`form-control-2 ${meta && meta.error && meta.touched ? 'error' : ''}`}
					type={"type"}
					placeholder={"Longitude"}
					value={valuee && valuee.coordinates && valuee.coordinates[0]}
					disabled={disabled}
                    style={{ cursor: disabled ? 'not-allowed' : 'auto' }}
                    name={input.name}
					onChange={value => {
                        subChange("lon", value.target.value);
					}}
				/>
				{meta && meta.error && meta.touched && <p className="form-input-error-message">{meta.error}</p>}
				{children}
			</div>
		</React.Fragment>
	);
};

export default wrapField(Location);

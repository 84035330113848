export const fr = {
    menu:{
        applications:"applications",
        users:"usuarios",
        sessions:"sessions"
    },
    translate:{
        translations:"traducciones",
        addTranslation:"Agregar traducción"
    }
}
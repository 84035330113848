// react
import React, { useRef } from 'react'
// npm
import { useLoader } from 'react-three-fiber'
//import { useFBX } from '@react-three/drei'
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { DoubleSide, TextureLoader, RepeatWrapping } from "three"

const modelLeft = require("./../models/SPHERE_LEFT_8K.fbx")
const modelRight = require("./../models/SPHERE_RIGHT_8K.fbx")

const checker = require("./../models/checker.jpg")

//const API_URL = process.env.REACT_APP_API_URL;

export default function SphereEnv(props) {
	//const fbx = useFBX(model)
	const fbxLeft = useLoader(FBXLoader, modelLeft);
	const fbxRight = useLoader(FBXLoader, modelRight);

	let urlFinalLeft = null
	let urlFinalRight = null
	let textureLeft = useLoader(TextureLoader, checker)
	let textureRight = useLoader(TextureLoader, checker)

	textureLeft.wrapS = RepeatWrapping;
	textureLeft.wrapT = RepeatWrapping;
	textureRight.wrapS = RepeatWrapping;
	textureRight.wrapT = RepeatWrapping;
	textureLeft.repeat.set(4, 4);
	textureRight.repeat.set(4, 4);

	if(props.urlLeft){
		urlFinalLeft = props.urlLeft
		textureLeft = useLoader(TextureLoader, urlFinalLeft)
	}

	if(props.urlRight){
		urlFinalRight = props.urlRight
		textureRight = useLoader(TextureLoader, urlFinalRight)
	}

	const mesh = useRef()

	/*useFrame(() => {
		mesh.current.rotation.y += 0.01
	})*/

	return (
		<group
			ref={mesh}
			{...props}
			scale={[-100000,100000,100000]}
		>
			<mesh
				//ref={mesh}
				{...fbxLeft.children[0]}>
				<meshBasicMaterial
					//color={'orange'}
					side={DoubleSide}
					attach="material"
					map={textureLeft}
				/>
			</mesh>
			<mesh
				//ref={mesh}
				scale={[-10000,10000,10000]}
				{...fbxRight.children[0]}>
				<meshBasicMaterial
					//color={'orange'}
					side={DoubleSide}
					attach="material"
					map={textureRight}
				/>
			</mesh>
		</group>
		
	)
}
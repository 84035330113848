import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment';
import { Input, Select } from '../form';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import './EditableTable.css';


import './DataTable.scss';

class EditableTable extends Component {
    
    state = {
        rules : this.props.rules,
        operatorsTemplates : {}
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.rules !== prevState.rules) {
            this.setState({rules : this.state.rules})
        }
    }

    handleFilter(e, i, elem) {
        /*const {
            rules
        } = this.state;*/

        switch (elem) {
        case "type":
            let operatorsTemplates = this.state.operatorsTemplates ? this.state.operatorsTemplates : {};
            operatorsTemplates[i] = e.operators;
            this.setState({operatorsTemplates: operatorsTemplates});
            break;
        default:
            break;
        }
    }

    newRule = () => {
        const {
            rules
        } = this.state;

        if (rules) {
            let object = {
                _id:"temp_"+moment().format("x"),
                type: "",
                operator: "",
                value: ""
            };
            rules.push(object);
            this.setState({rules: rules})
        }
    }

    delRule(id) {
        let {
            rules
        } = this.state;

        let newRules = [];

        for (let i = 0; i < rules.length; i++) {
            if(i !== id){
                newRules.push(rules[i]);
            }
        }

        newRules.map((r, i) => {
            rules.map((s, j) => {
                if (newRules[i]._id === rules[j]._id) {
                    newRules[i].type = rules[j].type;
                    newRules[i].operator = rules[j].operator;
                    newRules[i].value = rules[j].value;
                }
                return rules;
            });
            return newRules;
        });

        rules = Object.assign(newRules)

        this.setState({rules : rules});
    }

    render() {
        const {
            rulesTemplates,
            page
        } = this.props;

        const {
            rules
        } = this.state;

        let idTmp = 0;

        const properties = [ // DataTable Headers
            { short: "id", width: "7.143%", category: "id"},
            { short: "Règle", width: "19.048%", category: "classic" },
            { short: "Opérateur", width: "19.048%", category: "classic" },
            { short: "Valeur", width: "19.048%", category: "classic" },
            { short: "actions", width: "7.143%", category: "actions" }
        ];

        const dataCollection = [];

        if (rules && rules.length){
            for(let i = 0; i < rules.length; i++){
                const object = { // objects for dataTable
                    link: "#",
                    règle: (<Select
                        name={"rules["+i+"].type"}
                        value={rules[i].type}
                        options={rulesTemplates}
                        onChange={e => this.handleFilter(e, i, "type")}
                    />),
                    opérateur: (<Select
                        name={"rules["+i+"].operator"}
                        value={rules[i].operator}
                        options={this.state.operatorsTemplates ? this.state.operatorsTemplates[i] : []}
                        onChange={e => this.handleFilter(e, i, "operator")}
                    />),
                    valeur: (<Input
                        name={"rules["+i+"].value"}
                        value={rules[i].value}
                        placeholder="Valeur"
                        onChange={e => this.handleFilter(e, i, "value")}
                    />)
                };
                object.id = idTmp++;
                object.index = page.skip + i + 1;
                dataCollection.push(object);
            }
        }

        const { noDataMessage } = this.props;
        const params = {};
        const arrayParams = [];
        let idTitle, idContent, collection = null;

        const titles = properties.slice().map((property)=>{
            params[property.short] = {
                ...property
            };
            if(property.category === "classic"){
                arrayParams.push(params[property.short]);
                return(
                    <th
                        className="datatable-cell"
                        key={property.short}
                        style={{ width: property.width }}
                    >
                        <span>{property.short}</span>
                    </th>
                );
            }else{
                return null;
            }
        });

        if(params["id"]){
            idTitle =   <th className="datatable-cell" style={{ width: params["id"].width }}>
                            <span>#<i className="fas fa-arrow-up"></i></span>
                        </th>
        }

        if(!dataCollection || dataCollection.length === 0){

            const content = noDataMessage ? noDataMessage : "No data";

            collection = <React.Fragment>
                            <tr className="datatable-row">
                                <td
                                    className="my-5"
                                    style={{ textAlign: "center" }}
                                    colSpan={"3"}
                                >
                                    <div className="my-5">{content}</div>
                                </td>
                            </tr>
                        </React.Fragment>
        }else{
            collection = dataCollection.slice().map((element)=>{

                const content = arrayParams.map((param)=>{
                    return(
                        <td
                            className="datatable-cell-0"
                            style={{ width: params[param.short].width }}
                            key={param.short}
                        >
                        {element[param.short.toLowerCase()]}
                        </td>
                    );
                });

                if(params["id"]){
                    idContent = <td
                        className="datatable-cell center"
                        data-field="id"
                        style={{ width: params["id"].width }}
                    >
                    {element.index}
                    </td>
                }

                return(
                    <React.Fragment key={element.id}>
                        <tr className="datatable-row">

                            {idContent}

                            {content}
                            <td className="datatable-cell" style={{ width: params["actions"].width }}>
                                <span>
                                    <div className="dropdown">
                                        <p
                                            title="Supprimer"
                                            className="btn btn-actions"
                                            onClick={(e) => this.delRule(element.id)}
                                        >
                                            <FontAwesomeIcon icon={faTrashAlt} />
                                        </p>
                                    </div>
                                </span>
                            </td>
                        </tr>
                    </React.Fragment>
                );
            });
        }

        return (
            <React.Fragment>
                <Button onClick={() => (this.newRule())} >
                    Ajouter une règle
                </Button>

                
                <div className="datatable" id="column_rendering">
                    <table className="datatable-table">
                        <thead className="datatable-head">
                            <tr className="datatable-row">

                                {idTitle}

                                {titles}
                                <th className="datatable-cell" style={{ width: params["actions"].width }}>
                                    <span>Actions</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="datatable-body">
                            {collection}
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        )
    };
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {},
        dispatch
    );
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditableTable);

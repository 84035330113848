import { default as stamstrap } from "../../../settings/stam-strap"

let thing = {
    name: "thing",
    actions: [
        "all", "id", "add", "update", "trash", "reset"
    ],
    model: {
        game:{
            name:"Application",
            type:"select",
            data:{
                isMulti:true
            },
            modelType:"game",
            update:true,
            add:true
        },
        short: {
            name: "Short",
            type: "text",
            placeholder: "Short",
            add: true,
            update: true
        },
        name: {
            name: "Nom",
            placeholder: "Nom de l'objet",
            type: "translationtext",
            //add: true,
            update: true
        },
        description: {
            name: "Description",
            type: "textarea",
            placeholder: "Description",
            update: true
        },
        options: {
            name: "Options",
            type: "text",
            placeholder: "op#option;option",
            update: true
        },
        data: {
        }
    }
}

if(stamstrap.hasOwnProperty("models")){
    if(stamstrap.models.hasOwnProperty("thing")){
        if(stamstrap.models.thing.hasOwnProperty("add")){
            Object.assign(thing.model, stamstrap.models.thing.add);
        }
    }
}

export default thing;
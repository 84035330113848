import React from 'react';
import './Tile.css';

export default ({ children, title, border, toolbar, toolbarFull, childrenRight = true }) => (
    <div className={"tile-header " + (border ? 'tile-header-border' : '')}>
        {!childrenRight && children}
        {title ? (
            <div className="tile-header-label">
                <h3 className="tile-header-title">{title}</h3>
            </div>
        ) : null}
        {toolbar ? (
            <div className="tile-header-toolbar"> {toolbar} </div>
        ) : null}
        {toolbarFull ? (
            <div className="tile-header-toolbarFull"> {toolbarFull} </div>
        ) : null}

        {childrenRight && children}
    </div>
);
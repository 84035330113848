import { RepeatWrapping } from "three"

export default function(texture, hTiles, vTiles, durationTile) {
	// current tile number
	this.currentTile = 0;
	// duration of every tile
	this.durationTile = durationTile;
	// internal time counter
	this.currentTime = 0;
	// amount of horizontal and vertical tiles, and total count of tiles
	this.hTiles = hTiles;
	this.vTiles = vTiles;

	//this.cntTiles = this.hTiles * this.vTiles;
	texture.wrapS = texture.wrapT = RepeatWrapping;
	texture.repeat.set(1 / this.hTiles, 1 / this.vTiles);
	//texture.offset.x = 0.875; //0.875
	//texture.offset.y = 0.50;

	//console.log(texture.repeat)

	//console.log(texture.offset.x+" - "+texture.offset.y)

	this.update = function(time) {
		if(time === "plus"){
			this.currentTile++;
			if(this.currentTile > 34){
				this.currentTile = 0;
			}
		}else{
			this.currentTile = time;
		}
		
		var iColumn = this.currentTile % this.hTiles;
		texture.offset.x = iColumn / this.hTiles;
		var iRow = Math.floor(this.currentTile / this.hTiles);
		texture.offset.y = 0.875 - (iRow / this.vTiles);
	};
}
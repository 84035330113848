import React, { /*useState*/ } from 'react';
import Slider from 'nouislider-react';

import { wrapField } from './Form';
import { timeStrToMinutes, formatHour } from '../../lib/time';

const SelectHours = ({ input, meta, minTime = '06:00', maxTime = '21:00', step = 30, disabled = false, onChange, noForm = false }) => {
	const min = timeStrToMinutes(minTime);
	const max = timeStrToMinutes(maxTime);
	const options = [];
	for (let i = min; i <= max; i += step) {
		const h = formatHour(i);
		options.push({ label: h, value: h });
	}
	
	//const [valuee, setValuee] = useState(input.value);

	return (
		<React.Fragment>
			<div className="select-hours-slider">
				<Slider
					range={{ min, max }}
					step={step}
					format={{ from: v => (v ? timeStrToMinutes(v) : min), to: formatHour }}
					tooltips
					start={[input.value && input.value.start, input.value && input.value.end]}
					connect
					/*onChange={v => input.onChange({ start: v[0], end: v[1] })}*/
					onChange={value => {
                        onChange && onChange(value);
                        //setValuee({start:value[0], end:value[1]});
                        if(!noForm){
                            input.onChange({ start: value[0], end: value[1] });
                        }
					}}
					disabled={disabled}
				/>
			</div>
			{meta && meta.error && <p style={{ color: 'red' }}>{meta.error}</p>}
		</React.Fragment>
	);
};

export default wrapField(SelectHours);

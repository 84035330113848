import React from 'react';
import ReactPaginate from 'react-paginate';
import { Form, Select } from '../form';
import "./Pagination.scss"

const Pagination = function({ props, page, callFunc }) {

    // Variables declaration
    const previous = props.previousLabel ? props.previousLabel : "Précédent";
    const next = props.nextLabel ? props.nextLabel : "Suivant";
    const breakLab = props.breakLabel ? props.breakLabel : "...";

    const breakLinkClass = props.breakLinkClassName ?
    props.breakLinkClassName :
    "datatable-pager-link pager-link-number";

    const previousLinkClass = props.previousLinkClassName ?
    props.previousLinkClassName :
    "datatable-pager-link prev";

    const nextLinkClass = props.nextLinkClassName ?
    props.nextLinkClassName :
    "datatable-pager-link next";

    const containerClass = props.containerClassName ?
    props.containerClassName :
    "datatable-pager-nav";

    const subContainerClass = props.subContainerClassName ?
    props.subContainerClassName :
    "datatable-pager-link pager-link-number";

    const activeLinkClass = props.activeLinkClassName ?
    props.activeLinkClassName :
    "datatable-pager-link active";

    const pageLinkClass = props.pageLinkClassName ?
    props.pageLinkClassName :
    "datatable-pager-link pager-link-number";

    const disabledClass = props.disabledClassName ?
    props.disabledClassName :
    "datatable-pager-link disabled";

    const range = 5;
    const marginRange = 1;
    const count = Math.ceil(page.totalSize / page.limit);
    const total = (count === page.current) ? page.totalSize : page.skip + page.limit;

    const elementsByPage = [
        {value: 20, label: 20},
        {value: 50, label: 50},
        {value: 100, label: 100}
    ];

    // Events
    const handlePageClick = event => {
        page.current = event.selected + 1;
        page.skip = (page.current-1) * page.limit;
        callFunc({ skip: page.skip, limit: page.limit });
    };

    const handleChange = event => {
        page.current = 1;
        page.skip = 0;
        page.limit = event;
        callFunc({ skip: page.skip, limit: page.limit });
    };

    // Render
    return (
        <React.Fragment>

            <div className="tile-pagination">

                {/*Pagination*/}
                <ReactPaginate
                    forcePage = {page.current-1}
                    previousLabel = {previous}
                    nextLabel = {next}
                    breakLabel = {breakLab}
                    breakLinkClassName = {breakLinkClass}
                    previousLinkClassName = {previousLinkClass}
                    nextLinkClassName = {nextLinkClass}
                    containerClassName = {containerClass}
                    subContainerClassName = {subContainerClass}
                    activeLinkClassName = {activeLinkClass}
                    pageCount = {count}
                    marginPagesDisplayed = {marginRange}
                    pageRangeDisplayed = {range}
                    onPageChange = {handlePageClick}
                    pageLinkClassName = {pageLinkClass}
                    disabledClassName = {disabledClass}
                />

                <div className="datatable-pager-info">
                    {/*Change elementsByPage*/}
                    <Form initialValues={{ elementsByPage: page.limit }} className="ebp">
                        <Select
                            name="elementsByPage"
                            options={elementsByPage}
                            onChange={handleChange}
                        />
                    </Form>
                    {/*Pagination informations*/}
                    <span
                        className="datatable-pager-detail">
                        Affiche {page.skip + 1} - {total} sur {page.totalSize}
                    </span>
                </div>

            </div>

        </React.Fragment>
    );
};

export default Pagination;

import { default as store } from '../../../index';

var deep_value = function(obj, objectPath){
    for (var i=0, path=objectPath.split('.'), len=path.length; i<len; i++){
        obj = obj[path[i]];
    };
    return obj;
};

export default function returnFunction(action, search, limit, statePath, value, label, force = true, minimum = 0){

    let loadOptions = async (inputValue, callback) => {

		let results = [];

        if(inputValue.length > minimum){
            let skip = 0;
            let filter = {};

            if(Array.isArray(search)){
                filter[search[0].name] = inputValue;

                for(let i=1; i<search.length; i++){
                    filter[search[i].name] = search[i].value;
                }
            }else{
                filter[search] = inputValue;
            }

            await action({ skip, limit, filter });

            let elements = deep_value(store.getState(), statePath);
            
            for(let e of elements){

                let tempLabel = "";

                //if (label instanceof Function){
                if(typeof label === "function"){
                    tempLabel = label(e);
                }else if(Array.isArray(label)){
                    let labelFinal = [];
                    for(let l of label){
                        labelFinal.push(deep_value(e, l));
                    }
                    tempLabel = labelFinal.join(" ");
                }else{
                    tempLabel = deep_value(e, label);
                }

                let temp = {
                    value:e[value],
                    label:tempLabel
                }

                if(force){
                    temp.force = true;
				}

                results.push(temp)
            }
		}

		//This setTimeout is really uggly, but make it work when input is empty
		if(minimum < 0){
			setTimeout(function(){
				callback(results)
			}, 500)
		}

		callback(results)
    }

    return loadOptions;
}
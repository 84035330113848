/**
 * returns an error message if the field value is falsy
 * @param {string} message custom error message
 */
export const required = (message = 'Ce champ est requis') => value =>
    value ? undefined : message;

/**
 * returns an error message if the field value is not a valid email address
 * @param {string} message custom error message
 */
export const validateEmail = (message = 'Adresse email non valide') => value =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
    )
        ? undefined
        : message;

/**
 * returns an error message if the field value is different from the other field value
 * @param {string} fieldName field name to compare to value to
 * @param {string} message custom error message
 */
export const equalsField = (
    fieldName,
    message = 'Les champs ne correspondent pas'
) => (value, allValues) =>
        allValues[fieldName] !== value ? message : undefined;

/**
 * returns an error message if the field value is not a valid date (YYYY-MM-DD format)
 * @param {string} message custom error message
 */
export const validateDate = (
    message = 'Veuillez saisir une date valide'
) => value => {

    if(value && (value.hasOwnProperty("startDate") && value.hasOwnProperty("endDate"))){
        return undefined;
    }

    return /^\s*((?:19|20)\d{2})-(1[012]|0?[1-9])-(3[01]|[12][0-9]|0?[1-9])\s*$/.test(
        value
    )
        ? undefined
        : message;
}
        

/**
 * returns an error message if the value does not contains valid start and end hours
 * @param {string} message custom error message
 */
export const validateHours = (
    message = 'Veuillez sélectionner une plage horaire valide'
) => value =>
        value &&
            value.start &&
            value.start.match(/^[0-2]\d:[0-6]\d$/) &&
            value.end &&
            value.end.match(/^[0-2]\d:[0-6]\d$/) &&
            value.end > value.start
            ? undefined
            : message;

/**
 * returns an error message if the array is empty
 * @param {string} message custom error message
 */
export const nonEmpty = (
    message = 'Sélectionnez au moins un élément'
) => value => (value && value.length ? undefined : message);

/**
 * returns an error message if the value is not
 * a valid french phone number
 * @param {string} message custom message
 */
export const validPhone = (
    message = 'Numéro de téléphone non valide'
) => value =>
        /^(0|\+33|0033)[1-9]([0-9]){8}$/.test(value ? value.replace(/ /g, '') : value)
            ? undefined
            : message;

import _ from "lodash";
import { FETCH_NOTIFICATION, VIEWED_NOTIFICATION, GET_SOME_NOTIFICATION, NOTIFICATION_ERROR } from "../actions/types"

const INITIAL_STATE = { error: '', notification: null, message: '' };

export default function (state = INITIAL_STATE, action) {

    switch (action.type) {
        case FETCH_NOTIFICATION:
            return { ...state, message: action.payload.notification, error: '' };
        case VIEWED_NOTIFICATION:
            let notification = null
            if (action.payload.notification) {
                notification = _.filter(state.notification, (notification) => {
                    return notification._id !== action.payload.notification._id
                })
            } else {
                notification = state.notification
            }
            return { ...state, notification: notification, message: '', error: '' };
        case GET_SOME_NOTIFICATION:
            return { ...state, notification: action.payload.notification, error: '' };
        case NOTIFICATION_ERROR:
            return { ...state, error: action.payload };
        default:
            return state;
    }
}

//React
import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from './history';
import moment from 'moment';
import 'moment/locale/fr';
//CSS
import 'css/stylesheets/old.css';
import 'gj_modules/gj-strap/css/gj-strap.scss';
import 'css/stylesheets/base.scss';
//Stam-strap
import { stamApp } from 'stam_modules/stam-strap';
//import { Games } from 'stam_modules/stam-strap/components'
//Custom components
import Export from './components/export/Export';
import Things from './components/thing/Things';
import Sphereditor from './components/sphereditor/Sphereditor';
import { default as PublicAppStore } from './components/public/PublicAppStore';
import { default as getCustoms } from 'stam_modules/stam-strap/components/custom/customs';
import { default as getCustom } from 'stam_modules/stam-strap/components/custom/custom';

import { stamstrap } from 'settings';

moment.locale('fr');

class App extends Component {
    render() {
		let menu = "timescope"

        let routes = [
			<Route key="routepublicapp" exact path="/p/app" component={PublicAppStore} />,
			<Route key="routeexport" exact path="/:id/export" component={Export} />,
            <Route key="routemanual" exact path="/:id/things" component={Things} />,
			<Route key="routemanualsphere" exact path="/:id/sphereditor" component={Sphereditor} />
        ]

		let models = ["mbuilding", "msphere", "mlayer", "mpoi", "mcard", "mstory", "m3dmodel", "mquestion", "mversion", "maudioguid"];

		for(let m of models){
			routes.push(<Route key={"routemanual_"+m} exact path={"/:id/"+m} component={getCustoms(stamstrap.models[m], menu)} />,)
			routes.push(<Route key={"routemanuald_"+m} exact path={"/:id/"+m+"/:idelement"} component={getCustom(stamstrap.models[m], menu)} />,)
		}

        routes = routes.concat(stamApp({
            match:this.props.match,
            menu:"timescope",
            history:history,
            components:[
                "login",
                "users",
                "user",
                "notfound",
                "games",
                "game",
                "sessions",
                "session",
                "blocks",
                "block",
                "clusters",
                "cluster",
                "actions",
                "action",
                //"things",
                "thing",
                "ffile",
				"ffiles",
                "fvalues",
                "fvalue",
                "units",
                "unit",
                "translations",
                "translation",
				"fqrcodes",
				"fqrcode",
				"p_fqrcode"
            ]
        }));

        return (
            <Router history={history}>
                <Switch>
                    {routes.map(r => { return (r) })}
                </Switch>
            </Router>
        );
    }
}

export default App;

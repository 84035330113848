import React from 'react';
import { Form, Field } from 'react-final-form';

/**
 * just a wrapper for react-final-form's Form
 * so we don't have to write a render function and <form> element each time
 */
export default ({ onSubmit, initialValues, children, className, onChange }) => (
	<Form
		initialValues={initialValues}
		onSubmit={onSubmit || (() => {})}
		render={({ handleSubmit }) => (
			<form noValidate onSubmit={handleSubmit} className={className}>
				{children}
			</form>
		)}
		debug={onChange}
	/>
);

/**
 * wrapper for react-final-form's Field
 * so you can just use `<Input />` in your form instead of `<Form component={Input} />`
 * @param {*} component
 * @param {string} type set the type of this component
 */
export const wrapField = (component, type) => props => <Field type={type} {...props} component={component} />;

//Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//stam-strap
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
//import { required } from '../../lib/validate';
//child-app
import history from '../../../../history';
//gj-strap
import { ComplexAll } from './../../../../gj_modules/gj-strap/components/complex'
import { FullCenter, modalFunction } from './../../../../gj_modules/gj-strap/components/partials';
import { processSubmit } from './../../../../gj_modules/gj-strap/lib';
//import { GetTranslation } from '../partials';
//various
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
//gj-stuva
import { /*generatePanelNew,*/ generateInitialValue } from './../../../../gj_modules/gj-stuva/forms'
//import { generatePanelNew } from 'gj_modules/gj-stuva/forms'
import { PanelNew } from './../../../../gj_modules/gj-stuva/components'
import { actions } from '../../stuva';
const elementActions = actions["fqrcode"];
const getAll = elementActions.getAll;
const add = elementActions.add;
const trashById = elementActions.trashById;
const reset = elementActions.reset;

class Fqrcodes extends Component {

    componentDidMount(){
    }

    renderTd = (e) => {
		let splitLine = (line) => {
			let l = line.split("/")
			let final = ""
			let index = 0;
			for(let li of l){
				final += li;
				index++
				if(index > 3){
					final += "/\n"
					index = 0;
				}else{
					final += "/"
				}
			}
			return final
		}

        return {
            id: e._id,
            key: "fqrcode_"+e._id,
            short: e.short,
			urlfinal: splitLine(e.urlfinal),
			url: splitLine(e.url),
			urlsuffix: e.urlsuffix
        }
    }
    render(){

        const { fqrcode, menu, match, add, trashById, getAll, menuGame } = this.props;

        const element = fqrcode;

        const properties = [
            { short: "short", name: "Short", width: "10%", category: "classic"},
            { short: "urlfinal", name: "Url Final", width: "30%", category: "classic"},
			{ short: "url", name: "Url", width: "30%", category: "classic"},
			{ short: "urlsuffix", name: "Suffixe", width: "10%", category: "classic"},
            { short: "actions", name: "Actions", width: "10%", category: "actions" }
        ];

        const filters = [
            { type:"search", size:80, name:"searchFqrcodeByShort", placeholder:"Recherche par short" },
            {
                type:"button",
                size:20,
                name:"",
                title:"Ajouter un qr code",
                icon:faPlusCircle,
                fn:() => {
                    modalFunction({
                        submit:(values) => {
							let data = processSubmit(values);
                            console.log(data);
                            if(!element.pendingAdd){
                                add(data).then(
                                    function(response){
                                        getAll();
                                        //history.push("/sic/project/"+response.response.data.projects)
                                    }
                                );
                            }
                        },
                        initialValues:generateInitialValue(elementActions.model, {game:[{_id:menuGame._id, name:menuGame.name}]}),
                        text:<PanelNew
                            width={[3, 9]}
                            model={elementActions.model}
                            filter={"add"}
                        />,
                        header:"Ajouter un fichier",
                        state:"fqrcode.pendingAdd"
                    }, "popup")
                }
            }
        ]

        const portlet = {
            title:"Qr codes",
            links:[
                {name:"Recherche", path:"/fqrcode"}
            ]
        }
            
        return(
            <React.Fragment>
                <div id="popup"></div>
                <MenuLeft menu={menu} />
                <HeaderTop menu={menu} />
                <FullCenter portlet={portlet}>
                    <ComplexAll
                        actions={elementActions}
                        elements={element.fqrcodes}
                        properties={properties}
                        filters={filters}
                        renderTd={this.renderTd}
                        pending={element.pending}
                        noDataMessage = {"Pas de qr code"}
                        deleteAction = {(e) => {
                            modalFunction({
                                btnFunction:() => {
                                    trashById(e).then(
                                        function(response){
                                            getAll();
                                        }
                                    );
                                },
                                btnText:"Confirmer",
                                text:"Êtes-vous sûr ?",
                                header:"Supprimer un fichier",
                                state:"fqrcode.pendingTrash"
                            }, "popup")
                        }}
                        editAction = {(id) => ( history.push("/"+match.params.id+"/fqrcode/"+id) )}
                    />
                </FullCenter>
            </React.Fragment>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            reset,
            getAll,
            trashById,
            add
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        fqrcode: state.fqrcode,
        menuGame: state.menu.game
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Fqrcodes);

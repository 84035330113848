export const unit = {
    name:"unit",
    actions:[
        "all", "id", "add", "update", "trash", "reset"
    ],
    model:{
        game:{
            name:"Application",
            type:"select",
            data:{
                isMulti:true
            },
            modelType:"game",
            update:true,
            add:true
        },
        short:{
            name:"Short",
            type:"text",
            placeholder:"Short",
            add:true,
            update:true
        },
        name: {
            name: "Nom",
            placeholder: "Nom de la valeur",
            type: "translationtext",
            update: true
        },
        format: {
            name: "Format",
            placeholder: "Format",
            type: "translationtext",
            update: true
        },
        digits: {
            name: "Digits",
            placeholder: "0",
            type: "text",
            update: true
        }
    },
    display:{
        selectDefault:(e) => {
            return {
                label:e.name,
                value:e._id,
                force:true
            }
        }
    },
    filters:{
        default:{
            filter:"searchUnitByName",
            quantity:20,
            state:"unit.units",
            value:"_id",
            label:"short"
        }
    }
}
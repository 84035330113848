// react
import React, { useRef, useState } from 'react'
// npm
import { useFrame, useLoader, useThree } from 'react-three-fiber'
import LatLon from 'geodesy/latlon-nvector-spherical.js';
import { Vector3, TextureLoader } from 'three';
// internal
import { default as TileTextureAnimator } from './tileTextureAnimator'
// ressources
import poitexture from './../models/poi_spritesheet.png'


export default function Poi(props) {

	const { camera } = useThree();
	const [hovered, setHover] = useState(false)
	const sprite = useRef()

	let position = [10,0,10]
	let scale = [100,100,100]

	let get2dPosition = (v, camera, width, height) => {
		let p = new Vector3(v.x, v.y, v.z);
        var vector = p.project(camera);
        vector.x = (vector.x + 1) / 2 * width;
        vector.y = -(vector.y - 1) / 2 * height;
        return vector;
	}

	if(props.poi && props.msphere){
		let originLatLon = new LatLon(props.msphere.mbuilding.location.coordinates[1], props.msphere.mbuilding.location.coordinates[0], 0)
		let distance = originLatLon.distanceTo(new LatLon(props.poi.location.coordinates[1], props.poi.location.coordinates[0], 0))
		let angleTemp = -Number(props.poi.angle)+Number(37)+Number(props.poi.offsetangle);

		let degToRad = function(deg){
			return deg * (Math.PI/180.0);
		}

		position[0] = -Math.cos(degToRad(angleTemp))*distance
		position[1] = -Number(props.msphere.mbuilding.elevation)+Number(props.poi.elevation)+Number(props.poi.offsetelevation) // +_this.pois[i].alt
		position[2] = Math.sin(degToRad(angleTemp))*distance

		let distance3d = new Vector3(position[0], position[1], position[2]).distanceTo(new Vector3(0,0,0))
		let scale3d = distance3d/10;

		scale3d = 0.1

		scale = [scale3d,scale3d,scale3d]
	}

	const texture = useLoader(TextureLoader, poitexture)

	let spriteAnimation = new TileTextureAnimator(texture, 8, 8, 64);
	spriteAnimation.update(0)

	useFrame(() => {
		if(props.poi){
			let position2d = get2dPosition(sprite.current.getWorldPosition(new Vector3()), camera, 100, 100)

			if(position2d.z > 1){
				document.getElementById("poi_"+props.poi._id).style.display = "none"
			}else{
				document.getElementById("poi_"+props.poi._id).style.display = "block"
				document.getElementById("poi_"+props.poi._id).style.left = ""+position2d.x+"%"
				document.getElementById("poi_"+props.poi._id).style.top = ""+position2d.y+"%"

				document.getElementById("poit_"+props.poi._id).style.marginTop = ""+((-props.poi.offset-50)/1.7)+"px"
			}
		}
		//mesh.current.rotation.x = mesh.current.rotation.y += 0.01
	})

	return (
		<sprite
			{...props}
			position={position}
			scale={scale}
			ref={sprite}
			onClick={(event) => props.selectAction(props.poi._id)}
			onPointerOver={(event) => setHover(true)}
			onPointerOut={(event) => setHover(false)}
		>
			<spriteMaterial
				attach="material"
				color={hovered ? "red" : 0xffffff}
				map={texture}
				sizeAttenuation={false}
				//color={0xffffff}
				depthWrite={true}
				depthTest={true}
				//transparent={true}
			/>
		</sprite>
	)
}
export const action = {
    name: "action",
    actions: [
        "all", "id", "add", "update", "trash", "reset"
    ],
    model: {
        game:{
            name:"Application",
            type:"select",
            data:{
                isMulti:true
            },
            modelType:"game",
            update:true,
            add:true
        },
        short: {
            name: "Short",
            type: "text",
            placeholder: "Short",
            add: true,
            update: true
        },
        parent: {
            name: "Action parente"
        },
        name: {
            name: "Nom",
            type: "translationtext",
            placeholder: "Nom de l'action",
            data:{
                //loadOptions:returnFunction()
            },
            //add: true,
            update: true
        },
        description: {
            name: "Description",
            type: "translationtextarea",
            placeholder: "Description",
            update: true
        },
        closeMenu: {
            name: "Fermer Menu",
            type: "checkbox",
            update: true
        },
        data: {
        }
    }
}
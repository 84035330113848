// React & Redux
import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'

import moment from 'moment';

import "./CalendarTimeline.scss"

class CalendarTimeline extends Component {

    constructor(props) {
        super(props);
        this.state = {
            visibilityGroups: []
        };
    }

    //Génération du header avec les horaires
    getTimelineHours(minTime, maxTime, step) {

        let table = [];
        let offsetMinute = step/2;

        let currentTime = new Date(0, 0, 0, ...minTime.split(':'));
        const lastTime = new Date(0, 0, 0, ...maxTime.split(':'));
        let currentTimeString;

        currentTime.setMinutes(currentTime.getMinutes() + offsetMinute);


        //currentTime = new Date(0, 0, 0, ...minTime.split(':'));

        for (; currentTime.getTime() <= lastTime.getTime(); currentTime.setMinutes(currentTime.getMinutes() + Number(step))) {

            currentTimeString = moment(currentTime).format('HH:mm');

            table.push(
                <td key={moment(currentTime).format('X')} style={{width:""+(100/(maxTime-minTime))+"%"}}>
                    {currentTimeString}
                    <div className="calendarTimelineHourLine"></div>
                </td>
            );
        }
        return table
    }

    //Génération du header avec les jours,
    //daysFilter est le numéro du jour dans la semaine : de base tous les jours [1, 2, 3, 4, 5] pour ne pas avoir le weekend (dimanche = 0)
    getTimelineDays(daysFilter = [0, 1, 2, 3, 4, 5, 6]) {

        const { startDay, endDay } = this.props;

        let table = [];
        let currentDay = moment(startDay, "YYYY-MM-DD"); //"2020-02-03"
        const endTime = moment(endDay, "YYYY-MM-DD");  //"2020-02-03"

        if (currentDay._isValid && endTime._isValid) {
            for (; currentDay.format('X') <= endTime.format('X'); currentDay = currentDay.add(1, 'days')) {
                let validatedByFilter = false;
                for (let dayFilter of daysFilter) {
                    if (currentDay.format('d') === dayFilter.toString()) {
                        validatedByFilter = true;
                    }
                }

                if (validatedByFilter) {
                    table.push(
                        <td key={currentDay.format('X')}>
                            {currentDay.format('DD/MM')}
                        </td>
                    );
                }
            }
        }
        return table;
    }

    //DayFilter à mettre à une valeur entre 0 et 6  (dimanche et samedi) pour filtrer par le jour
    getTimeLineBar(timelines, dayFilter = null) {

        const { minTimePerDay, maxTimePerDay } = this.props;

        const startDay = moment(new Date(0, 0, 0, ...minTimePerDay.split(':'))).format("X");
        const endDay = moment(new Date(0, 0, 0, ...maxTimePerDay.split(':'))).format("X");

        let newtimelines = [];
        for (let timeline of timelines) {

            let dayFilterValid = false;
            if (dayFilter == null) {
                dayFilterValid = true;
            }
            else {
                if (moment(timeline.startTime).format("d") === dayFilter.toString()) {
                    dayFilterValid = true;
                }
            }

            if (dayFilterValid) {
                const startFormatHHmm = moment(timeline.startTime).format("HH:mm");
                const endFormatHHmm = moment(timeline.stopTime).format("HH:mm");

                const startDuration = moment(new Date(0, 0, 0, ...startFormatHHmm.split(':'))).format("X");
                const endDuration = moment(new Date(0, 0, 0, ...endFormatHHmm.split(':'))).format("X");

                timeline.percentStart = ((startDuration - startDay) / (endDay - startDay) * 100) + "%";
                timeline.percentEnd = ((endDuration - startDuration) / (endDay - startDay) * 100) + "%";

                newtimelines.push(timeline);
            }
        }

        return (
            <React.Fragment>
                {timelines && newtimelines.map((timeline, i) => <div
                    key={i}
                    className={"calendarTimelineBar " + timeline.classCss}
                    style={{ left: timeline.percentStart, width: timeline.percentEnd, backgroundColor: timeline.customBackgroundColor ? timeline.customBackgroundColor : null }}
                    onClick={timeline.onClick ? timeline.onClick : function () { }}>
                    {timeline.contextMenu}{timeline.content}
                </div>
                )}
            </React.Fragment>
        );
    }

    setVisibilityGroup(groupId, newHide) {
        let actualVisibilityGroup = this.state.visibilityGroups;
        actualVisibilityGroup[groupId] = newHide;
        this.setState({ visibilityGroups: actualVisibilityGroup });
    }

    getVisibilityGroup(groupId) {
        if (this.state.visibilityGroups[groupId] !== undefined) {
            return this.state.visibilityGroups[groupId];
        }
        else {
            return true;
        }
    }

    render() {

        const { minTimePerDay, maxTimePerDay, intervalHours = "60", displayMode = "day", groups, pending, titleTopLeft = "" } = this.props;

        /*let timelinesTest = [];
        timelinesTest.push({ startTime: moment("08:00-2020-02-04", "HH:mm-YYYY-MM-DD").toDate(), stopTime: moment("17:30-2020-02-04", "HH:mm-YYYY-MM-DD").toDate(), classCss: "calendarTimelineBar-striped" });
        timelinesTest.push({ startTime: moment("08:30-2020-02-04", "HH:mm-YYYY-MM-DD").toDate(), stopTime: moment("13:30-2020-02-04", "HH:mm-YYYY-MM-DD").toDate(), classCss: "calendarTimelineBar-present" });
        timelinesTest.push({ startTime: moment("13:30-2020-02-04", "HH:mm-YYYY-MM-DD").toDate(), stopTime: moment("16:30-2020-02-04", "HH:mm-YYYY-MM-DD").toDate(), classCss: "calendarTimelineBar-absence" });*/
        const cssClassCalendarTimelineContainer = pending ? 'calendarTimelineContainerPending' : 'calendarTimelineContainer';
        return (
            <React.Fragment>
                <div className={cssClassCalendarTimelineContainer}>
                    <table className="calendarTimeline">
                        <tbody>
                            <tr className={"calendarTimelineTitle"}>
                                <td key="titleg5r36d4" className={"calendarTimelineEntry"}><div className="topLeftTitle">{titleTopLeft}</div></td>
                                {displayMode === "day" && this.getTimelineHours(minTimePerDay, maxTimePerDay, intervalHours)}
                                {displayMode === "week" && this.getTimelineDays([1, 2, 3, 4, 5])}
                            </tr>

                            {pending ? (
                                <tr>
                                    <td
                                        colSpan={100}
                                        style={{position:"relative", height:"100px"}}
                                        className={"text-center"}
                                    >
                                        Chargement
                                    </td>
                                </tr>
                            ) : (

                                groups && groups.map((group, i) =>
                                <React.Fragment key={group.id}>
                                    {group.titleVisibility &&
                                        <React.Fragment>
                                            <tr className={"calendarTimelineGroup"}>
                                                <td style={{ background: group.color, borderRadius:5 }}>
                                                    <div className={"calendarTimelineGroupTitle"}>{group.label}</div>
                                                    {this.getVisibilityGroup(group.id) ?
                                                        <React.Fragment>
                                                            <div className={"calendarTimelineGroupChevron"} onClick={() => this.setVisibilityGroup(group.id, false)}>
                                                                <FontAwesomeIcon icon={faChevronUp} />
                                                            </div>
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <div className={"calendarTimelineGroupChevron"} onClick={() => this.setVisibilityGroup(group.id, true)}>
                                                                <FontAwesomeIcon icon={faChevronDown} />
                                                            </div>
                                                        </React.Fragment>
                                                    }
                                                </td>
                                                <td colSpan={"100%"} style={{ background: (group.color + Math.round(255 * Number(group.contentBackgroundOpacity)).toString(16)) }}>
                                                    {group.content}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    }
                                    {this.getVisibilityGroup(group.id) && group.entries && group.entries.length > 0 && group.entries.map((entry, j) =>
                                        <tr key={group.id + "-" + entry.id}>
                                            <td className={"calendarTimelineEntry"}>
                                                {entry.content}
                                            </td>
                                            {displayMode === "day" &&
                                                <td colSpan={"100%"}>
                                                    <div className={"calendarTimelineBarWrapper"}>
                                                        {this.getTimeLineBar(entry.dates)}
                                                    </div>
                                                </td>
                                            }
                                            {displayMode === "week" &&
                                                <React.Fragment>
                                                    <td style={{width:"20%"}}>
                                                        <div className={"calendarTimelineBarWrapper"}>
                                                            {this.getTimeLineBar(entry.dates, 1)}
                                                        </div>
                                                    </td>
                                                    <td style={{width:"20%"}}>
                                                        <div className={"calendarTimelineBarWrapper"}>
                                                            {this.getTimeLineBar(entry.dates, 2)}
                                                        </div>
                                                    </td>
                                                    <td style={{width:"20%"}}>
                                                        <div className={"calendarTimelineBarWrapper"}>
                                                            {this.getTimeLineBar(entry.dates, 3)}
                                                        </div>
                                                    </td>
                                                    <td style={{width:"20%"}}>
                                                        <div className={"calendarTimelineBarWrapper"}>
                                                            {this.getTimeLineBar(entry.dates, 4)}
                                                        </div>
                                                    </td>
                                                    <td style={{width:"20%"}}>
                                                        <div className={"calendarTimelineBarWrapper"}>
                                                            {this.getTimeLineBar(entry.dates, 5)}
                                                        </div>
                                                    </td>
                                                </React.Fragment>
                                            }
                                        </tr>
                                    )}

                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </React.Fragment >
        );
    }
}

export default CalendarTimeline;

import React from 'react';
import SubHeader from './SubHeader';
import './Full.css';


export default ({ children, title, portlet }) => {
    return(
        <div className={portlet ? "content pb-5" : "content py-5"}>
            {portlet ? <SubHeader portlet={portlet} /> : null}
            {title ? (
            <h2 id="title1">
                <span>{title}</span>
            </h2>
            ) : null}
            {children}
        </div>
    )
};
import { default as stamstrap } from "./../../../settings/stam-strap"

import { generateActions, generateReducers } from './../../../gj_modules/gj-stuva'
import { game } from './game';
import { action } from './action';
import { default as thing } from './thing';
import { fvalue } from './fvalue';
import { default as ffile } from './ffile';
import { fmenu } from './fmenu';
import { unit } from './unit';
import { translation } from './translation';
import { default as fqrcode } from './fqrcode';

let initialModels = [game, action, thing, fvalue, ffile, fmenu, unit, translation, fqrcode]

for(let m in stamstrap.models){
	if(m !== "thing"){
		initialModels.push(stamstrap.models[m])
	}
}

export const actions = generateActions(initialModels);
export const reducers = generateReducers(initialModels);

//export const game = stuva.actions["game"];
import React, { Component } from "react";
import * as THREE from "three";
import "./viewer.css";

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

import { Motor } from "./Motor"

const API_URL = process.env.REACT_APP_API_URL;

class ViewerLevel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first:true
        };
        this.motor = null;
    }

    componentWillUnmount() {
        this.motor.unMount();
    }

    componentDidMount() {
        
        this.motor = new Motor("threetest");
        this.motor.init();

        var url = API_URL + "/asset/";

        const { clusterElements, blocks } = this.props;

        //Load only relevant assets
        var manager = new THREE.LoadingManager();

        manager.onStart = function (url, itemsLoaded, itemsTotal) {
            //console.log('Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.');
        };

        let preBlocks = {};
        for (let ce of clusterElements) {
            if (preBlocks[ce.block] === undefined) {
                preBlocks[ce.block] = true;
            }
        }

        let finalBlocks = {};

        Object.keys(preBlocks).forEach(function (key) {
            let found = null;
            for (let b of blocks) {
                if (b._id === key) {
                    found = b;
                }
            }
            if (found !== null) {
                finalBlocks[key] = {
                    short: found.short,
                    type: found.type
                };
            }
        });

        Object.keys(finalBlocks).forEach(function (key) {
            let loader = new GLTFLoader(manager);
            loader.load(url + "" + finalBlocks[key].short + "/" + finalBlocks[key].short + ".gltf", function (object) {
                let root = object.scene.children[0];
                root.rotation.x = Math.PI / 2;
                finalBlocks[key].model = root;
            });
        });

        //console.log("FINALBLOCK", finalBlocks);

        this.mount.appendChild(this.motor.renderer.domElement);

        // ground
        var mesh = new THREE.Mesh(
            new THREE.PlaneBufferGeometry(8000, 8000),
            new THREE.MeshBasicMaterial({
                color: 0xffffff
            })
        );

        mesh.rotation.x = 0;
        mesh.receiveShadow = true;
        //this.motor.scene.add(mesh);

        var grid = new THREE.GridHelper(8000, 80, 0x000000, 0x000000);
        grid.rotation.x = Math.PI / 2;
        grid.material.opacity = 0.2;
        grid.material.transparent = true;
        this.motor.scene.add(grid);

        //Object control
        /*let control = new TransformControls(this.motor.camera, this.motor.renderer.domElement);
        //control.setSize(100)
        control.setMode("translate");
        control.addEventListener('dragging-changed', function (event) {
            controls.enabled = !event.value;
        });
        //control.position.copy(new THREE.Vector3(0,0,1))
        //control.attach(mesh)
        this.motor.scene.add(control);*/

        //Env
        //var urls = [
        //    url+"env/bridge2_posx.jpg", url+"env/bridge2_negx.jpg",
        //    url+"env/bridge2_posy.jpg", url+"env/bridge2_negy.jpg",
        //    url+"env/bridge2_posz.jpg", url+"env/bridge2_negz.jpg"
        //];

        //var reflectionCube = new THREE.CubeTextureLoader().load(urls);

        var self = this;

        manager.onLoad = function () {
            if (clusterElements && clusterElements.length > 0) {
                for (let ce of clusterElements) {
                    let model = finalBlocks[ce.block].model.clone();
                    model.position.set(ce.transform.translation.x, ce.transform.translation.y, ce.transform.translation.z);
                    model.name = ce._id + " " + ce.editor.name;
                    model.type = finalBlocks[ce.block].type;
                    self.motor.scene.add(model);
                }
            }
        };
    }

    render() {
        return <div className="threeViewerLevel" id="threetest" ref={ref => (this.mount = ref)} />;
    }
}

export default ViewerLevel;
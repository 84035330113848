exports.dataToUrl = function(data){

    let final = "";
    let i = 0;

    for (const key in data){

        if(key === "filter"){
            for (const key2 in data[key]){
                if(key2 !== "" && data[key][key2] !== ""){
                    if(i === 0){
                        final += "?"+key2+"="+data[key][key2]
                    }else{
                        final += "&"+key2+"="+data[key][key2]
                    }
                }
            }
            i++;
        }else{
            if(i === 0){
                final += "?"+key+"="+data[key]
            }else{
                final += "&"+key+"="+data[key]
            }
        }
                
        i++;
    }

    return final;
}
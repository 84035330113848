import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logoutUser } from '../../actions/auth';
import { navLinks } from './../../../../data/menu';

import { /*resetMenu,*/ setMenuGame, resetMenuGame, getMenuGameAll } from '../../actions/menu'

import MenuAside from './../../../../gj_modules/gj-strap/components/partials/MenuAside';

import { findRoleByValue } from '../../../../lib';

class MenuLeft extends Component {
    componentDidMount() {
        const { getMenuGameAll/*, resetGame*/ } = this.props;
        //resetGame();
        getMenuGameAll();
    }

    render() {

        const { games, gameId, setMenuGame, currentUser } = this.props;

        let localCompany = [];

        if (games && games.length) {
            for (let i = 0; i < games.length; i++) {
                if(i === 0 && gameId === null){
                    setMenuGame(games[i]._id, games[i])
                }
                localCompany.push({
                    id: games[i]._id,
                    name: games[i].name
                })
            }
        }

        return (
            <MenuAside
                navlinks={navLinks[this.props.menu]}
                rank={findRoleByValue(currentUser.rank).rank}
                companyList={localCompany}
                companyDefaultValue={gameId}
                urlPrefix={'/'+gameId}
                onSelect={(e) => {

                    let found = null;
                    for(let g of games){
                        if(g._id === e.target.value){
                            found = g;
                        }
                    }

                    setMenuGame(e.target.value, found)
                }}
            />
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setMenuGame,
            getMenuGameAll,
            resetMenuGame,
            logoutUser
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        games: state.menu.games,
        gameId: state.menu.gameId,
        currentUser: state.auth.user
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuLeft);
//Redux
import React, { Component } from 'react';
import "./AppStore.style.scss"

class AppStore extends Component {

    componentDidMount() {

		const { appstore, playstore } = this.props

		if(navigator.userAgent.toLowerCase().includes("iphone")){
			window.location.href = appstore;
		}else if(navigator.userAgent.toLowerCase().includes("android")){
			window.location.href = playstore;
		}

    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    render() {
		
		const { appstore, playstore } = this.props

        return (
            <React.Fragment>
				<div style={{
					display:"flex",
					alignItems: "center",
					justifyContent: "center",
					height:"100%",
					flexDirection:"column"
				}}>
					<div className="appstore_cover"></div>
					<a href={appstore}
						className="appstore_link appstore_link_apple"
					>.</a>
					<a href={playstore}
						className="appstore_link appstore_link_android"
					>.</a>
				</div>
				
            </React.Fragment>
        );
    }
}

export default AppStore
export default function generateReducers(objects) {

	function recompose(obj, string) {
		var parts = string.split('.');
		var newObj = obj[parts[0]];
		if (parts[1]) {
			parts.splice(0, 1);
			var newString = parts.join('.');
			return recompose(newObj, newString);
		}
		return newObj;
	}

    let temp = {};

    for (let o of objects) {

        let INITIAL_STATE = {
            error: '',
            message: null,
            pending: false,
            pendingAdd: false,
            pendingTrash: false,
            pendingDelete: false,
            pendingUpdate: false
        };

        INITIAL_STATE[o.name] = null;
		INITIAL_STATE[o.name + "s"] = null;
		
		if(o.hasOwnProperty("subactions")){
			for(let property in o.subactions){
				let subname = o.subactions[property].name;
				for(let sa of o.subactions[property].actions){
					if(sa === "add"){
						INITIAL_STATE["pending"+subname+"Add"] = false;
					}else if(sa === "trash"){
						INITIAL_STATE["pending"+subname+"Trash"] = false;
					}else if(sa === "update"){
						INITIAL_STATE["pending"+subname+"Update"] = false;
					}
				}
			}
		}

        temp[o.name] = function (state = INITIAL_STATE, action) {

			if(o.hasOwnProperty("subactions")){
				for(let property in o.subactions){
					let subname = o.subactions[property].name;
					for(let sa of o.subactions[property].actions){
						if(sa === "add"){
							if(action.type === o.name+"_"+subname+"_add_pending"){
								let returnTemp = {
									...state
								}
								returnTemp["pending"+subname+"Add"] = true;
								return returnTemp;
							}else if(action.type === o.name+"_"+subname+"_add"){
								let returnTemp = {
									...state
								}
								
								let update = recompose(returnTemp[o.name], o.subactions[property].path);

								if(update.length > 0){
									update.push(action.payload.subffile)
								}else{

								}
								
								returnTemp["pending"+subname+"Add"] = false;

								return returnTemp;
							}
						}else if(sa === "update"){
							if(action.type === o.name+"_"+subname+"_update_pending"){
								let returnTemp = {
									...state
								}
								returnTemp["pending"+subname+"Update"] = true;
								return returnTemp;
							}else if(action.type === o.name+"_"+subname+"_update"){
								let returnTemp = {
									...state
								}
								
								let update = recompose(returnTemp[o.name], o.subactions[property].path);

								if(update.length > 0){
									let index = 0;
									for(let u of update){
										if(u._id === action.payload.subid){
											update[index] = action.payload.subffile;
										}
										index++;
									}
								}
								
								returnTemp["pending"+subname+"Update"] = false;
								return returnTemp;
							}
						}else if(sa === "trash"){
							if(action.type === o.name+"_"+subname+"_trash_pending"){
								let returnTemp = {
									...state
								}
								returnTemp["pending"+subname+"Trash"] = true;
								return returnTemp;
							}else if(action.type === o.name+"_"+subname+"_trash"){
								let returnTemp = {
									...state
								}
								
								let update = recompose(returnTemp[o.name], o.subactions[property].path);

								if(update.length > 0){
									let find = null;
									let index = 0;
									for(let u of update){
										if(u._id === action.payload.subid){
											find = index;
										}
										index++;
									}
									if(find !== null){
										update.splice(find,1)
									}
								}
								
								returnTemp["pending"+subname+"Trash"] = false;
								return returnTemp;
							}
						}
					}
				}
			}

            switch (action.type) {

                // ALL

                case o.name + "_all_pending":
                    return {
                        ...state,
                        pending: true
                    }
                case o.name + "_all":
                    let subtemp = action.payload[o.name + "s"]
                    subtemp['count'] = action.payload[o.name + "sCount"];
                    let returnTemp = {
                        ...state,
                        error: '',
                        message: action.payload.message,
                        pending: false
                    }
                    returnTemp[o.name + "s"] = subtemp;
                    return returnTemp;

                // ID

                case o.name + "_id_pending":
                    return {
                        ...state,
                        [o.name]: {
                            ...state[o.name],
                            pending: true
                        }
                    }
                case o.name + "_id":
                    subtemp = action.payload[o.name]
                    subtemp.pending = false;
                    return {
                        ...state,
                        [o.name]: subtemp,
                        error: '',
                        message: action.payload.message
                    }

                // ADD

                case o.name + "_add_pending":
                    return {
                        ...state,
                        pendingAdd: true
                    }
                case o.name + "_add":
                    return {
                        ...state,
                        pendingAdd: false
                    }
                
                // UPDATE

                case o.name + "_update_pending":
                    return {
                        ...state,
                        pendingupdate: true
                    }
                case o.name + "_update":

					let temp = {
						...state,
						[o.name]:{
							...state[o.name],
							previousupdate:action.requestData,
						},
                        pendingupdate: false
					}

                    return temp
                
                // TRASH

                case o.name + "_trash_pending":
                    return {
                        ...state,
                        pendingTrash: true
                    }
                case o.name + "_trash":
                    return {
                        ...state,
                        pendingTrash: false
                    }

                // ERROR RESET
                case o.name + "_error":
                    return { ...state, error: action.payload.message };
                case o.name + "_reset":
                    let stateReset = INITIAL_STATE;
                    stateReset[o.name] = null;
                    stateReset[o.name + "s"] = null;
                    return stateReset;
                default:
                    return state;
            }
        }
    }

    return temp;
}


import { roles } from '../data/constants';

export default function(value) {
    for (let r of Object.entries(roles)) {
        if (r[1].value === value) {
            return r[1];
        }
    }
    return {
        rank: 100
    };
}
import React from 'react';

import 'rc-color-picker/assets/index.css';
import ColorPicker from 'rc-color-picker';

import { wrapField } from './Form';


const ColorPick = props => {
    const { input, label, meta, children, onChange } = props;

    return (
        <React.Fragment>
            <div className="form-group">
                {label ? <label>{label}</label> : null}

                <ColorPicker
                    color={typeof input.value === "string" ? input.value : input.value.color}
                    onChange={value => {
                        onChange && onChange(value);
                        input.onChange(value && (value.value || value));
                    }}
                    placement="topLeft"
                />
                {meta && meta.error && meta.touched && <p className="form-input-error-message">{meta.error}</p>}
                {children}
            </div>
        </React.Fragment>
    );
};

export default wrapField(ColorPick);

import React, { Component } from "react";
import * as THREE from "three";
import "./viewer.css";

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
//import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';

//const API_URL = process.env.REACT_APP_API_URL;

class Viewer extends Component {

    componentDidMount() {

        const { asset } = this.props;

        var viewerWindows = document.getElementById("threetest");

		

        //Scene
        var scene = new THREE.Scene();
        scene.background = new THREE.Color(0xffffff);
        //scene.fog = new THREE.Fog(0xffffff, 100, 3000);

        //Camera
        var camera = new THREE.PerspectiveCamera(75, viewerWindows.offsetWidth/viewerWindows.offsetHeight, 1, 200000);
        camera.up.set(0,0,1);
        camera.position.set(150, 150, 150);

		

        //Control
        let controls = new OrbitControls( camera, viewerWindows);
        controls.target.set(0, 0, 75);
        controls.update();

        //Lighting
        //var ambient = new THREE.AmbientLight( 0xffffff, 10 );
        //scene.add( ambient );
        var hemiLight = new THREE.HemisphereLight(0xffffff, 0x222222, 2);
        scene.add(hemiLight);

        //Renderer
        var renderer = new THREE.WebGLRenderer({
            antialias: true
        });
        renderer.shadowMap.enabled = true
        renderer.shadowMap.type = THREE.PCFSoftShadowMap
        renderer.setPixelRatio( window.devicePixelRatio);
        renderer.setSize(viewerWindows.offsetWidth, viewerWindows.offsetHeight);
        renderer.gammaInput = true;
        renderer.gammaOutput = true;

        this.mount.appendChild(renderer.domElement);
        
        // ground
        var mesh = new THREE.Mesh(
            new THREE.PlaneBufferGeometry( 8000, 8000 ),
            new THREE.MeshBasicMaterial({
                color: 0xffffff
            })
        );

        mesh.rotation.x = 0;
        mesh.receiveShadow = true;
        scene.add(mesh);

        var grid = new THREE.GridHelper( 8000, 80, 0x000000, 0x000000 );
        grid.rotation.x = Math.PI / 2;
        grid.material.opacity = 0.2;
        grid.material.transparent = true;
        scene.add(grid);

        //var url = API_URL+"/asset/";

        //Env
        
        //var urls = [
        //    url+"env/bridge2_posx.jpg", url+"env/bridge2_negx.jpg",
        //    url+"env/bridge2_posy.jpg", url+"env/bridge2_negy.jpg",
        //    url+"env/bridge2_posz.jpg", url+"env/bridge2_negz.jpg"
        //];

        //var reflectionCube = new THREE.CubeTextureLoader().load(urls);
        
		var materialRobot = new THREE.MeshStandardMaterial({
			color: 0x2222FF, 
			depthWrite: true,
			depthTest: true,
			metalness: 0.8,
			//transparent:true,
			//opacity:0.5
		});


        //Asset
        var loader = new FBXLoader();
        loader.load(asset, function(object){
			console.log(object)

			object.traverse(function(child){
				if(child.isMesh){
					child.castShadow = true;
					child.receiveShadow = true;
					child.material = materialRobot;
					child.geometry.computeBoundingBox();

					/*console.log(child.geometry.boundingBox)

					child.position.set(
						(child.geometry.boundingBox.max.x-child.geometry.boundingBox.min.x)/2,
						(child.geometry.boundingBox.max.y-child.geometry.boundingBox.min.y)/2,
						(child.geometry.boundingBox.max.z-child.geometry.boundingBox.min.z)/2
					)*/
					//child.scale.set(100,100,100)
				}
			});

			//object.scale.set(100,100,100)

			object.rotation.x = Math.PI / 2;

			scene.add(object)

			/*object.children[0].material = materialRobot

			

			console.log(object.children[0])*/

            /*let root = object.scene;
            root.rotation.x = Math.PI / 2;
            scene.add(root);*/
            animate();
        });

        window.addEventListener('resize', onWindowResize, false);

        var animate = function () {
            requestAnimationFrame(animate);

            //cube.rotation.x += 0.01;
            //cube.rotation.y += 0.01;            

            renderer.render(scene, camera);
        };

        //animate();

        function onWindowResize() {
            camera.aspect = viewerWindows.offsetWidth / viewerWindows.offsetHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(viewerWindows.offsetWidth, viewerWindows.offsetHeight);
        }
    }

    render() {
        return <div className="threeViewer" id="threetest" ref={ref => (this.mount = ref)} />;
    }
}

export default Viewer;
import React, { Component } from 'react';
import { Form, Select } from '../form';

/*const langagesExample = [
    {
        value: "fr",
        label: "Français"
        //label: <div><span style={{width:"10px", height:"10px", backgroundColor:"red", display:"inline-block"}}></span>Truc</div>
    },
    {
        value: "en",
        label: "English"
    }
]*/

class TranslationSelect extends Component {

    onChangeLangage = e => {
        const { updateLangage } = this.props;
        if(updateLangage !== undefined){
            updateLangage(e);
        }
    }

    render() {

        const { langages, currentLangage, updateLangage } = this.props;

        let finalCurrentLangage = langages[0].value;

        if(currentLangage !== undefined && currentLangage !== null){
            finalCurrentLangage = currentLangage;
        }

        if(currentLangage === ""){
            if(updateLangage !== undefined){
                updateLangage(langages[0].value);
            }
        }

        return (
            <div style={{ display: "inline-block", width: "200px" }} >
                <Form
                    initialValues={{
                        translation:finalCurrentLangage
                    }}
                >
                    <Select
                        name={"translation"}
                        isClearable={false}
                        options={langages}
                        onChange={(e) => this.onChangeLangage(e)}
                    />
                </Form>
            </div>
        );
    }
}

export default TranslationSelect;
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { NavLink, Link } from 'react-router-dom';
import './MenuAside.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons'

import { Button, Collapse } from 'react-bootstrap'

export const REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;

class MenuAside extends Component {

    constructor(props) {
		super(props);

        let open = {}

        for(let link of this.props.navlinks){
            if(link.type === "category"){
                let found = false;
                if(link.links.length > 0){
                    for(let sublink of link.links){
                        if(this.isCurrentPage(sublink.path)){
							//console.log("found")
                            found = true;
                        }
                    }
                }
                if(found){
                    open[link.id] = true;
                }else{
                    open[link.id] = false;
                }

            }
        }

        this.state = { open };
    }

    isCurrentPage(path){
		let currentLocation = this.props.location ? this.props.location.pathname : null;

		if(this.props.urlPrefix && currentLocation !== null){
			let removeUrlPrefix = currentLocation.split(this.props.urlPrefix);

			if(removeUrlPrefix.length > 1){
				currentLocation = removeUrlPrefix[1];
			}
		}

        if(currentLocation === path){
            return true;
        }else{
            return false;
        }
    }

    toggleCategory(id){

		const { stayOpen } = this.props;

        let open = {}
		Object.assign(open, this.state.open);
		for(let o in open){
			if(o === id){
				open[id] = !open[id];
			}else{
				if(stayOpen){

				}else{
					open[o] = false;
				}
			}
		}
        this.setState({
            open
        });
    }

    renderSubLinks(links) {
        return links.map((link, i) => {

            let icon = faAngleDoubleRight;
            if (link.icon) {
                icon = link.icon;
            }

            let finalTo = link.path;
            if (this.props.urlPrefix && !link.noprefix) {
                finalTo = this.props.urlPrefix + "" + link.path;
            }

            return (
                <NavLink
                    key={i + "_" + finalTo}
                    className="menu-link menu-sublink"
                    activeClassName="active"
                    onClick={this.props.toggleMenu}
                    exact={finalTo === '/' ? true : false}
                    to={finalTo}
                >
                    <FontAwesomeIcon icon={icon} />
                    <span>{link.name}</span>
                </NavLink>
            )
        })
    }

    renderNavLinks() {
        const { rank } = this.props;
		let navLinks = this.props.navlinks;

        return navLinks
            .filter(link => link.desktop)
            .filter(link => (link.rank ? (rank <= link.rank) : true))
            .map((link, i) => {

                if (link.type === "spacebar") {
                    return (
                        <li key={i+"asidemenu"} className="menu-link-container">
                            ------------------
                        </li>
                    )
                } else if (link.type === "category") {

                    let icon = faAngleDoubleRight;
                    if (link.icon) {
                        icon = link.icon;
                    }

                    let open = this.state.open[link.id];

                    return (
                        <li key={i+"asidemenu"} className={open ? "menu-link-container menu-sublinks" : "menu-link-container" }>
                            <Button className="menu-link" onClick={() => this.toggleCategory(link.id)}>
                                <FontAwesomeIcon icon={icon} />
                                <span>{link.name}</span>
                                <FontAwesomeIcon icon={faCaretLeft} className={open ? "menu-category-arrow menu-category-arrow-active" : "menu-category-arrow"} />
                            </Button>

                            <Collapse in={open}>
                                <div className="menu-sublinks">
                                    {this.renderSubLinks(link.links)}
                                </div>
                            </Collapse>
                        </li >
                    )

                } else {
                    let icon = faAngleDoubleRight;
                    if (link.icon) {
                        icon = link.icon;
                    }

                    let finalTo = link.path;
                    if (this.props.urlPrefix && !link.noprefix) {
                        finalTo = this.props.urlPrefix + "" + link.path;
                    }

                    return (
                        <li key={i+"asidemenu"} className="menu-link-container">
                            <NavLink
                                className="menu-link"
                                activeClassName="active"
                                onClick={this.props.toggleMenu}
                                exact={finalTo === '/' ? true : false}
                                to={finalTo}
                            >
                                <FontAwesomeIcon icon={icon} />
                                <span>{link.name}</span>
                            </NavLink>
                        </li>
                    )
                }
            });
    }

    renderCompanyList() {

        let companyList = this.props.companyList;

        return companyList
            .map((company, i) => {
                return (
                    <option
                        key={i+"asidelist"}
                        value={company.id}
                    >
                        {company.name}
                    </option>
                )
            });
    }

    render() {
        const { companyList, logo } = this.props
		let arrow = companyList && companyList.length > 1 ? '' : 'active'
		
        return (
            <aside className="menu">
                <div className="menuFixed">
                    <nav className="nav">
                        {companyList && companyList.length ?
                            <select
                                className={"aside-menu-select " + arrow}
                                disabled={companyList.length < 2 && true}
                                onChange={this.props.onSelect}
                                defaultValue={this.props.companyDefaultValue}
                            >
                                {this.renderCompanyList()}
                            </select> : null
                        }

						{/* LOGO */}
						{logo ? (
							<Link className="navbar-brand" to={this.props.headerLink || '/home'}>
								<img id="logo" className="logoAside" src={"" + (REACT_APP_PUBLIC_URL ? REACT_APP_PUBLIC_URL : "") + "/images/logo_sidebar.png"} alt="logo" />
								<span className="brand-name" style={{fontWeight:'bold'}}>{this.props.headerTitle || 'GJGEARS'}</span>
							</Link>
						) : null}

                        <ul className="menu-list">
                            {this.renderNavLinks()}
                        </ul>
                    </nav>
                    {this.props.logoutUser && <button onClick={() => this.props.logoutUser()}>Se déconnecter</button>}
                </div>
            </aside>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuAside);

import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router";
//import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'

class Avatar extends Component {
    render(){
        const { text, icon/*, image*/, classDiv = null } = this.props;
        let iconFinal = faUser;

        if(icon){
            iconFinal = icon;
        }

        return(
            <div
                className={"avatar mr-3" + (classDiv !== null ? (" " + classDiv) : "")}
                style={{
                    /*backgroundImage: image && `url('${PUBLIC_URL}/${image}')`*/
                }}
            >
                {text ? (
                    <span>{text}</span>
                ) : (
                    <FontAwesomeIcon icon={iconFinal} />
                )}
            </div>
        )
    }
    
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Avatar));
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
import {FullCenter} from './../../../../gj_modules/gj-strap/components/partials/';
import {TileContainer, Tile, TileHeader, TileBody} from './../../../../gj_modules/gj-strap/components/partials/';

//import moment from 'moment';

import { Form, Input } from './../../../../gj_modules/gj-strap/components/form';
//import DataTable from 'gj_modules/gj-strap/components/form/DataTable';
import { Media, Row, Col, Button } from 'react-bootstrap';

import { resetBlock, getBlockById } from '../../actions/block';

import { Avatar } from './../../../../gj_modules/gj-strap/components/display/';

import Viewer from '../three/Viewer';

class Block extends Component {
    componentDidMount() {
        const { match, getBlockById, resetBlock } = this.props;
        const idBlock = match && match.params && match.params.idblock;

        resetBlock();

        if (idBlock) {
            getBlockById(idBlock);
        }
    }

    render() {
        const { match, block, menu, error, message } = this.props;
        const id = match && match.params && match.params.id;
        const idBlock = match && match.params && match.params.idblock;

        const sizeInfo = 3, sizeInfo2 = 9;


        let portlet = {
            title:"Blocks",
            links:[
                /*{name:"Sessions", path:"/"+id+"/sessions"},*/
                {name:"block Immergency", path:"/"+id+"/block/"+idBlock}
            ]
        }

        /*if(session){
            portlet.links[1].name = "";
        }*/

        return (
            <React.Fragment>
                <MenuLeft menu={menu} />
                <HeaderTop menu={menu} />
                

                {block ? (

                    <FullCenter portlet={portlet}>

                        <Form
                            initialValues={{
                                name: (block.name) || '',
                                short: (block.short) || ''
                            }}
                        >
                            <TileContainer>
                                <Tile size={"full"}>
                                    <TileBody>
                                        <Row>
                                            <Col sm="6">
                                                <Media>
                                                    <Avatar />
                                                    <Media.Body>
                                                        <h3>{block.name}</h3>
                                                        <p className="my-0">{block.game.name}</p>
                                                    </Media.Body>
                                                </Media>
                                            </Col>
                                            <Col sm="6" className="text-right">
                                                {block.type}<br />
                                            </Col>
                                        </Row>
                                    </TileBody>
                                </Tile>
                            </TileContainer>

                            <Row>
                                <Col lg="8" className="tile-col">
                                    {/* Options */}
                                    <TileContainer>
                                        <Tile size={'full'}>
                                            <TileHeader title="Preview" border={true} />
                                            <TileBody>
                                                    <Viewer asset={block.short} />
                                            </TileBody>
                                        </Tile>
                                    </TileContainer>

                                </Col>

                                {/* Options */}
                                <Col lg="4" className="tile-col">
                                    {
                                        <TileContainer>
                                            <Tile size={'full'}>
                                                <TileHeader title="Options" border={true} />
                                                <TileBody>
                                                    <Row>
                                                        <Col sm={sizeInfo} className="my-auto text-right">
                                                            Nom
                                                        </Col>
                                                        <Col sm={sizeInfo2}>
                                                            <Input name="name" type="text" />
                                                        </Col>
                                                    </Row>

                                                    <Row>
                                                        <Col sm={sizeInfo} className="my-auto text-right">
                                                            Short
                                                        </Col>
                                                        <Col sm={sizeInfo2}>
                                                            <Input name="short" type="text" />
                                                        </Col>
                                                    </Row>

                                                </TileBody>
                                            </Tile>
                                        </TileContainer>
                                    }

                                    {/* Save */}
                                    <TileContainer>
                                        <Tile size={'full'}>
                                            <TileBody>
                                                <Button type="submit" className="float-sm-right" variant="success">
                                                    Sauvegarder
                                                </Button>
                                                {error && <span style={{marginTop: '20px', color: 'red'}}>
                                                    <strong>Erreur!</strong> {error}
                                                </span>}
                                                {message && <span style={{marginTop: '20px', color: 'green'}}>
                                                    {message}
                                                </span>}
                                            </TileBody>
                                        </Tile>
                                    </TileContainer>
                                    
                                </Col>
                            </Row>

                        </Form>

                    </FullCenter>
                ) : null }
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            resetBlock,
            getBlockById
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        block:state.block.block,
        error: state.block.error,
		message: state.block.message
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Block);
//css
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

import './Slider.scss'

import React from 'react';
import Slider from 'rc-slider';
import Tooltip from 'rc-tooltip';

import { wrapField } from './Form';
import { timeStrToMinutes, formatHour } from '../../lib/time';

const SelectHour = ({ input, meta, minTime = '06:00', maxTime = '21:00', step = 30, disabled = false }) => {
    const min = timeStrToMinutes(minTime);
    const max = timeStrToMinutes(maxTime);
    const options = [];
    for (let i = min; i <= max; i += step) {
        const h = formatHour(i);
        options.push({ label: h, value: h });
    }

    const Handle = Slider.Handle;

    const handle = (props) => {
        const { value, dragging, index, ...restProps } = props;
        return (
            <Tooltip
                prefixCls="rc-slider-tooltip"
                overlay={formatHour(value)}
                visible={true}
                placement="top"
                key={index}
            >
                <Handle value={value} {...restProps} />
            </Tooltip>
        );
    };

    return (
        <React.Fragment>
            <div className="select-hours-slider">
                <Slider
                    min={min}
                    max={max}
                    defaultValue={input.value ? timeStrToMinutes(input.value) : (1440/2)}
                    handle={handle}
                    onChange={v => {input.onChange(formatHour(v))}}
                    step={step}
                />
            </div>
            {meta && meta.error && <p style={{ color: 'red' }}>{meta.error}</p>}
        </React.Fragment>
    );
};

export default wrapField(SelectHour);

//Redux
import React, { Component } from 'react';
//various
import { getManualData } from './../../actions';

class FqrcodeRedirector extends Component {
    componentDidMount() {
        const { match } = this.props;
        const id = match && match.params && match.params.idfqrcode;
        if (id) {
			getManualData(false, "/fqrcode/id/"+id+"?s=1").then(function(r){
				let qrcode = r.response.data.fqrcode
				if(qrcode.type === "url"){
					window.location.href = qrcode.url; 
				}
			})
            //getById(id);
        }
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    render() {

        return (
            <React.Fragment>
            </React.Fragment>
        );
    }
}

export default FqrcodeRedirector;
// react
import React from 'react';
// store
import { default as store } from './../../../index'
//gj-strap
import { modalFunction } from './../../../gj_modules/gj-strap/components/partials';
import { processSubmit } from './../../../gj_modules/gj-strap/lib';
//gj-stuva
import { generateInitialValue } from './../../../gj_modules/gj-stuva/forms'
import { PanelNew } from './../../../gj_modules/gj-stuva/components'

import { actions } from './../../../stam_modules/stam-strap/stuva/index.js';
const elementActions = actions["translation"];

export default function generateTranslationPopupNew(origin, defaultVars) {
	modalFunction({
		submit:(values) => {
			store.dispatch(elementActions.add(processSubmit(values))).then(
				function(response){
					let translationNew = response.response.data.translation;
					
					let newValues = {
						...origin.element
					}

					newValues[origin.short] = translationNew;

					store.dispatch(origin.updateElement(origin.element._id, processSubmit(newValues))).then(
						function(response){
							store.dispatch(origin.getElement(origin.element._id))
						}
					)
				}
			)
		},
		initialValues:generateInitialValue(elementActions.model, defaultVars),
		text:<PanelNew
			width={[3, 9]}
			model={elementActions.model}
			filter={"add"}
			//element={origin.element}
		/>,
		header:"test",
		state:"translation.pendingAdd"
	}, "popup")
}
export const en = {
    menu:{
        applications:"applications",
        users:"users",
        sessions:"sessions"
    },
    translate:{
        translations:"translations",
        addTranslation:"Add a translation"
    }
}
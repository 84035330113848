export const fmenu = {
    name: "fmenu",
    actions: [
        "all", "id", "add", "update", "trash", "reset"
    ],
    model: {
        game:{
            name:"Application",
            type:"select",
            data:{
                isMulti:true
            },
            modelType:"game",
            update:true,
            add:true
        },
        short: {
            name: "Short",
            type: "text",
            placeholder: "Short",
            add: true,
            update: true
        },
        name: {
            name: "Nom",
            placeholder: "Nom du menu",
            type: "translationtext",
            //add: true,
            update: true
        },
        description: {
            name: "Description",
            type: "textarea",
            placeholder: "Description",
            update: true
        },
        submenus:{
            name:"Sous-menus",
            type:"select",
            data:{
                isMulti:true
            },
            modelType:"fmenu",
            update:true
        },
        actionPressed:{
            name:"Action",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"action",
            update:true
        },
        actionOption:{
            name:"Action options",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"action",
            update:true
        },
        options: {
            name: "Options",
            type: "text",
            placeholder: "op#option;option",
            update: true
        },
        ifView: {
            name: "ifView",
            type: "text",
            placeholder: "",
            update: true
        },
    }
}
//Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//general
import { default as store} from './../../../index'
//gj-stuva
import { default as gjstuva } from './../../../settings/gj-stuva'
//gj-strap
import { Panel } from './../../../gj_modules/gj-strap/components/form';
import { returnFunction } from './../../../gj_modules/gj-strap/lib';

const getAll = gjstuva.translation.getAll

class PanelNew extends Component {

    findTrad = (short) => {
        const { langage, element } = this.props

        //let labelFinal = null;

        if(element.hasOwnProperty(short)){

            if(element[short] && element[short].hasOwnProperty("translations") && element[short].translations.length > 0){
                for(let t of element[short].translations){
                    if(t.langage === langage.currentLangage){
                        return {
                            value:element[short]._id,
                            label:t.value,
                            force:true
                        }
                    }
                }

                return {
                    value:element[short]._id,
                    label:element[short].translations[0].value+" ("+element[short].translations[0].langage+")",
                    force:true
                }
            }
            
        }
    }

    render() {

        const { width, model, filter, getAll, element, langage, getElement, updateElement } = this.props;

        let inputs = [];

        for (let [short, value] of Object.entries(model)) {
            if (value[filter]) {
				//console.log(short+" - "+value.type)
                let input = {
                    short: short,
                    name: value.name,
                    type: value.type
                };

                if (value.hasOwnProperty("placeholder")) {
                    input.placeholder = value.placeholder;
                }

                if (value.hasOwnProperty("data")) {
                    input.data = value.data;
                }

                if (value.type === "translationtext") {
                    if (!value.hasOwnProperty("data")) {
                        input.data = {};
                    }
					input.data.id = null
                    input.data.defaultValue = this.findTrad(short)
                    input.data.loadOptions = returnFunction(getAll, "searchTranslationByShort", 20, "translation.translations", "_id",
                        (e) => {
                            let temp = "";
                            
                            if(e.translations && e.translations.length > 0){
                                for(let t of e.translations){
                                    if(t.langage === langage.currentLangage){
                                        temp = t.value;
                                    }
                                }
                            }

                            return e.short+" - "+temp;
                        }
                    );
					
					if(element.hasOwnProperty(short) && element[short] !== null){
						input.data.id = element[short]._id
						input.data.origin = {
							element:element[short],
							getElement:getElement,
							id:element._id
						}
					}else{
						input.data.origin = {
							short:short,
							element:element,
							getElement:getElement,
							updateElement:updateElement
						}
					}
                }

                if(value.hasOwnProperty("modelType")){

                    if (!value.hasOwnProperty("data")) {
                        input.data = {};
                    }

                    if(value.data.hasOwnProperty("isMulti")){
                    
                        let cacheUserIds = [];

                        value.data.forceLabel = true;
                        value.data.cache = cacheUserIds;

                        if(value.data.isMulti === false){
                            if(element && element[short]){
                                input.data.defaultValue = {
                                    value:element[short]._id,
                                    label:element[short].short,
                                    force:true
                                }
								input.data.shortcut = {
									type:value.modelType,
									id:element[short]._id
								}
                            }else{
                                input.data.defaultValue = null;
                            }
                        }
                    }

                    let exec = function(params){
                        store.dispatch(gjstuva.actions[value.modelType].getAll(params))
                    }

                    let search = gjstuva.actions[value.modelType].filters.default;

                    input.data.loadOptions = returnFunction(
                        exec,
                        search.filter,
                        search.quantity,
                        search.state,
                        search.value,
                        search.label
                    );
                }

                inputs.push(input)
            }
        }

        return (
            <Panel
                width={width}
                inputs={inputs}
            />
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getAll
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        langage: state.langage,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PanelNew);
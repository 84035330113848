import axios from 'axios';
import cookies from 'universal-cookie';
import history from '../../../history';

import { API_URL, errorHandler } from './index';
import {
    AUTH_USER,
    AUTH_ERROR,
    AUTH_CLEAR,
    UNAUTH_USER,
    EXISTING_USER,
    ADD_USER,
    FORGOT_PASSWORD_REQUEST,
    RESET_PASSWORD_REQUEST
} from './types';

const cookie = new cookies();

export function clearLoginError() {
    return {
        type: AUTH_ERROR,
        payload: null
    };
}

export function clearAuth() {
    return {
        type: AUTH_CLEAR,
        payload: null
    };
}

export function loginUser({ email, password }) {
    return function (dispatch) {
        // TODO: dispatch an intermediate action so we can display a loader/spinner or something while the api processes the request
        axios
            .post(`${API_URL}/auth/login`, { email, password })
            .then(response => {
                // cookie.set('token', response.data.token);
                localStorage.setItem('token', response.data.token);

                const dataUser = response.data.user;
                cookie.set('user', dataUser);

                dispatch({ type: AUTH_USER, user: dataUser });
                history.push('/games');
            })
            .catch(error => {
                errorHandler(
                    dispatch,
                    error && error.response && error.response.status === 401
                        ? 'Veuillez vérifier vos identifiants'
                        : 'Une erreur est survenue',
                    AUTH_ERROR
                );
            });
    };
}

export function logoutUser(error) {
    return function (dispatch) {
        dispatch({ type: UNAUTH_USER, payload: error || '' });
        // cookie.remove('token', { path: '/' });
        cookie.remove('user', { path: '/' });
        localStorage.removeItem('token');
        history.push('/');
    };
}

export function registerUser(data) {
    return function (dispatch) {
        axios
            .post(
                `${API_URL}/auth/register`,
                data
                // { headers: { Authorization: cookie.load('token') } }
            )
            .then(response => {
                if (response.data.error) {
                    dispatch({ type: EXISTING_USER, payload: response.data.error });
                } else {
                    if (response.data.message === 'user created') {
                        history.push('/register-confirmation');
                    }
                    dispatch({ type: ADD_USER, payload: response.data.message });
                }
            })
            .catch(error => {
                console.log('AUTH_ERROR');
                errorHandler(dispatch, error, AUTH_ERROR);
            });
    };
}

export function getForgotPasswordToken({ email }) {
    return function (dispatch) {
        axios.post(`${API_URL}/auth/forgot-password`, { email })
            .then((response) => {
                dispatch({
                    type: FORGOT_PASSWORD_REQUEST,
                    payload: response.data,
                });
            })
            .catch((error) => {
                errorHandler(dispatch,
                    error && error.response && error.response.status === 422
                        ? 'Veuillez vérifier votre email. Réessayez.'
                        : 'Une erreur est survenue',
                    AUTH_ERROR);
            });
    };
}

export function resetPassword(token, password) {
    return function (dispatch) {
        axios.post(`${API_URL}/auth/reset-password/${token}`, password)
            .then((response) => {
                dispatch({
                    type: RESET_PASSWORD_REQUEST,
                    payload: response.data,
                });
                // Redirect to login page on successful password reset
                // history.push('/login');
            })
            .catch((error) => {
                errorHandler(dispatch,
                    error && error.response && error.response.status === 422
                        ? 'Votre token a expiré. Recommencez la procédure.'
                        : 'Une erreur est survenue',
                    AUTH_ERROR);
            });
    };
}

import {
    CLUSTER_ALL,
    CLUSTER_ID,
    CLUSTER_ELEMENTS,
    CLUSTER_RESET,
    CLUSTER_ERROR
} from '../actions/types';

const INITIAL_STATE = {
    error:'',
    clusters:null,
    cluster:null,
    clusterElements:null,
    message:null
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case CLUSTER_ALL:
            let clusters = action.payload.clusters;
            clusters['count'] = action.payload.clustersCount;
            return {
                ...state,
                clusters: clusters,
                error: '',
                message: action.payload.message
            };
        case CLUSTER_ID:
            let cluster = action.payload.cluster;
            return{
                ...state,
                cluster:cluster,
                error:'',
                message:action.payload.message
            }
        case CLUSTER_ELEMENTS:
            let clusterElements = action.payload.clusterelements;
            clusterElements['count'] = action.payload.clusterelementsCount;
            return {
                ...state,
                clusterElements:clusterElements,
                error: '',
                message: action.payload.message
            };
        case CLUSTER_RESET:
            return{
                ...state,
                error:'',
                clusters:null,
                cluster:null,
                clusterElements:null,
                message:null
            }
        case CLUSTER_ERROR:
            return { ...state, error: action.payload.message };
        default:
            return state;
    }
}

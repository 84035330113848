import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';

import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import './MenuLink.scss'

class MenuLink extends Component {
    render(){
        const {title, path, icon, notifications} = this.props;

        let link = "/"
        let finalIcon = faBars;
        let finalNotifiations = 5;

        if(path){
            link = path;
        }
        if(icon){
            finalIcon = icon;
        }
        if(notifications){
            finalNotifiations = notifications
        }

        return(
            <Card className="mx-5 menulink-card">
                <Link to={link} className="m-auto">
                    <span className="menulink-bg-icon m-auto">
                        <FontAwesomeIcon icon={finalIcon} className="m-auto menulink-icon" />
                        <span className="menulink-notifications">{finalNotifiations}</span>
                    </span>
                    {title ? (
                        <Card.Body>
                            <Card.Title className="text-center">{title}</Card.Title>
                        </Card.Body>
                    ) : null}
                </Link>
            </Card>
        )
    }
    
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
    };
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuLink));
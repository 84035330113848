//Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
//stam-strap
import HeaderTop from '../partials/HeaderTop';
import MenuLeft from '../partials/MenuLeft';
//child-app
import history from '../../../../history';
//gj-strap
import { Full, FullCenter, TileContainer, Tile, TileHeader, TileBody, Cover, modalFunction } from './../../../../gj_modules/gj-strap/components/partials';
import { Form, PanelButton } from './../../../../gj_modules/gj-strap/components/form';
import { processSubmit, checkNull } from './../../../../gj_modules/gj-strap/lib';
import { GetTranslation } from '../partials';
//various
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons'
//gj-stuva
import { /*generatePanelNew,*/ generateInitialValue } from './../../../../gj_modules/gj-stuva/forms'
import { PanelNew } from './../../../../gj_modules/gj-stuva/components'
import { actions } from '../../stuva';
const elementActions = actions["translation"];
const reset = elementActions.reset;
const getById = elementActions.getById;
const updateById = elementActions.updateById;
const trashById = elementActions.trashById;

class Translation extends Component {
    componentDidMount() {
        const { match, reset, getById } = this.props;
        const id = match && match.params && match.params.idtranslation;
        reset();
        if (id) {
            getById(id);
        }
    }

    componentDidUpdate() {
        //console.log("didUpdate");
    }

    submitEdit(values) {
        const { match, updateById, translation } = this.props;
        let element = translation;
        const id = match && match.params && match.params.idtranslation;
        //console.log(processSubmit(values));
        if (element && !element.pendingUpdate) {
            updateById(id, processSubmit(values));
        }
    }

    render() {
        const { match, translation, trashById, menu } = this.props;
        const id = match && match.params && match.params.id;
        const idtranslation = match && match.params && match.params.idtranslation;

        const element = translation;

        let portlet = {
            title: <GetTranslation firstletter short={"translate.translations"} />,
            links: [
                /*{name:"Sessions", path:"/"+id+"/sessions"},*/
                { name: "Traduction", path: "/" + id + "/translation/" + idtranslation }
            ]
        }

        return (
            <React.Fragment>
                <div id="popup"></div>
                <MenuLeft menu={menu} />
                <HeaderTop menu={menu} />

                {element && !element.pending ? (

                    <FullCenter portlet={portlet}>
                        <TileContainer>
                            <Tile size={"full"}>
                                <TileBody>
                                    <Cover
                                        icon={faPencilRuler}
                                        title={element.name}
                                        subtitle={checkNull(element.short)}
                                    >
                                        Traduction créée le {moment().format("DD/MM/YYYY")}<br />
                                        Dernière modification le {moment().format("DD/MM/YYYY")}
                                    </Cover>
                                </TileBody>
                            </Tile>
                        </TileContainer>

                        <Row>
                            <Col lg="6" className="tile-col">

                                {/* Général */}
                                <TileContainer>
                                    <Tile size={"full"}>
                                        <TileHeader title="Général" border={true} />
                                        <TileBody>

                                            <Form
                                                onSubmit={values => this.submitEdit(values)}
                                                initialValues={generateInitialValue(elementActions.model, element)}
                                            >

                                                <PanelNew
                                                    width={[3, 9]}
                                                    model={elementActions.model}
                                                    filter={"update"}
                                                    element={element}
                                                />

                                                <PanelButton
                                                    pending={element.pendingUpdate}
                                                    message={element.message}
                                                />

                                            </Form>
                                        </TileBody>
                                    </Tile>
                                </TileContainer>

                                {/* Delete */}
                                <TileContainer>
                                    <Tile size={'full'}>
                                        <TileBody>
                                            <Button
                                                onClick={function () {
                                                    modalFunction({
                                                        btnFunction: () => {
                                                            trashById(idtranslation).then(
                                                                function (response) {
                                                                    history.push("/" + id + "/translations")
                                                                }
                                                            );
                                                        },
                                                        btnText: "Confirmer",
                                                        text: "Êtes-vous sûr de supprimer cette traduction ?",
                                                        header: "Supprimer une traduction",
                                                        state: "translation.pendingTrash"
                                                    }, "popup")
                                                }}
                                                className="float-sm-right"
                                                variant="danger"
                                            >
                                                Supprimer cette traduction
											</Button>
                                        </TileBody>
                                    </Tile>
                                </TileContainer>
                            </Col>

                            <Col lg="6" className="tile-col">

                                {/* Other panels */}

                            </Col>

                        </Row>

                    </FullCenter>
                ) : (
                        <Full>
                            Chargement
                        </Full>
                    )}
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            reset,
            getById,
            updateById,
            trashById
        },
        dispatch
    );
}

function mapStateToProps(state) {
    return {
        translation: state.translation.translation,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Translation);
import axios from 'axios';
import errorHandler from './errorHandler';

const API_URL = process.env.REACT_APP_API_URL;

export default function deleteData(action, pending, errorType, isAuthReq, url, dispatch) {
    const requestUrl = API_URL + url;
    let headers = {};

    if(pending !== null){
        dispatch( { type:pending } );
    }
  
    if (isAuthReq) {
        headers = {
            // headers: { Authorization: cookie.load('token') }
            headers: {
                Authorization: localStorage['token']
            }
        };
    }
  
    return axios
    .delete(requestUrl, headers)
    .then(response => {
        dispatch({
            type: action,
            payload: response.data
        });
        return { response };
    })
    .catch(error => {
        return errorHandler(dispatch, error, errorType);
    });
}
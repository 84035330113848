// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
/*import { Media, Button } from 'react-bootstrap';
import history from '../../history';
import moment from 'moment';*/
// submodule components
import { Form, PanelButton } from 'gj_modules/gj-strap/components/form';
import { processSubmit } from 'gj_modules/gj-strap/lib';
//gj-stuva
import { generateInitialValue } from 'gj_modules/gj-stuva/forms'
import { PanelNew } from 'gj_modules/gj-stuva/components'
//import { GetTranslation } from '../partials';
// contants & utilities
// actions
import { actions } from 'stam_modules/stam-strap/stuva';
const elementActions = actions["mpoi"];
//const reset = elementActions.reset;
const getById = elementActions.getById;
const updateById = elementActions.updateById;

class SphereditorPartial extends Component {

    submitEdit(values) {
        const { updateById, mpoi, onSave } = this.props;
        let id = new URLSearchParams(window.location.search).get('t');
        let element = mpoi;
        console.log(processSubmit(values));
        if (element && !element.pendingUpdate) {
            updateById(id, processSubmit(values));
            onSave(id);
        }
    }

    render() {

		const { mpoi, onChange } = this.props;

        let element = mpoi;

        let id = new URLSearchParams(window.location.search).get('t');
        if(id == null){
            element = null;
        }

        return (
            <React.Fragment>
                {element && !element.pending ? (
                    <React.Fragment>
                        <Form
                            onSubmit={values => this.submitEdit(values)}
                            initialValues={generateInitialValue(elementActions.model, element)}
                            onChange={onChange}
                        >
                            <PanelNew
                                width={[3, 9]}
                                model={elementActions.model}
                                filter={"update"}
                                element={element}
								getElement={getById}
								updateElement={updateById}
                            />

                            <PanelButton
                                pending={element.pendingUpdate}
                                message={"Update"}
                            />

                        </Form>
                    </React.Fragment>
                ) : (
					element && element.pending ? (
						<React.Fragment>Loading</React.Fragment>
					) : (
						<React.Fragment>Aucun élément sélectionné</React.Fragment>
					)
                )}
            </React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
		getById,
        updateById
    }, dispatch);
}

function mapStateToProps(state) {
    return {
        mpoi: state.mpoi.mpoi,
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SphereditorPartial);

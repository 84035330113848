//import { default as stamstrap } from "../../../settings/stam-strap"

let ffile = {
    name: "ffile",
    actions: [
        "all", "id", "add", "update", "trash", "reset"
	],
	subactions: {
		subffiles:{
			name:"subffile",
			path:"data.subffiles",
			actions:[
				"add", "update", "trash"
			]
		}
	},
    model: {
        game:{
            name:"Application",
            type:"select",
            data:{
                isMulti:true
            },
            modelType:"game",
            update:true,
            add:true
        },
        short: {
            name: "Short",
            type: "text",
            placeholder: "Short",
            add: true,
            update: true
        },
        name: {
            name: "Nom",
            placeholder: "Nom de l'objet",
            type: "translationtext",
            //add: true,
            update: true
        },
        description: {
            name: "Description",
            type: "textarea",
            placeholder: "Description",
            update: true
        },
        data: {
			subffiles:{

			}
		},
		file:{
			name: "Fichier",
            type: "file",
            placeholder: "",
            add: true,
		},
		channel:{
			name:"Channel",
			type: "text",
			placeholder: "preview",
			add: true
		}
	},
	display:{
        selectDefault:(e) => {
            return {
                label:e.short,
                value:e._id,
                force:true
            }
        }
    },
    filters:{
        default:{
            filter:"searchFfileByShort",
            quantity:20,
            state:"ffile.ffiles",
            value:"_id",
            label:"short"
        }
    }
}

/*if(stamstrap.hasOwnProperty("models")){
    if(stamstrap.models.hasOwnProperty("thing")){
        if(stamstrap.models.thing.hasOwnProperty("add")){
            Object.assign(thing.model, stamstrap.models.thing.add);
        }
    }
}*/

export default ffile;
// react
import React, { /*useRef*/ } from 'react'
// npm
import { Canvas } from 'react-three-fiber'
// childapp
//import history from '../../../history';
// internal
import { CameraControls, SphereEnv, Poi } from "./index"
import { GetTranslation } from 'stam_modules/stam-strap/components/partials';

const API_URL = process.env.REACT_APP_API_URL;

export default function SphereViewer(props) {

	//console.log("props", props)

	let urlLeft = ""
	let urlRight = ""
	let textColor = "black"

	if(props.msphere && props.msphere.ffile && props.msphere.ffile.data && props.msphere.ffile.data.subffiles){
		for(let sf of props.msphere.ffile.data.subffiles){
			if(sf.channel === "left"){
				urlLeft = API_URL+"/subffile/get/"+sf.path.split("\\")[1]
			}else if(sf.channel === "right"){
				urlRight = API_URL+"/subffile/get/"+sf.path.split("\\")[1]
			}
		}

		if(props.msphere.short.includes("night")){
			textColor = "white"
		}
	}

	return(
		<React.Fragment>
			<div
				style={{
					width:"100%",
					height: "500px",
					position:"absolute",
					zIndex:1000,
					pointerEvents:"none",
					overflow:"hidden",
					marginLeft:"-10px",
					marginRight:"-10px"
				}}
			>
				<div
					style={{

					}}
				>
					{props.mpois ? props.mpois.filter(function(mpoi){ return mpoi.published === "active" }).map((poi) =>
						<div
							key={"poit_"+poi._id}
							id={"poi_"+poi._id}
							style={{
								left:"50%",
								top:"0%",
								position:"absolute"
							}}
						>
							<div
								id={"poit_"+poi._id}
								style={{
									left:"-100px",
									width:"200px",
									height:"300px",
									position:"absolute",
									marginTop:"-50px",
									//backgroundColor:"green"
								}}
							>
								<p
									style={{
										textAlign:"center",
										fontFamily:"TungstenNarrow-Semibold",
										textTransform:"uppercase",
										fontSize:"20px",
										lineHeight:"18px",
										color:textColor
									}}
								><GetTranslation quick={poi.name && poi.name.translations} /></p>
							</div>
						</div>
					) : null}
				</div>
			</div>
			<Canvas
				style={{
					height:"500px"
				}}
			>
				<CameraControls />
				{/*<ambientLight />
				<pointLight position={[10, 10, 10]} />*/}
				{props.mpois ? props.mpois.filter(function(mpoi){ return mpoi.published === "active" }).map((poi) =>
					<React.Suspense
						fallback={null}
						key={poi._id}
					>
						<Poi
							poi={poi}
							msphere={props.msphere}
							selectAction={props.selectAction}
						/>
					</React.Suspense>
				) : null}
				{/*<Box position={[-1.2, 0, 0]} />*/}
				{/*<Box position={[1.2, 0, 0]} />*/}
				<React.Suspense fallback={null}>
					<SphereEnv
						position={[0, 0, 0]}
						urlLeft={urlLeft}
						urlRight={urlRight}
					/>
				</React.Suspense>
			</Canvas>
		</React.Fragment>
	)
}
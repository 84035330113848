//import React from 'react';

import { langages } from '../data/langages'

export const translation = {
    name: "translation",
    actions: [
        "all", "id", "add", "update", "trash", "reset"
    ],
    model: {
        game:{
            name:"Application",
            type:"select",
            data:{
                isMulti:true
            },
            modelType:"game",
            update:true,
            add:true
        },
        short: {
            name: "Short",
            type: "text",
            placeholder: "Short",
            add: true,
            update: true
        },
        description: {
            name: "Description",
            type: "textarea",
            placeholder: "Description",
            update: true
        },
        translations: {
            name: "Translations",
            type: "datatableedit",
            data:{
                rows:[
                    {
                        short: "langage", name: "Langage", width: "40%", type: "select", placeholder: "Langage", data: {
                            isMulti: false,
                            options: langages
                        }
                    },
                    { short: "value", name: "value", width: "60%", type: "textarea", placeholder: "Traduction" }                                    
                ],
                formater:(value) => {
                    //console.log("format base", value)
                    let output = [];

                    for (const i in value) {
                        if(value[i].langage){
                            output.push({
								index:value[i].index,
                                langage:value[i].langage,
                                value:value[i].value
                            })
                        }
                    }
                    //console.log("format", output)

                    return output;
                }
            },
            //placeholder: "Nom de la valeur",
            //add: true,
            update: true
        },
        data: {
        }
    }
}
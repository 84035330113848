import React from 'react';
import { GetTranslation } from '../stam_modules/stam-strap/components/partials';

export const mpoi = {
    name: "mpoi",
	clean:"point d'interêt",
    actions: [
        "all", "id", "add", "update", "trash", "reset"
    ],
    model: {
        game:{
            name:"Application",
            type:"select",
            data:{
                isMulti:true
            },
            modelType:"game",
            update:true,
            add:true
        },
        short: {
            name: "Short",
            type: "text",
            placeholder: "Short",
            add: true,
            update: true
        },
        name: {
            name: "Nom",
            placeholder: "Nom de l'objet",
            type: "translationtext",
            //add: true,
            update: true
        },
		published: {
			name: "Published",
			placeholder: "Published",
            type: "text",
			update: true
		},
		type: {
			name: "Type",
			placeholder: "Type",
            type: "text",
			update: true
		},
		mbuilding:{
            name:"Tour",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"mbuilding",
            update:true,
            add:true
        },
		msphere:{
            name:"Sphere",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"msphere",
            update:true
        },
		mcard:{
            name:"Fiche",
            type:"select",
            data:{
                isMulti:false
            },
            modelType:"mcard",
            update:true
        },
		location:{
            name: "Localisation",
            type: "location",
            add: true,
            update: true
		},
		angle: {
            name: "Angle",
            type: "text",
            placeholder: "Angle",
            update: true
        },
		elevation: {
            name: "Elevation",
            type: "text",
            placeholder: "Angle",
            update: true
        },
		offset: {
            name: "Offset texte",
            type: "text",
            placeholder: "Offset",
            update: true
        },
		offsetangle: {
            name: "Offset Angle",
            type: "text",
            placeholder: "Offset",
            update: true
        },
		offsetelevation: {
            name: "Offset Elevation",
            type: "text",
            placeholder: "Offset",
            update: true
        },
		linex: {
            name: "Line X",
            type: "text",
            placeholder: "Linex",
            update: true
        },
		liney: {
            name: "Line Y",
            type: "text",
            placeholder: "Liney",
            update: true
        },
        description: {
            name: "Description",
            type: "textarea",
            placeholder: "Description",
            update: true
        }
    },
    filters:{
        default:{
            filter:"searchMpoiByShort",
            quantity:20,
            state:"mpoi.mpois",
            value:"_id",
            label:"short"
        },
		all:[
			{
				type:"search",
				size:25,
				name:"searchMpoiByShort",
				placeholder:"Recherche par short"
			},
			{
				type:"select",
				size:50,
				name:"searchMpoiByPublished",
				placeholder:"",
				options:[
					{
						value: 'active',
						label: 'Active'
					},
					{
						value: 'draft',
						label: 'Draft'
					}
				],
				default:"active"
			}
		]
    },
    display:{
        selectDefault:(e) => {
            return {
                label:e.name,
                value:e._id,
                force:true
            }
        }
    },
	properties:[
		{ short: "short", name: "Short", width: "20%", category: "classic"},
		{ short: "mbuilding", name: "Tour", width: "20%", category: "classic"},
		{ short: "msphere", name: "Sphere", width: "20%", category: "classic"},
		{ short: "published", name: "Publié", width: "20%", category: "classic"},
		{ short: "name", name: "Nom", width: "10%", category: "classic"},
		{ short: "actions", name: "Actions", width: "10%", category: "actions" }
	],
	renderTd:(e) => {
		return {
			id: e._id,
			key: "mpoi_"+e._id,
			short: e.short,
			mbuilding: e.mbuilding && e.mbuilding.short,
			msphere: e.msphere && e.msphere.short,
			published: e.published,
			name: <GetTranslation quick={e.name && e.name.translations} />
		}
	}
}
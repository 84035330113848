import * as THREE from "three";
import "./viewer.css";

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
//import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

export class Motor {
    constructor(elementId){

        //Init variable
        this.mouse = new THREE.Vector2();
        this.viewerWindows = document.getElementById(elementId);
        this.raycaster = new THREE.Raycaster();
        this.scene = new THREE.Scene();
        this.camera = null;
        this.renderer = null;
        this.controls = null;
        this.control = null;

        //Callback

        //Binding
        this.onWindowResizeBinded = this.onWindowResize.bind(this);
        this.onMouseMoveBinded = this.onMouseMove.bind(this);
        this.onMouseUpBinded = this.onMouseUp.bind(this);
        this.draggingChangedBinded = this.draggingChanged.bind(this);
    }

    init(){
        //console.log("init");

        //Scene
        this.scene.background = new THREE.Color(0xffffff);
        this.scene.fog = new THREE.Fog(0xffffff, 100, 3000);

        //Camera
        this.camera = new THREE.PerspectiveCamera(75, this.viewerWindows.offsetWidth / this.viewerWindows.offsetHeight, 1, 20000);
        this.camera.up.set(0, 0, 1);
        this.camera.position.set(150, 150, 150);

        //Control
        this.controls = new OrbitControls(this.camera);
        this.controls.target.set(0, 0, 75);
        this.controls.update();

        //Lighting
        //var ambient = new THREE.AmbientLight( 0xffffff, 10 );
        //scene.add( ambient );
        var hemiLight = new THREE.HemisphereLight(0xffffff, 0x222222, 2);
        this.scene.add(hemiLight);

        //Renderer
        this.renderer = new THREE.WebGLRenderer({
            antialias: true
        });
        this.renderer.shadowMap.enabled = true
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(this.viewerWindows.offsetWidth, this.viewerWindows.offsetHeight);
        this.renderer.gammaInput = true;
        this.renderer.gammaOutput = true;

        //Control

        this.control = new TransformControls(this.camera, this.renderer.domElement);
        //control.setSize(100)
        this.control.setMode("translate");
        //control.position.copy(new THREE.Vector3(0,0,1))
        //control.attach(mesh)
        this.scene.add(this.control);

        
        document.addEventListener("resize", this.onWindowResizeBinded, true);
        document.addEventListener("mousemove", this.onMouseMoveBinded, true);
        document.addEventListener("mouseup", this.onMouseUpBinded, true);
        this.control.addEventListener("dragging-changed", this.draggingChangedBinded, true);

        this.loop()
    }

    loop(){
        requestAnimationFrame(() => this.loop());

        /*this.raycaster.setFromCamera(this.mouse, this.camera);

        // calculate objects intersecting the picking ray
        var intersects = this.raycaster.intersectObjects(this.scene.children);

        let objectFinal = null;

        for ( var i = 0; i < intersects.length; i++ ) {
            if(i===0){
                objectFinal = intersects[i].object;
            }
        }*/

        this.renderer.render(this.scene, this.camera);
    }

    unMount(){
        //console.log("unmount")
        document.removeEventListener("resize", this.onWindowResizeBinded, true)
        document.removeEventListener("mousemove", this.onMouseMoveBinded, true)
        document.removeEventListener("mouseup", this.onMouseUpBinded, true);
        this.control.removeEventListener("dragging-changed", this.draggingChangedBinded, true);
    }

    onWindowResize() {
        this.camera.aspect = this.viewerWindows.offsetWidth / this.viewerWindows.offsetHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.viewerWindows.offsetWidth, this.viewerWindows.offsetHeight);
    }
    
    onMouseMove(event) {
        let rect = this.viewerWindows.getBoundingClientRect();

        //mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        //mouse.y = - (event.clientY / window.innerHeight) * 2 + 1;
        //console.log(mouse.x)
        //console.log(this.viewerWindows.getBoundingClientRect().top)

        this.mouse.x = (Math.min(this.viewerWindows.offsetWidth, Math.max(0, event.clientX - rect.left)) / this.viewerWindows.offsetWidth) * 2 - 1;
        this.mouse.y = - (Math.min(this.viewerWindows.offsetHeight, Math.max(0, event.clientY - rect.top)) / this.viewerWindows.offsetHeight) * 2 + 1;

        //console.log(this.mouse)

        //viewerWindows.
    }

    onMouseUp(event){

        //console.log("onmouseup")

        this.raycaster.setFromCamera(this.mouse, this.camera);

        // calculate objects intersecting the picking ray
        var intersects = this.raycaster.intersectObjects(this.scene.children);

        let objectFinal = null;

        for ( var i = 0; i < intersects.length; i++ ) {
            if(i === 0){
                if(intersects[i].object.type === "Subblock" || intersects[i].object.type === "Block"){
                    objectFinal = intersects[i].object;
                }
            }
        }

        if(objectFinal !== null){
            console.log(objectFinal)
            //this.control.position.copy(objectFinal.position);
            this.control.attach(objectFinal)
        }else{
            this.control.detach();
        }
    }

    draggingChanged(event){
        console.log("dragChanged")
        this.controls.enabled = !event.value;
    }
    
}
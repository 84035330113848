//Auth
export const AUTH_USER = 'auth_user',
    UNAUTH_USER = 'unauth_user',
    AUTH_ERROR = 'auth_error',
    AUTH_CLEAR = 'auth_clear',
    FORGOT_PASSWORD_REQUEST = 'forgot_password_request',
    RESET_PASSWORD_REQUEST = 'reset_password_request',
    PROTECTED_TEST = 'protected_test';

//User
export const UPDATE_NOTIFICATION = 'update_notification',
    USER_ALL = 'user_all',
    USER_ALL_PENDING = 'user_all_pending',
    USER_RESET = 'user_reset',
    GET_USER_SELF = 'get_user_self',
    GET_USER = 'get_user',
    EXISTING_USER = 'existing_user',
    ADD_USER = 'add_user',
    RESET_USER = 'reset_user',
    USER_ERROR = 'user_error',
    SET_PREFERENCE = 'set_preference',
    EDIT_USER_REQUEST = 'edit_user_request',
    EDIT_USER_SUCCESS = 'edit_user_success',
    EDIT_USER_ERROR = 'edit_user_error';

//Menu
export const MENU_RESET = 'menu_reset',
    MENU_GAME_RESET = 'menu_game_reset',
    MENU_GAME_ALL = 'menu_game_all',
    MENU_GAME_UPDATE = 'menu_game_update',
    MENU_ERROR = 'menu_game_error';

//Session
export const SESSION_RESET = 'session_reset',
    SESSION_ALL = 'session_all',
    SESSION_ID = 'session_id',
    SESSION_EVENTS = 'session_events',
    SESSION_ERROR = 'session_error';

//Block
export const BLOCK_ALL = 'block_all',
    BLOCK_ID = 'block_id',
    BLOCK_RESET = 'block_reset',
    BLOCK_ERROR = 'block_error';

//Cluster
export const CLUSTER_ALL = 'cluster_all',
    CLUSTER_ID = 'cluster_id',
    CLUSTER_ELEMENTS = 'cluster_elements',
    CLUSTER_RESET = 'cluster_reset',
    CLUSTER_ERROR = 'cluster_error';

//Notifications
export const FETCH_NOTIFICATION = 'fetch_notification',
    VIEWED_NOTIFICATION = 'viewed_notification',
    GET_SOME_NOTIFICATION = 'get_some_notification',
    NOTIFICATION_ERROR = 'notification_error';